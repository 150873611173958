import React, { useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

import InputWithLabel from "components/molecules/InputWithLabel";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

const Container = styled.div`
    width: 100%;
`;

function ReferenceBibInfoAddFieldModal(props) {
    const [fieldName, setFieldName] = useState("");

    return (
        <Container>
            <GeneralText size={"regular"}>필드 추가를 위해 필드명을 입력해주세요.</GeneralText>
            <InputWithLabel
                width="100%"
                margin="24px 0 0 0"
                labelText="필드명"
                bgColor="var(--color-Base1)"
                placeholder="title"
                onChange={(e) => setFieldName(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        props.addAction(fieldName);
                    }
                }}
                value={fieldName}
            />
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"추가"}
                leftOnClick={props.exitModal}
                rightOnClick={(e) => props.addAction(fieldName)}
                disabled={!fieldName}
            />
        </Container>
    );
}

export default ReferenceBibInfoAddFieldModal;
