import { useQuery } from "react-query";

import { getSelectBoxOptions } from "utils/request/options";

export const useSelectBoxOptions = (params) => {
    return useQuery("selectBoxOptions", (e) => getSelectBoxOptions(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};
