import { apiUrl } from "utils/urls";

import axios from "axios";

export const getSelectBoxOptions = async (params) => {
    const { data } = await axios.get(apiUrl("selectBoxOptions"), {
        params: params,
    });
    return data;
};
