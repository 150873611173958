import React, { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import styled from "styled-components";

import Image from "components/atoms/Image";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import { apiUrl } from "utils/urls";

import axios from "axios";

// 전체 모달 컨테이너
const ModalContent = styled.div`
    width: 100%;

    @media print {
        padding: 20px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.justifyContent || "space-between"};
    align-items: ${(props) => props.alignItems || "flex-start"};
    margin: ${(props) => props.margin || "0 0 16px 0"};
    height: auto;
    width: 100%;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--color-Line);
    margin: 24px 0;
`;

const titleStyle = {
    size: "subRegular",
    color: "var(--color-SubBlack)",
};

const contentStyle = {
    size: "regular",
    color: "var(--color-Black)",
};

const PaymentBillingHistoryModal = (props) => {
    const [paymentReceipt, setPaymentReceipt] = React.useState(null);
    const printRef = useRef();

    const getPaymentReceipt = () => {
        axios
            .get(apiUrl("paymentBillingReceipt"), {
                params: { id: props.id },
            })
            .then((res) => {
                console.log(res.data);
                setPaymentReceipt(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (props) {
            getPaymentReceipt();
        }
    }, [props]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => console.log("Print job completed!"),
    });

    return (
        <>
            <ModalContent ref={printRef}>
                <Row>
                    <Image name={"headerLogo"} />
                    <GeneralText size={"small"} fontWeight={"300"} textAlign={"right"}>
                        (주) 데이탄소프트
                        <br />
                        제주특별자치도 제주시 도령북길 31, 2층
                        <br />
                        prep@datansoft.com
                    </GeneralText>
                </Row>

                <Row margin={"40px 0 0 0"}>
                    <TitleText {...titleStyle}>결제 금액</TitleText>
                    <GeneralText {...contentStyle}>₩{paymentReceipt?.amount}</GeneralText>
                </Row>
                <Divider />
                <Row margin={"0 0 0 0"}>
                    <TitleText {...titleStyle}>
                        구독 요금
                        <GeneralText
                            margin={"4px 0 0 0"}
                            size={"small"}
                            fontWeight={"400"}
                            color={"var(--color-Grey1)"}
                        >
                            구독 기간: {paymentReceipt?.subscribe_date}
                        </GeneralText>
                    </TitleText>
                    <GeneralText size={"regular"} color={"var(--color-Black)"}>
                        ₩{paymentReceipt?.amount ? Math.round(paymentReceipt.amount / 1.1) : 0}
                    </GeneralText>
                </Row>
                <Row margin={"24px 0 0 0"}>
                    <TitleText {...titleStyle}>부가세(10%)</TitleText>
                    <GeneralText size={"regular"} color={"var(--color-Black)"}>
                        ₩{paymentReceipt?.amount ? paymentReceipt.amount - Math.round(paymentReceipt.amount / 1.1) : 0}
                    </GeneralText>
                </Row>
                <Row margin={"24px 0 0 0"}>
                    <TitleText {...titleStyle}>결제일</TitleText>
                    <GeneralText {...contentStyle}>{paymentReceipt?.created_at}</GeneralText>
                </Row>
                <Divider />
                <Row>
                    <TitleText {...titleStyle}>결제 상태</TitleText>
                    <GeneralText size={"regular"} color={"var(--color-Black)"}>
                        {paymentReceipt?.payment_status === "refund"
                            ? "결제 취소"
                            : paymentReceipt?.payment_status === "bill"
                            ? "결제 예정"
                            : "결제 완료"}
                    </GeneralText>
                </Row>
                <Row>
                    <TitleText {...titleStyle}>결제 수단</TitleText>
                    <GeneralText size={"regular"} color={"var(--color-Black)"}>
                        {paymentReceipt?.billing_key?.card_name} {paymentReceipt?.billing_key?.card_number}
                    </GeneralText>
                </Row>
                <Divider />
                <Row margin={"40px 0 40px 0"}>
                    <TitleText {...titleStyle}>
                        위의 결제 금액을{" "}
                        {paymentReceipt?.payment_status === "refund"
                            ? "환불하였습니다."
                            : paymentReceipt?.payment_status === "bill"
                            ? "청구합니다."
                            : "영수하였습니다."}
                    </TitleText>
                    <GeneralText size={"small"} fontWeight={"300"} textAlign={"right"}>
                        {paymentReceipt?.merchant_uid && (
                            <>
                                결제번호: {paymentReceipt?.merchant_uid}
                                <br />
                            </>
                        )}
                        계정: {props.user?.email}
                    </GeneralText>
                </Row>
            </ModalContent>
            <Row margin={"56px 0 0 0"} justifyContent={"center"}>
                <Button
                    onlyText
                    onClick={handlePrint}
                    margin={""}
                    width={"160px"}
                    height={"40px"}
                    bgColor={"var(--color-Button2)"}
                    hoverBgColor={"var(--color-ButtonHover2)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"인쇄"}
                />
            </Row>
        </>
    );
};

export default PaymentBillingHistoryModal;
