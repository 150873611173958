import React from "react";

import styled, { keyframes } from "styled-components";

const RotateCube = keyframes`
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);

    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const LoadIcon = styled.span`
    display: inline-block;
    margin: 0 0 0 6px;
    width: 9px;
    height: 9px;
    background-color: #29bddd;
    border-radius: 3px;
    animation-name: ${RotateCube};
    animation-duration: 2000ms;
    animation-delay: unset;
    animation-iteration-count: infinite;
    // animation-direction: normal;
    animation-timing-function: linear;
`;

function AiCursor(props) {
    return <LoadIcon />;
}

export default AiCursor;
