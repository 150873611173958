import React, { useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";

import FormInput from "components/molecules/FormInput";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { encrypt } from "utils/crypto";
import { adminChangeUser } from "utils/request/auth";

const Container = styled.div`
    width: 100%;
`;

function AdminChangeUserModal(props) {
    const [email, setEmail] = useState("");

    const { setLoading } = useLoading();

    const login = () => {
        setLoading(true);
        adminChangeUser({ email: email })
            .then((res) => {
                let email = res.user_email;
                let encryptedEmail = encrypt(email);

                localStorage.setItem("email", encryptedEmail);
                localStorage.setItem("token", res.auth_token);
                setLoading(false);
                window.location = "/reference";
                props.exitModal();
            })
            .catch((err) => {
                setLoading(false);
                Alert("warn", "에러", err.response.data.error);
            });
    };

    return (
        <Container>
            <FormInput
                labelDp="none"
                placeholder="이메일 주소를 입력해주세요."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        login();
                    }
                }}
            />
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"로그인"}
                leftOnClick={props.exitModal}
                rightOnClick={login}
                disabled={email.trim() === ""}
            />
        </Container>
    );
}

export default AdminChangeUserModal;
