import React from "react";

import styled, { css } from "styled-components";

const Size = css`
    ${(props) =>
        props.size === "xsmall" &&
        css`
            font-size: 1.1rem;
        `}

    ${(props) =>
        props.size === "small" &&
        css`
            font-size: 1.2rem;
        `}

    ${(props) =>
        props.size === "regular" &&
        css`
            font-size: 1.3rem;
        `}

    ${(props) =>
        props.size === "large" &&
        css`
            font-size: 1.4rem;
        `}

    ${(props) =>
        props.size === "xlarge" &&
        css`
            font-size: 1.6rem;
        `};
`;

const Text = styled.p`
    display: ${(props) => props.display};
    margin: ${(props) => props.margin || "0"};
    width: ${(props) => props.width || "fit-content"};
    height: ${(props) => props.height || "fit-content"};
    font-weight: 400;
    color: ${(props) => props.color || "var(--color-SubBlack)"};
    text-align: ${(props) => props.textAlign || "justify"};
    line-height: 1.5;
    word-break: keep-all;
    letter-spacing: -0.03em;
    -webkit-line-clamp: ${(props) => props.webkitLineClamp || "1"};

    ${(props) =>
        props.ellipsis &&
        css`
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        `}

    ${Size}

    ${(props) =>
        props.multiEllipsis &&
        css`
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-box-orient: vertical;
        `}

    ${(props) =>
        props.botTextType &&
        css`
            white-space: pre-wrap;
            text-align: justify;
            word-break: break-all;
            list-style: disc;
        `}
`;

function SentenceText(props) {
    const {
        ref,
        display,
        margin,
        width,
        height,
        color,
        textAlign,
        size,
        ellipsis,
        multiEllipsis,
        botTextType,
        webkitLineClamp,
        children,
    } = props;

    return (
        <Text
            ref={ref}
            display={display}
            margin={margin}
            width={width}
            height={height}
            textAlign={textAlign}
            color={color}
            ellipsis={ellipsis}
            multiEllipsis={multiEllipsis}
            botTextType={botTextType}
            webkitLineClamp={webkitLineClamp}
            size={size}
        >
            {children}
        </Text>
    );
}

export default SentenceText;
