class ConstantsManager {
    constructor() {
        this.PC_WIDTH = 1280;
        this.TABLET_WIDTH = 768;

        this.TABLET_OR_PC_HEADER_HEIGHT = 56;
        this.MOBILE_HEADER_HEIGHT = 48;

        this.BIG_HEIGHT_MQ = `@media (min-height: 1280px)`;

        this.KB = 1024;
        this.MB = 1048576;
        this.GB = 1073741824;

        this.FREE_PLAN = 1;
        this.PAID_PLAN = 2;

        this.FILE_ANALYSIS_TODO = "TODO";
        this.FILE_ANALYSIS_DOING = "DOING";
        this.FILE_ANALYSIS_DONE = "DONE";
        this.FILE_ANALYSIS_UNSUPPORTED = "UNSUPPORTED";
        this.FILE_ANALYSIS_FAILED = "FAILED";

        this.REVIEW_MESSAGE_MEMO = "MEMO";
        this.REVIEW_MESSAGE_CHAT = "CHAT";
        this.REVIEW_CHAT_TRANSLATE = "TRANSLATE";
        this.REVIEW_CHAT_SUMMARIZE = "SUMMARIZE";
        this.REVIEW_CHAT_EXPLAIN = "EXPLAIN";
        this.REVIEW_CHAT_FREETALK = "FREETALK";

        this.SEARCH_FILTER_REGEX = /(is:|t:|r:)?(\"[^\"]*\"|\S*)(\s|\W|$)/g;

        this.TAG_TREE_ID_SIDEMENU = "tree-tag-side-menu";
        this.TAG_TREE_ID_EDIT_MODAL = "tree-tag-edit";
    }
}

const Constants = new ConstantsManager();
export default Constants;
