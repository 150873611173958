import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    padding: 6px 12px;
    width: auto;
    height: 100%;
    border-radius: 4px;
    background-color: transparent;
    transition: 100ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

function PageHeaderNavItem(props) {
    return (
        <Link as={Link} to={props.linkAddress} reloadDocument={props.reloadDocument}>
            <Container>
                <GeneralText size={"regular"} fontWeight={"500"} color={"var(--color-Black)"}>
                    {props.menuName}
                </GeneralText>
            </Container>
        </Link>
    );
}

export default PageHeaderNavItem;
