import React from "react";

import styled from "styled-components";

import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";

const Container = styled.div`
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 16px 0;
    padding: 6px;
    width: 100%;
    // height: 320px;
    // max-height: 320px;
    background-color: var(--color-Base1);
`;

const ItemRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0 8px 0;
    width: 100%;
    height: 32px;
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
`;

const ItemRight = styled.div`
    display: flex;
    align-items: flex-start;
`;

const LabelSign = styled.div`
    min-width: 2px;
    height: 24px;
    background-color: var(--color-Key);
`;

const ItemLabel = styled.label`
    margin-left: 8px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    cursor: text;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 16px 0 0 0;
    width: 100%;
    height: 32px;
`;

function ReferenceMetadataAnalyzeModal(props) {
    return (
        <Container>
            <GeneralText size={"regular"}>업로드한 PDF 파일을 AI가 분석하여 참고문헌 정보를 채워줍니다.</GeneralText>
            <GeneralText size={"regular"}>원하지 않을 경우 아래의 닫기 버튼을 누르세요.</GeneralText>
            <ContentsWrapper>
                <ItemRow>
                    <ItemLeft>
                        <LabelSign />
                        <ItemLabel>서지정보 가져오기</ItemLabel>
                    </ItemLeft>
                    <ItemRight>
                        <Button
                            onlyText
                            onClick={props.onBibInfoLoadClick}
                            margin={"0 0 0 4px"}
                            width={"60px"}
                            height={"24px"}
                            bgColor={"var(--color-Button1)"}
                            hoverBgColor={"var(--color-ButtonHover1)"}
                            fontColor={"var(--color-White)"}
                            buttonText={"시작"}
                        />
                    </ItemRight>
                </ItemRow>
                <ItemRow>
                    <ItemLeft>
                        <LabelSign />
                        <ItemLabel>연관태그 적용하기</ItemLabel>
                    </ItemLeft>
                    <ItemRight>
                        <Button
                            onlyText
                            onClick={props.onTagRecommendClick}
                            margin={"0 0 0 4px"}
                            width={"60px"}
                            height={"24px"}
                            bgColor={"var(--color-Button1)"}
                            hoverBgColor={"var(--color-ButtonHover1)"}
                            fontColor={"var(--color-White)"}
                            buttonText={"시작"}
                        />
                    </ItemRight>
                </ItemRow>
            </ContentsWrapper>
            <Footer>
                <Button
                    onlyText
                    onClick={props.exitModal}
                    margin={"0 0 0 4px"}
                    width={"80px"}
                    height={"32px"}
                    bgColor={"var(--color-Button1)"}
                    hoverBgColor={"var(--color-ButtonHover1)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"닫기"}
                />
            </Footer>
        </Container>
    );
}

export default ReferenceMetadataAnalyzeModal;
