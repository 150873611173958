import React, { useEffect, useState } from "react";

import styled, { keyframes } from "styled-components";

import Icon from "components/atoms/Icon";

const Animation = keyframes`
    0% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

const AnimationField = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;

const FlashWrap = styled.div`
    position: absolute;
    left: ${({ left }) => left};
    top: ${({ top }) => top};
    transform: scale(${(props) => props.scale});
`;

const FlashAnimation = styled.div`
    position: relative;
    animation-name: ${Animation};
    animation-duration: 5000ms;
    animation-delay: unset;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
`;

function FlashingStar(props) {
    // 랜덤 색상 재생
    const getRandomColor = () => {
        const colors = ["#E6F392", "#F6EA77", "#FFD15B", "#FFDF35", "#28F0C0"];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    // 랜덤 위치 재생
    const getRandomPosition = () => ({
        left: Math.random() * 50 + 10 + "%",
        top: Math.random() * 60 + 10 + "%",
    });

    // 랜덤 인터벌 재생
    const getRandomInterval = () => Math.floor(Math.random() * 1000) + 2000;

    // 랜덤 크기 재생
    const getRandomScale = () => {
        const scales = [0.6, 0.8, 1, 1.2, 1.4];
        return scales[Math.floor(Math.random() * scales.length)];
    };

    const [flashes, setFlashes] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => {
            const newFlash = (
                <FlashWrap key={Math.random()} scale={getRandomScale()} {...getRandomPosition()}>
                    <FlashAnimation>
                        <Icon name={"star"} color={getRandomColor()} />
                    </FlashAnimation>
                </FlashWrap>
            );
            setFlashes([...flashes, newFlash].slice(-5));
        }, getRandomInterval());

        return () => {
            clearInterval(interval);
        };
    }, [flashes]);

    return <AnimationField>{flashes}</AnimationField>;
}

export default FlashingStar;
