import React from "react";

import { ReactComponent as FooterLogo } from "assets/svg/image/FooterLogo.svg";
import { ReactComponent as HeaderLogo } from "assets/svg/image/HeaderLogo.svg";
import { ReactComponent as LandingLogo } from "assets/svg/image/LandingLogo.svg";
import { ReactComponent as LoginLogo } from "assets/svg/image/LoginLogo.svg";
import { ReactComponent as TooltipArrowIcon } from "assets/svg/image/TooltipArrowIcon.svg";

function Image({ name, ...rest }) {
    switch (name) {
        case "footerLogo":
            return <FooterLogo {...rest} />;
        case "headerLogo":
            return <HeaderLogo {...rest} />;
        case "landingLogo":
            return <LandingLogo {...rest} />;
        case "loginLogo":
            return <LoginLogo {...rest} />;
        case "tooltipArrow":
            return <TooltipArrowIcon {...rest} />;
        default:
            return null;
    }
}

export default Image;
