import { useQuery } from "react-query";

import { listReviewThread } from "utils/request/review";

export const useReviewThread = (reference_file_uuid) => {
    return useQuery("reviewThread", (e) => listReviewThread(reference_file_uuid), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};
