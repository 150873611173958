import React, { createContext, useState } from "react";

const initialState = {
    type: "info",
    text: "",
};

const ToastContext = createContext({
    ...initialState,
    setToast: () => {},
    removeToast: () => {},
});

export function ToastProvider({ children }) {
    const [display, setDisplay] = useState(false);
    const [type, setType] = useState("");
    const [text, setText] = useState("");

    const setToast = (texts, type) => {
        setText(texts);
        setType(type);
        setDisplay(true);
        setTimeout(function () {
            setDisplay(false);
        }, 3000);
    };

    const removeToast = () => {
        setDisplay(false);
    };

    const contextValue = {
        type,
        text,
        display,
        setToast,
        removeToast,
    };

    return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
}

export default ToastContext;
