import { createContext, useContext, useState } from "react";

const LoadingContext = createContext({
    setLoading: () => {},
    text: "",
    display: false,
});

export function LoadingProvider({ children }) {
    const [display, setDisplay] = useState(false);
    const [text, setText] = useState("Loading...");

    const setLoading = (loading, text) => {
        if (!text) {
            text = "Loading...";
        }
        setDisplay(loading);
        setText(text);
    };

    const value = { setLoading, text, display };
    return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}

export default useLoading;
