import React, { useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";

import InputWithLabel from "components/molecules/InputWithLabel";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

function ReferenceAddPubmedModal(props) {
    const [pubMedID, setPubMedID] = useState("");
    const { setLoading } = useLoading();

    const handleAddButton = () => {
        setLoading(true, "참고문헌 정보를 확인 중입니다.");
        axios
            .post(apiUrl("paperMetadata"), {
                reference_id: pubMedID,
                reference_source: "pubmed",
            })
            .then((res) => {
                setLoading(false);
                props.mutation.mutate({
                    body: {
                        display_name: res.data.display_name,
                        bib_json: res.data.bib_json,
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Alert("warn", "에러", "참고문헌 추가 중 오류가 발생했습니다.");
            });
    };

    return (
        <Container>
            <GeneralText size={"regular"}>참고문헌 추가를 위해 PubMed ID를 입력해주세요.</GeneralText>
            {/* PubMed ID로 참고문헌 추가 기능 */}
            <InputWithLabel
                width="100%"
                margin="24px 0 0 0"
                labelText="PubMed ID"
                essenMarkDp="none"
                bgColor="var(--color-Base1)"
                infoTextDp="block"
                infoText="PubMed"
                tooltipDp="flex"
                tooltipMessage="PubMed ID는 생명과학 분야의 논문 정보를 제공하는 PubMed에서 각 논문에 부여되는 고유한 식별자 번호입니다."
                placeholder="EX) 33126180"
                onChange={(e) => setPubMedID(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleAddButton();
                    }
                }}
                value={pubMedID}
            />
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"추가"}
                leftOnClick={props.exitModal}
                rightOnClick={handleAddButton}
                disabled={!pubMedID}
            />
        </Container>
    );
}

export default ReferenceAddPubmedModal;
