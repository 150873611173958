import React, { useEffect, useState } from "react";

import styled from "styled-components";

import SelectBox from "components/atoms/SelectBox";
import Alert from "components/atoms/alert/Alert";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import { useSelectBoxOptions } from "hooks/queries/useOptions";
import { useUser } from "hooks/queries/useUser";

import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    display: ${(props) => (props.isActive ? "block" : "none")};
    padding: 0 0 160px 0;
    width: 100%;
    overflow: auto;
`;

const InnerWrap = styled.div`
    margin: 40px 0 0 0;
    width: 100%;
`;

const Category = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 40px 0;
    width: 100%;

    &:last-child {
        margin: 0;
    }
`;

const CategoryRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
    width: 100%;
    height: 32px;

    &:last-child {
        margin: 0;
    }
`;

const CategoryContents = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 32px;
`;

const BlankSpace = styled.div`
    margin: 0;
    width: 200px;
`;

const barTitleStyle = {
    size: "small",
    width: "200px",
    fontWeight: "regular",
};

function TabEnvironView(props) {
    const [user, setUser] = useState({});
    const [options, setOptions] = useState({
        env_service_language: [],
        env_ai_language: [],
        env_ai_model: [],
        env_citation_style: [],
    });

    const userQuery = useUser();
    const optionsQuery = useSelectBoxOptions();

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
        if (optionsQuery.data) {
            setOptions(optionsQuery.data);
        }
    }, [userQuery.data, optionsQuery.data]);

    const changeEnvSettingsApi = (changed_settings) => {
        setUser({
            ...user,
            ...changed_settings,
        });
        axios
            .post(apiUrl("changeEnvSettings"), changed_settings)
            .catch((e) => {
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            })
            .finally(() => {
                userQuery.refetch();
            });
    };

    return (
        <Container isActive={props.isActive}>
            <InnerWrap>
                <TitleText size={"small"} margin={"0 0 24px 0"}>
                    서비스 전반에 영향을 주는 설정입니다.
                </TitleText>
                {/* 서비스 언어 설정 */}
                <Category>
                    <CategoryRow>
                        <TitleText withBarType {...barTitleStyle}>
                            서비스 언어
                        </TitleText>
                        <CategoryContents>
                            <GeneralText size={"small"}>메뉴, 설명에 사용되는 기본 언어입니다.</GeneralText>
                        </CategoryContents>
                    </CategoryRow>
                    <CategoryRow>
                        <BlankSpace />
                        <CategoryContents>
                            <SelectBox
                                width="160px"
                                height="32px"
                                placeholder="서비스 언어 설정"
                                items={options.env_service_language}
                                value={user.env_service_language}
                                onChange={(e) => changeEnvSettingsApi({ env_service_language: e })}
                            />
                        </CategoryContents>
                    </CategoryRow>
                </Category>
                <TitleText size={"small"} margin={"0 0 24px 0"}>
                    리서치 별, 화면 별 변경이 가능한 곳에서는 별도 옵션이 제공되는 설정입니다.
                </TitleText>
                {/* AI 언어 설정 */}
                <Category>
                    <CategoryRow>
                        <TitleText withBarType {...barTitleStyle}>
                            AI 응답 언어
                        </TitleText>
                        <CategoryContents>
                            <GeneralText size={"small"}>AI가 응답을 작성할 때 사용하는 기본 언어입니다.</GeneralText>
                        </CategoryContents>
                    </CategoryRow>
                    <CategoryRow>
                        <BlankSpace />
                        <CategoryContents>
                            <SelectBox
                                width="160px"
                                height="32px"
                                placeholder="AI 언어 설정"
                                items={options.env_ai_language}
                                value={user.env_ai_language}
                                onChange={(e) => changeEnvSettingsApi({ env_ai_language: e })}
                            />
                        </CategoryContents>
                    </CategoryRow>
                </Category>
                {/* AI 모델 설정 */}
                <Category>
                    <CategoryRow>
                        <TitleText withBarType {...barTitleStyle}>
                            AI 생성 모델
                        </TitleText>
                        <CategoryContents>
                            <GeneralText size={"small"}>AI가 응답을 작성할 때 사용하는 기본 모델입니다.</GeneralText>
                        </CategoryContents>
                    </CategoryRow>
                    <CategoryRow>
                        <BlankSpace />
                        <CategoryContents>
                            <SelectBox
                                width="160px"
                                height="32px"
                                placeholder="AI모델"
                                items={options.env_ai_model}
                                value={user.env_ai_model}
                                onChange={(e) => changeEnvSettingsApi({ env_ai_model: e })}
                            />
                        </CategoryContents>
                    </CategoryRow>
                </Category>
                {/* 인용 스타일 설정 */}
                <Category>
                    <CategoryRow>
                        <TitleText withBarType {...barTitleStyle}>
                            인용 스타일
                        </TitleText>
                        <CategoryContents>
                            <GeneralText size={"small"}>참고문헌 표기법을 변경할 수 있습니다.</GeneralText>
                        </CategoryContents>
                    </CategoryRow>
                    <CategoryRow>
                        <BlankSpace />
                        <CategoryContents>
                            <SelectBox
                                width="160px"
                                height="32px"
                                placeholder="인용 스타일"
                                items={options.env_citation_style}
                                value={user.env_citation_style}
                                onChange={(e) => changeEnvSettingsApi({ env_citation_style: e })}
                            />
                        </CategoryContents>
                    </CategoryRow>
                </Category>
            </InnerWrap>
        </Container>
    );
}

export default TabEnvironView;
