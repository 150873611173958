import React, { useRef } from "react";

import styled from "styled-components";

import Button from "components/atoms/button/Button";

import { getDefaultBibtex } from "utils/bibtex";

const Container = styled.div`
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    padding: 4px;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-color: var(--color-White);
    opacity: ${(props) => props.opacity};
    visibility: ${(props) => props.visibility};
    border: solid 2px var(--color-Outline);
    border-radius: 8px;
    overflow: hidden;
    transition: all 100ms ease-in-out;
    z-index: 10;
`;

const ButtonDivider = styled.span`
    display: block;
    margin: 8px auto;
    height: 1px;
    width: calc(100% - 32px);
    background-color: var(--color-Line);
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const ButtonStyle = {
    bgColor: "transparent",
    hoverBgColor: "var(--color-ButtonHover5)",
    fontColor: "var(--color-Black)",
};

function ReferenceAddMenu(props) {
    const fileRef = useRef(null);

    return (
        <Container width={props.width} height={props.height} opacity={props.opacity} visiblity={props.visiblity}>
            <HiddenFileInput
                type={"file"}
                ref={fileRef}
                accept={"application/pdf"}
                name={"file"}
                multiple
                onChange={(e) => props.fileAddHandler(e)}
            />
            <Button
                onlyText
                {...ButtonStyle}
                buttonText={"PDF로 추가"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    fileRef.current.click();
                }}
            />
            <ButtonDivider />
            {/* BibTeX로 추가 */}
            <Button
                onlyText
                {...ButtonStyle}
                buttonText={"BibTeX로 추가"}
                onClick={(e) => props.setModalState({ ...props.modalState, bibtexModal: true })}
            />
            <ButtonDivider />
            {/* ArXiv ID, DOI, PubMed ID로 추가 */}
            <Button
                onlyText
                {...ButtonStyle}
                buttonText={"ArXiv ID로 추가"}
                onClick={(e) => props.setModalState({ ...props.modalState, arxivModal: true })}
            />
            <Button
                onlyText
                {...ButtonStyle}
                buttonText={"DOI로 추가"}
                onClick={(e) => props.setModalState({ ...props.modalState, doiModal: true })}
            />
            <Button
                onlyText
                {...ButtonStyle}
                buttonText={"PubMed ID로 추가"}
                onClick={(e) => props.setModalState({ ...props.modalState, pubmedModal: true })}
            />
            <ButtonDivider />
            {/* 직접 추가 */}
            <Button
                onlyText
                {...ButtonStyle}
                buttonText={"직접 추가"}
                onClick={(e) =>
                    props.mutation.mutate({
                        body: getDefaultBibtex(),
                    })
                }
            />
        </Container>
    );
}

export default ReferenceAddMenu;
