import React from "react";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Background = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-Black);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    max-width: 800px;
    min-height: 100vh;
    background-color: white;
    border-right: solid 1px var(--color-Outline);
    border-left: solid 1px var(--color-Outline);
`;

const BackButton = styled.button`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0 0 24px 0;
    width: fit-content;
    background-color: var(--color-Base1);
    border: 1px solid var(--color-Outline);
    border-radius: 4px;
    cursor: pointer;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-Base2);
    }
`;

const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.margin || "24px 0 0 0"};

    &:first-child {
        margin: 0;
    }
`;

const ViewBoxWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const ItemViewBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 8px 0 0 0;
    padding: 24px;
    width: ${(props) => props.width || "100%"};
    min-height: 340px;
    background-color: var(--color-Base1);
    border-radius: 8px;
    border: solid 1px var(--color-Outline);
`;

const IconWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px 0;
    width: 160px;
`;

const ExplainBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px 0 0 0;
    padding: 24px;
    width: ${(props) => props.width || "100%"};
    min-height: 340px;
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Outline);
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
`;

const Pre = styled.pre`
    padding: 16px;
    font-size: 12px;
    color: var(--color-White);
    border-radius: 4px;
    background-color: var(--color-CodeBack);
    word-break: break-all;
    word-wrap: break-word;
    text-transform: none;
`;

function UxIconPage() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };

    const canChangeColorIconName = [
        "aiBot",
        "aiResponse",
        "ai",
        "alignCenter",
        "alignLeft",
        "alignRight",
        "asending",
        "bookmark",
        "botCalibration",
        "botReduce",
        "botRelay",
        "botStretch",
        "botTranslate",
        "bullet",
        "capture",
        "cardview",
        "check",
        "copy",
        "delete",
        "desending",
        "download",
        "downward",
        "enter",
        "fileUpload",
        "foldAll",
        "folder",
        "hamburger",
        "imageBlock",
        "info",
        "knob",
        "last",
        "leftward",
        "link",
        "list",
        "listview",
        "logout",
        "memo",
        "minus",
        "modify",
        "openAll",
        "option",
        "pdf",
        "play",
        "plus",
        "publisher",
        "question",
        "quote",
        "refresh",
        "release",
        "rightward",
        "search",
        "setting",
        "star",
        "stop",
        "submenu",
        "tag",
        "thread",
        "upward",
        "userFill",
        "user",
        "warn",
        "x",
    ];

    const cannotChangeColorIconName = [
        "alert",
        "bookmarkDisactive",
        "cancel",
        "cancelHover",
        "checkboxActive",
        "checkboxActiveDisabled",
        "checkboxTriState",
        "checkboxTriStateDisabled",
        "checkboxDisactive",
        "checkboxDisactiveDisabled",
        "connectOff",
        "connectOn",
        "fileUploadOff",
        "fileUploadOn",
        "foldAll",
        "folderDownward",
        "folderRightward",
        "load",
        "sideBarReference",
        "sideBarResearch",
        "sideBarResearchItem",
        "sideBarSearch",
        "sidemenuClose",
        "sidemenuOpen",
        "styleBulletList",
        "styleHeader1",
        "styleHeader2",
        "styleHeader3",
        "styleNormalText",
        "styleTextBgColor",
        "styleTextColor",
        "styleToggleList",
        "textBlock",
        "textStyle",
    ];

    return (
        <Background>
            <Container>
                <HtmlHead title={"아이콘 데모"} />
                <BackButton onClick={handleBack}>
                    <Icon name={"arrowLeft"} size={"12px"} />
                    <GeneralText margin={"0 0 0 8px"} size={"regular"} fontWeight={"500"}>
                        목록으로 돌아가기
                    </GeneralText>
                </BackButton>
                <SectionWrap>
                    <TitleText size={"large"}>아이콘</TitleText>
                    <ViewBoxWrap>
                        {/* 색상변경이 가능한 아이콘 */}
                        <TitleText margin={"40px 0 0 0"} size={"regular"}>
                            색상 변경이 가능한 아이콘
                        </TitleText>
                        <ItemViewBox width={"100%"}>
                            {canChangeColorIconName.map((name) => (
                                <IconWrap key={name}>
                                    <Icon name={name} size={"20px"} />
                                    <GeneralText margin={"4px 0 0 0"} size={"small"} textAlign={"center"}>
                                        {name}
                                    </GeneralText>
                                </IconWrap>
                            ))}
                        </ItemViewBox>
                        {/* 색상변경이 불가능한 아이콘 */}
                        <TitleText margin={"40px 0 0 0"} size={"regular"}>
                            색상 변경이 불가능한 아이콘
                        </TitleText>
                        <ItemViewBox width={"100%"}>
                            {cannotChangeColorIconName.map((name) => (
                                <IconWrap key={name}>
                                    <Icon name={name} size={"20px"} />
                                    <GeneralText margin={"4px 0 0 0"} size={"small"} textAlign={"center"}>
                                        {name}
                                    </GeneralText>
                                </IconWrap>
                            ))}
                        </ItemViewBox>
                    </ViewBoxWrap>
                </SectionWrap>
                {/* Checkbox 컴포넌트 사용 설명서 */}
                <SectionWrap margin="40px 0 0 0">
                    <TitleText size="large">Icon 컴포넌트 사용 설명서</TitleText>
                    <ExplainBox>
                        <GeneralText size="large" margin="0 0 16px 0">
                            사용 가능한 Props
                        </GeneralText>
                        <Table>
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            textAlign: "left",
                                            padding: "8px",
                                            borderBottom: "1px solid var(--color-Outline)",
                                        }}
                                    >
                                        Prop
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "left",
                                            padding: "8px",
                                            borderBottom: "1px solid var(--color-Outline)",
                                        }}
                                    >
                                        Type
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "left",
                                            padding: "8px",
                                            borderBottom: "1px solid var(--color-Outline)",
                                        }}
                                    >
                                        Default
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "left",
                                            padding: "8px",
                                            borderBottom: "1px solid var(--color-Outline)",
                                        }}
                                    >
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        name
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        string
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        -
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        사용할 아이콘의 이름
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        size
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        string
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        "10px"
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        아이콘의 크기
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        color
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        string
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        "var(--color-Black)"
                                    </td>
                                    <td style={{ padding: "8px", borderBottom: "1px solid var(--color-Outline)" }}>
                                        <Link
                                            to="/ux/component/color"
                                            style={{ color: "var(--color-Key)", textDecoration: "none" }}
                                        >
                                            아이콘의 색상
                                        </Link>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <GeneralText size="regular" margin="16px 0 0 0">
                            사용 예시:
                        </GeneralText>
                        <Pre>
                            {`<Icon
  name="search"
  size="24px"
  color="var(--color-Primary)"
/>`}
                        </Pre>
                    </ExplainBox>
                </SectionWrap>
            </Container>
        </Background>
    );
}

export default UxIconPage;
