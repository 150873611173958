import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Background = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-Black);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    max-width: 800px;
    min-height: 100vh;
    background-color: var(--color-White);
    border-right: solid 1px var(--color-Outline);
    border-left: solid 1px var(--color-Outline);
`;

const BackButton = styled.button`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin: 0 0 24px 0;
    width: fit-content;
    background-color: var(--color-Base1);
    border: 1px solid var(--color-Outline);
    border-radius: 4px;
    cursor: pointer;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-Base2);
    }
`;

const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.margin || "24px 0 0 0"};

    &:first-child {
        margin: 0;
    }
`;

const ViewBoxWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const ColorWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0;
    width: calc(20% - 8px);
`;

const ColorBox = styled.div`
    width: 50px;
    height: 50px;
    background-color: var(${(props) => `--color-${props.colorName}`});
    border: 1px solid var(--color-Outline);
    border-radius: 4px;
`;

const ExplainBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px 0 0 0;
    padding: 24px;
    width: ${(props) => props.width || "100%"};
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Outline);
`;

const Pre = styled.pre`
    padding: 16px;
    font-size: 12px;
    color: var(--color-White);
    border-radius: 4px;
    background-color: var(--color-CodeBack);
    word-break: break-all;
    word-wrap: break-word;
    text-transform: none;
`;

function UxColorPage() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const colors = [
        "White",
        "Black",
        "SubBlack",
        "Grey1",
        "Grey2",
        "Grey3",
        "Outline",
        "Line",
        "Base1",
        "Base2",
        "BaseBlue",
        "InfoBox",
        "InputLine",
        "DisabledButton",
        "DisabledText",
        "DisabledInput",
        "Tag",
        "TagActive",
        "Research",
        "ResearchActive",
        "Key",
        "Sub",
        "DarkKey",
        "Background",
        "Button1",
        "Button2",
        "Button3",
        "Button4",
        "ButtonHover1",
        "ButtonHover2",
        "ButtonHover3",
        "ButtonHover4",
        "ButtonHover5",
        "CodeBack",
        "Warn",
        "WarnHover",
        "TitleBar",
        "HighlightRed",
        "HighlightBlue",
        "HighlightYellow",
        "HighlightGreen",
        "HighlightPurple",
    ];

    return (
        <Background>
            <Container>
                <HtmlHead title={"색상 데모"} />
                <BackButton onClick={handleBack}>
                    <Icon name={"arrowLeft"} size={"12px"} />
                    <GeneralText margin={"0 0 0 8px"} size={"regular"} fontWeight={"500"}>
                        목록으로 돌아가기
                    </GeneralText>
                </BackButton>
                <SectionWrap>
                    <TitleText size={"large"}>색상</TitleText>
                    <ViewBoxWrap>
                        {colors.map((colorName) => (
                            <ColorWrap key={colorName}>
                                <ColorBox colorName={colorName} />
                                <GeneralText margin={"4px 0 0 0"} size={"small"} textAlign={"center"}>
                                    {colorName}
                                </GeneralText>
                            </ColorWrap>
                        ))}
                    </ViewBoxWrap>
                </SectionWrap>
                <SectionWrap margin="40px 0 0 0">
                    <TitleText size="large">색상 사용 설명서</TitleText>
                    <ExplainBox>
                        <GeneralText size="regular">사용 예시:</GeneralText>
                        <Pre>
                            {`const StyledButton = styled.button\`
  background-color: var(--color-Button1);
  color: var(--color-White);
  border: 1px solid var(--color-Outline);
\`;`}
                        </Pre>
                    </ExplainBox>
                </SectionWrap>
            </Container>
        </Background>
    );
}

export default UxColorPage;
