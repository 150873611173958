import React from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import moment from "moment/moment";

const Container = styled.div`
    width: 100%;
`;

const PlanInfoWrap = styled.div`
    margin: 40px 0 0 16px;
    width: calc(100% - 16px);
`;

const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px 0;
    width: 100%;
    height: 32px;
    border-bottom: dashed 1px var(--color-Line);

    &:last-child {
        margin: 0;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const SubInfoBox = styled.div`
    margin: 40px 0 0 0;
    padding: 8px 16px;
    width: 100%;
    background-color: var(--color-InfoBox);
    border-radius: 8px;
`;

const InfoListWrap = styled.ul`
    margin: 0;
    padding: 0 0 0 16px;
`;

const SubInfoItem = styled.li`
    margin: 0 0 8px 0;
    line-height: 1.4;
    align-text: justify;
    color: var(--color-SubBlack);

    &:last-child {
        margin: 0;
    }
`;

const subTitleStyle = {
    size: "subRegular",
    fontWeight: "regular",
    color: "var(--color-Grey1)",
};

const contentStyle = {
    size: "regular",
    color: "var(--color-Black)",
};

const listStyle = {
    size: "small",
    textAlign: "justify",
};

function PlanGroupInfoModal(props) {
    // props: planGroupInfo
    return (
        <Container>
            <TitleText withBarType size={"subRegular"}>
                요금제 정보
            </TitleText>
            <PlanInfoWrap>
                <InfoItem>
                    <TitleText {...subTitleStyle}>요금제</TitleText>
                    <GeneralText {...contentStyle}>단체 플랜</GeneralText>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>단체명</TitleText>
                    <GeneralText {...contentStyle}>{props.planGroupInfo?.name}</GeneralText>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>구성원</TitleText>
                    <GeneralText {...contentStyle}>{props.planGroupInfo?.max_member}명</GeneralText>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>구독 요금</TitleText>
                    <Row>
                        <GeneralText {...contentStyle}>₩{props.planGroupInfo?.price}/매달</GeneralText>
                    </Row>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>자동 결제일</TitleText>
                    <Row>
                        <GeneralText {...contentStyle} margin={"0 4px 0 0"}>
                            매월
                        </GeneralText>
                        <GeneralText {...contentStyle}>{moment().format("DD")}</GeneralText>
                        <GeneralText {...contentStyle} margin={"0 0 0 2px"}>
                            일
                        </GeneralText>
                    </Row>
                </InfoItem>
            </PlanInfoWrap>
            <SubInfoBox>
                <InfoListWrap>
                    <SubInfoItem>
                        <GeneralText {...listStyle}>등록된 결제카드로 매달 요금이 자동 결제됩니다.</GeneralText>
                    </SubInfoItem>
                    <SubInfoItem>
                        <GeneralText {...listStyle}>
                            자동 결제일의 날짜가 없는 달에는 말일에 자동 결제가 진행됩니다.
                        </GeneralText>
                    </SubInfoItem>
                    <SubInfoItem>
                        <GeneralText {...listStyle}>구독 중 결제 실패시 무료 요금제로 자동 변경됩니다.</GeneralText>
                    </SubInfoItem>
                    <SubInfoItem>
                        <GeneralText {...listStyle}>
                            유료 구독 중인 경우, 유료 구독 요금은 지난 결제일로부터 경과한 일 수를 계산하여 환불됩니다.
                            환불 신청 당일도 일 수에 포함됩니다.
                        </GeneralText>
                    </SubInfoItem>
                </InfoListWrap>
            </SubInfoBox>
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"구독"}
                leftOnClick={props.exitModal}
                rightOnClick={(e) => props.subscribeAct()}
            />
        </Container>
    );
}

export default PlanGroupInfoModal;
