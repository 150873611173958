import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import styled from "styled-components";

import LoadingImage from "assets/png/others/LoadingImage.png";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import AbstractModal from "components/page/modal/AbstractModal";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";
import ReferenceBibInfoAddFieldModal from "components/page/modal/ReferenceBibInfoAddFieldModal";

import { usePaperMetadata } from "hooks/queries/usePapers";

import Constants from "utils/constants";
import { getJsonToBib, patchBibliography } from "utils/request/bibliography";

import saveAs from "file-saver";

const Container = styled.div`
    width: 100%;
`;

const BibTeXContainer = styled.div`
    position: relative;
    padding: 6px;
    width: 100%;
    height: calc(100% - 80px);
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);
    background-color: var(--color-CodeBack);
    border: solid 2px var(--color-Black);
    border-radius: 8px;
    overflow: inherit;

    .buttonWrap {
        button {
            visibility: hidden;
            opacity: 0;
        }
    }

    &:hover {
        .buttonWrap {
            right: 16px;

            button {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    ${Constants.BIG_HEIGHT_MQ} {
        min-height: calc(100vh - 560px);
        max-height: calc(100vh - 560px);
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    height: calc(100vh - 416px);
    min-height: 100%;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-White);
    background-color: transparent;
    border: transparent;
    outline: none;
    resize: none;

    &::placeholder {
        color: var(--color-DisabledText);
    }

    &::-webkit-scrollbar {
        width: 6px;
        display: initial;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-Black);
    }
`;

const ButtonColumn = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 52px;
    transition: all 300ms ease-in-out;
`;

const TabviewWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.form`
    position: relative;
    width: 100%;
`;

const FormInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 80px);
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);

    ${Constants.BIG_HEIGHT_MQ} {
        min-height: calc(100vh - 560px);
        max-height: calc(100vh - 560px);
    }
`;

const InfoView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
`;

const LoadingImageWrap = styled.div`
    margin: 24px; 0 24px 0;
    width: 56px;
    height: 56px;
    background-image: url(${(props) => props.loadImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const IndicatorWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 40px 0;
    width: 120px;
`;

const Circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: var(--color-Key);
`;

const ScrollWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    margin: 0;
    padding: 16px;
    max-height: calc(100vh - 440px);
    background-color: var(--color-Base1);
    border-radius: 8px;
    overflow: auto;
`;

const FormItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 0 12px 0;
    width: 100%;
    background-color: transparent;
    border-bottom: solid 1px var(--color-Line);

    &:first-child {
        padding: 0 0 12px 0;
    }

    &:last-child {
        padding: 12px 0 0 0;
        border-bottom: transparent;
    }
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 140px;
    height: 24px;
`;

const ItemRight = styled.div`
    display: flex;
    align-items: flex-start;
    width: calc(100% - 148px);
`;

const FormRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0 0 8px 0;
    width: 100%;

    &:last-child {
        margin: 0;
    }
`;

const NameWrap = styled.div`
    position: relative;
    top: 4px;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px 0;
    padding: 0 0 0 8px;
    border-left: solid 2px var(--color-ButtonHover3);
    overflow-wrap: anywhere;
`;

const FormLabel = styled.label`
    margin: 0 0 0 8px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
`;

const FormInput = styled.input`
    padding: 0 8px;
    flex: 1;
    max-width: calc(100% - 32px);
    height: 24px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
    background-color: var(--color-White);
    border-radius: 4px;
    border: solid 1px var(--color-Outline);

    &:disabled {
        background-color: var(--color-DisabledInput);
    }

    &:read-only {
        background-color: var(--color-DisabledInput);
    }
`;

const LabelWrap = styled.div`
    display: flex;
    align-items: center;
    width: 80px;
    height: 24px;
`;

const FormRadioInput = styled.input`
    margin: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    outline: none;
    cursor: pointer;
`;

const TabHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
`;

const TabSection = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const TabButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    background-color: var(--color-White);
    border: Transparent;

    &:hover {
        p {
            color: var(--color-Key);
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(props) => (props.active ? "var(--color-Key)" : "var(--color-DisabledInput)")};
    }
`;

const TabViewSection = styled.div`
    margin: 24px 0 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 151px);
    overflow: auto;
`;

const PlusButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 8px 0 0 0;
`;

function ReferenceBibInfoLoadModal(props) {
    const [metaBib, setMetaBib] = useState("");
    const [isPaperMetadataAnalyzing, setIsPaperMetadataAnalyzing] = useState(false);
    const [activeTab, setActiveTab] = useState("json");

    const paperMetadataQuery = usePaperMetadata({ reference_id: props.bibliography.id });

    const { setToast } = useToast();

    const textareaRef = useRef(null);

    const [metaJson, setMetaJson] = useState({});
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [bibFieldAddModal, setBibFieldAddModal] = useState(false);

    useEffect(() => {
        return () => {
            paperMetadataQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (paperMetadataQuery.data) {
            let temp_dict = {};
            for (let key in props.bibliography.bib_json) {
                let temp_array = [];

                // 원본 json 정보 저장
                temp_array.push({
                    type: "origin",
                    value: props.bibliography.bib_json[key],
                    checked: true,
                });
                if (paperMetadataQuery.data.data.bib_json[key] !== null) {
                    // 파일에서 가져온 bib json 데이터에서 원본 json 데이터와 겹치는 부분이 있으면 값을 저장
                    if (key === "citation_key" || key === "entry_type") {
                    } else {
                        temp_array.push({
                            type: "imported",
                            value: paperMetadataQuery.data.data.bib_json[key],
                            checked: false,
                        });
                    }
                } else {
                    // 파일에서 가져온 bib json 데이터에서 원본 json 데이터와 겹치는 부분이 없으면 키만 저장
                    temp_array.push({ type: "imported", value: "", checked: false });
                    if (key === "citation_key" || key === "entry_type") {
                    } else {
                        temp_array.push({ type: "imported", value: "", checked: false });
                    }
                }
                temp_dict[key] = temp_array;
            }
            for (let key in paperMetadataQuery.data.data.bib_json) {
                if (key === "citation_key" || key === "entry_type") {
                } else {
                    if (!Object.keys(props.bibliography.bib_json).includes(key)) {
                        let temp_array = [];
                        temp_array.push({ type: "origin", value: "", checked: true });
                        temp_array.push({
                            type: "imported",
                            value: paperMetadataQuery.data.data.bib_json[key],
                            checked: false,
                        });
                        temp_dict[key] = temp_array;
                    }
                }
            }
            let dict = sortBibJson(temp_dict);
            setMetaJson(dict);
        }
    }, [paperMetadataQuery.data]);

    const sortBibJson = (bibJson) => {
        // 1. entry_type 2. citation_key 3. etc 순서로 변경한다.
        const sortedBibJson = {};
        const sortedKeys = Object.keys(bibJson).sort((a, b) => {
            if (a === "entry_type") {
                return -1;
            } else if (b === "entry_type") {
                return 1;
            } else if (a === "citation_key") {
                return -1;
            } else if (b === "citation_key") {
                return 1;
            } else {
                return 0;
            }
        });
        sortedKeys.forEach((key) => {
            sortedBibJson[key] = bibJson[key];
        });
        return sortedBibJson;
    };

    useEffect(() => {
        setIsPaperMetadataAnalyzing(paperMetadataQuery.isFetching);
    }, [paperMetadataQuery.isFetching]);

    const copyToClipboard = () => {
        if (textareaRef.current) {
            const bib = textareaRef.current.value;

            navigator.clipboard
                .writeText(bib)
                .then(() => {
                    setToast("복사되었습니다", "info");
                })
                .catch((error) => {
                    console.error("복사 실패:", error);
                });
        }
    };

    const handleMetaChecked = (field, obj) => {
        let temp_dict = { ...metaJson };
        temp_dict[field][0].checked = !temp_dict[field][0].checked;
        temp_dict[field][1].checked = !temp_dict[field][1].checked;
        setMetaJson(temp_dict);
    };

    const fieldAdd = (fieldName) => {
        if (fieldName in metaJson) {
            Alert("warn", "에러", "이미 존재하는 필드입니다.");
            return;
        }
        let temp_dict = { ...metaJson };
        let temp_array = [];
        temp_array.push({ type: "origin", value: "", checked: false });
        temp_array.push({ type: "imported", value: "", checked: true });
        temp_dict[fieldName] = temp_array;
        setMetaJson(temp_dict);
        setBibFieldAddModal(false);
        setToast("필드가 추가되었습니다.", "info");
    };

    const fieldDelete = (fieldName) => {
        let temp_dict = { ...metaJson };
        delete temp_dict[fieldName];
        setMetaJson(temp_dict);
        setToast("필드가 삭제되었습니다.", "info");
    };

    const onSubmit = (data, submitType) => {
        let jsonData = {};
        Object.keys(metaJson).map((field, index) => {
            let value = metaJson[field][0].checked ? data[`origin_${field}`] : data[`imported_${field}`];
            if (value) {
                jsonData[field] = value;
            }
        });

        if (submitType === "textView") {
            getJsonToBib(jsonData).then((res) => {
                if (res.bib) {
                    if (submitType === "textView") {
                        setMetaBib(res.bib);
                        return;
                    }
                } else {
                    Alert("warn", "에러", "BibTeX 변환에 실패했습니다.");
                    setActiveTab("json");
                }
            });
        } else {
            props.mutation.mutate(
                {
                    id: props.bibliography.id,
                    bib_json: jsonData,
                },
                {
                    onSuccess: (data, variables, context) => {
                        props.exitModal();
                        Alert("info", "성공", "참고문헌이 수정되었습니다.");
                    },
                },
            );
        }
    };

    return (
        <Container>
            <GeneralText size={"regular"}>
                PDF 원문을 분석하여 추천하는 서지정보입니다.
                <br />
                적용버튼을 선택하면 서지정보가 변경됩니다.
            </GeneralText>
            {isPaperMetadataAnalyzing ? (
                <InfoView>
                    <LoadingImageWrap loadImg={LoadingImage} />
                    <GeneralText size={"regular"} margin={"0 0 32px 0"} color={"var(--color-Black)"}>
                        {"PDF 파일을 분석하고 있습니다."}
                    </GeneralText>
                    <IndicatorWrap>
                        <Circle className="circle1" />
                        <Circle className="circle2" />
                        <Circle className="circle3" />
                        <Circle className="circle4" />
                        <Circle className="circle5" />
                    </IndicatorWrap>
                </InfoView>
            ) : (
                <>
                    <TabHeader>
                        <TabSection>
                            <TabButton onClick={() => setActiveTab("json")} active={activeTab === "json"}>
                                <GeneralText
                                    size={"regular"}
                                    color={activeTab === "json" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                                >
                                    Form
                                </GeneralText>
                            </TabButton>
                            <TabButton
                                onClick={(e) => {
                                    handleSubmit(onSubmit)("textView");
                                    setActiveTab("text");
                                }}
                                active={activeTab === "text"}
                            >
                                <GeneralText
                                    size={"regular"}
                                    color={activeTab === "text" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                                >
                                    BibTex
                                </GeneralText>
                            </TabButton>
                        </TabSection>
                    </TabHeader>
                    <TabViewSection>
                        <TabviewWrap onSubmit={handleSubmit(onSubmit)}>
                            <FormContainer style={{ display: activeTab !== "json" && "none" }}>
                                <FormInner>
                                    <ScrollWrap>
                                        {Object.keys(metaJson).map((field, index) => {
                                            return (
                                                <FormItemWrap key={index}>
                                                    {metaJson[field].map((obj, idx) => {
                                                        let options = { value: obj.value };
                                                        let label = obj.type === "origin" ? `${field}` : `${field}`;
                                                        let option = obj.type === "origin" ? `기존` : `추천`;

                                                        return (
                                                            <FormRow key={idx + obj.type}>
                                                                <ItemLeft
                                                                    style={
                                                                        obj.type === "origin" ? {} : { display: "none" }
                                                                    }
                                                                >
                                                                    <NameWrap>
                                                                        <TitleText size={"subRegular"}>
                                                                            {label}
                                                                        </TitleText>
                                                                    </NameWrap>
                                                                </ItemLeft>
                                                                <ItemRight>
                                                                    <LabelWrap
                                                                        style={
                                                                            obj.type === "origin" &&
                                                                            (field === "entry_type" ||
                                                                                field === "citation_key")
                                                                                ? { display: "none" }
                                                                                : {}
                                                                        }
                                                                    >
                                                                        <FormRadioInput
                                                                            type={"radio"}
                                                                            name={field}
                                                                            value={field}
                                                                            checked={obj.checked}
                                                                            onChange={(e) =>
                                                                                handleMetaChecked(field, obj)
                                                                            }
                                                                        />
                                                                        <FormLabel>{option}</FormLabel>
                                                                    </LabelWrap>
                                                                    <FormInput
                                                                        style={
                                                                            obj.type === "origin"
                                                                                ? {}
                                                                                : { maxWidth: "calc(100% - 112px)" }
                                                                        }
                                                                        {...register(`${obj.type}_${field}`, options)}
                                                                        readOnly={
                                                                            !obj.checked ||
                                                                            field === "entry_type" ||
                                                                            field === "citation_key"
                                                                        }
                                                                    />
                                                                    {/* TODO textarea가 들어가야하는 영역에 필요한 컴포넌트 */}
                                                                    {/* <FormText /> */}
                                                                    {obj.type === "origin" &&
                                                                        field !== "citation_key" &&
                                                                        field !== "entry_type" && (
                                                                            <Button
                                                                                key={index + "origin"}
                                                                                onlyIcon
                                                                                margin={"0 0 0 8px"}
                                                                                buttonSize={"24px"}
                                                                                bgColor={"var(--color-Button3)"}
                                                                                hoverBgColor={
                                                                                    "var(--color-ButtonHover3)"
                                                                                }
                                                                                onClick={(e) => fieldDelete(field)}
                                                                            >
                                                                                <Icon
                                                                                    name={"delete"}
                                                                                    size={"12"}
                                                                                    color={"var(--color-White)"}
                                                                                />
                                                                            </Button>
                                                                        )}
                                                                </ItemRight>
                                                            </FormRow>
                                                        );
                                                    })}
                                                </FormItemWrap>
                                            );
                                        })}
                                    </ScrollWrap>
                                    <PlusButtonWrap>
                                        <Button
                                            width={"100px"}
                                            height={"32px"}
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            fontColor={"var(--color-White)"}
                                            buttonText={"항목 추가"}
                                            onClick={(e) => setBibFieldAddModal(true)}
                                        >
                                            <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                                        </Button>
                                    </PlusButtonWrap>
                                </FormInner>
                            </FormContainer>

                            <BibTeXContainer style={{ display: activeTab !== "text" && "none" }}>
                                <Textarea ref={textareaRef} autoFocus value={metaBib} readOnly />
                                <ButtonColumn className="buttonWrap">
                                    <Tooltip message={"복사"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"rgba(30, 30, 30, 0.8)"}
                                            hoverBgColor={"var(--color-Key)"}
                                            onClick={(e) => copyToClipboard()}
                                        >
                                            <Icon name={"copy"} size={"12"} color={"var(--color-White)"} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip message={"다운로드"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"rgba(30, 30, 30, 0.8)"}
                                            hoverBgColor={"var(--color-Key)"}
                                            onClick={(e) =>
                                                saveAs(new Blob([metaBib]), `paper-${new Date().toISOString()}.bib`)
                                            }
                                        >
                                            <Icon name={"download"} size={"12"} color={"var(--color-White)"} />
                                        </Button>
                                    </Tooltip>
                                </ButtonColumn>
                            </BibTeXContainer>
                            <AbstractModalFooter
                                leftBtnText={"취소"}
                                rightBtnText={"적용"}
                                leftOnClick={props.exitModal}
                                rightOnClick={(e) => {
                                    handleSubmit(onSubmit)("formView");
                                }}
                            />
                        </TabviewWrap>
                    </TabViewSection>
                </>
            )}
            {bibFieldAddModal && (
                <AbstractModal
                    modalTitle="Bibtex 필드 추가"
                    width={"400"}
                    exitModal={(e) => setBibFieldAddModal(!bibFieldAddModal)}
                    id={"bibFieldAddModal"}
                >
                    <ReferenceBibInfoAddFieldModal
                        exitModal={(e) => setBibFieldAddModal(!bibFieldAddModal)}
                        addAction={fieldAdd}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceBibInfoLoadModal;
