import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import MenuItem from "components/atoms/MenuItem";

const ContextMenuContainer = styled.div`
    position: fixed;
    background-color: var(--color-White);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    padding: 10px;
    z-index: 1000;
    transform: translate(${(props) => props.translate || "0, 26px"});
    visibility: ${(props) => (props.show ? "visible" : "hidden")};
    opacity: ${(props) => (props.show ? "1" : "0")};
    ${(props) => props.position && `left: ${props.position.x}px; top: ${props.position.y}px;`}
`;

const ContextMenu = ({ items, show, translate, minWidth, onClose, triggerClassName, position = undefined }) => {
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [menuPosition, setMenuPosition] = useState(position);

    const menuRef = useRef(); // 컨테이너를 위한 ref 생성

    useEffect(() => {
        if (position) {
            const triggerNode = document.querySelector(`.${triggerClassName}`);
            let triggerRectY = 0;
            if (triggerNode) {
                triggerRectY = triggerNode.getBoundingClientRect().height;
            }
            const { x, y } = position;
            const menuHeight = menuRef.current ? menuRef.current.clientHeight : 0;
            const newY = y + menuHeight > window.innerHeight ? y - menuHeight - triggerRectY : y;
            setMenuPosition({ x, y: newY });
        }
    }, [position]);

    // 외부 클릭을 감지하기 위한 effect
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                const closestTrigger = event.target.closest(`.${triggerClassName}`);
                if (!closestTrigger) {
                    // 트리거 외부에서 클릭되었을 때
                    setShowColorPicker(false);
                    onClose();
                }
            }
        }

        // 클릭 리스너 추가
        document.addEventListener("mousedown", handleClickOutside);

        // 클린업 함수를 반환하여 리스너 제거
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]); // onClose를 의존성 배열에 추가하여 변경될 때마다 effect를 다시 실행

    useEffect(() => {
        if (translate) {
            if (menuRef.current.bottom > window.innerHeight) {
                menuRef.current.style.transform = `translate(${translate}, -100%)`;
            }
        }
    }, [translate]);
    return (
        <ContextMenuContainer show={show} translate={translate} ref={menuRef} position={menuPosition}>
            {items.map((item, index) => (
                <MenuItem
                    key={index}
                    item={item}
                    minWidth={minWidth}
                    depth={0}
                    onClose={onClose}
                    showColorPicker={showColorPicker}
                    setShowColorPicker={setShowColorPicker}
                />
            ))}
        </ContextMenuContainer>
    );
};

export default ContextMenu;
