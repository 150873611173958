import React from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
`;

const Wrapper = styled.div`
    position: relative;
    top: 0;
`;

const AlertContainer = styled.div`
    width: 320px;
    height: auto;
    border: solid 1px var(--color-Outline);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
`;

const ContentsSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 100%;
    height: auto;
    background-color: var(--color-Base1);
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 12px 0;
`;

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.bgColor};
    border-radius: 4px;
`;

const MessageWrap = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    white-space: pre-wrap;
`;

const ButtonsSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    width: 100%;
    height: auto;
    background-color: var(--color-White);
`;

function AbstractAlert(props) {
    const { type, title, message, buttonName, onConfirm, onClose } = props;
    const getBackgroundColor = () => {
        return type === "info" ? "var(--color-Button1)" : "var(--color-Warn)";
    };

    const getHoverColor = () => {
        return type === "info" ? "var(--color-ButtonHover1)" : "var(--color-WarnHover)";
    };

    const getIconComponent = () => {
        return <Icon name={type === "info" ? "info" : "warn"} size={"12"} color={"var(--color-White)"} />;
    };

    const renderButtons = () => {
        if (!onConfirm) {
            return (
                <Button
                    onlyText
                    width={"64px"}
                    height={"32px"}
                    bgColor={"var(--color-Button1)"}
                    hoverBgColor={"var(--color-ButtonHover1)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"확인"}
                    onClick={onClose}
                    singleButton
                />
            );
        } else {
            return (
                <>
                    <Button
                        onlyText
                        width={"64px"}
                        height={"32px"}
                        bgColor={"var(--color-Button4)"}
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        fontColor={"var(--color-SubBlack)"}
                        buttonText={"취소"}
                        onClick={onClose}
                    />
                    <Button
                        onlyText
                        margin={"0 0 0 4px"}
                        width={"64px"}
                        height={"32px"}
                        bgColor={getBackgroundColor}
                        hoverBgColor={getHoverColor}
                        fontColor={"var(--color-White)"}
                        buttonText={buttonName}
                        onClick={(e) => {
                            onConfirm();
                            onClose();
                        }}
                    />
                </>
            );
        }
    };

    return (
        <Container>
            <Wrapper className="alert">
                <AlertContainer>
                    <ContentsSection>
                        <Row>
                            <IconBox bgColor={getBackgroundColor}>{getIconComponent()}</IconBox>
                            <TitleText size={"subRegular"}>{title}</TitleText>
                        </Row>
                        <MessageWrap>
                            <GeneralText size={"regular"}>{message}</GeneralText>
                        </MessageWrap>
                    </ContentsSection>
                    <ButtonsSection>{renderButtons()}</ButtonsSection>
                </AlertContainer>
            </Wrapper>
        </Container>
    );
}

export default AbstractAlert;
