import { pick } from "lodash";

export const toBibJson = (scienceon_item) => {
    let bib_json = pick(scienceon_item, [
        "entry_type",
        "title",
        "year",
        "author",
        "journal",
        "publisher",
        "keyword",
        "abstract",
        "doi",
    ]);
    bib_json["citation_key"] = scienceon_item.scienceon_cn;

    return bib_json;
};
