import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import LandingBackground1 from "assets/png/landingPage/LandingBackground1.png";
import LandingBackground2 from "assets/png/landingPage/LandingBackground2.png";
import PDFAnalyseImage1 from "assets/png/landingPage/PDFAnalyseImage1.png";
import PDFAnalyseImage2 from "assets/png/landingPage/PDFAnalyseImage2.png";
import PDFAnalyseImage3 from "assets/png/landingPage/PDFAnalyseImage3.png";
import PDFAnalyseImage4 from "assets/png/landingPage/PDFAnalyseImage4.png";
import PDFAnalyseImage5 from "assets/png/landingPage/PDFAnalyseImage5.png";
import RefManagement1 from "assets/png/landingPage/RefManagement1.png";
import RefManagement2 from "assets/png/landingPage/RefManagement2.png";
import RefManagement3 from "assets/png/landingPage/RefManagement3.png";
import RefManagement4 from "assets/png/landingPage/RefManagement4.png";
import RefManagement5 from "assets/png/landingPage/RefManagement5.png";
import RefManagement6 from "assets/png/landingPage/RefManagement6.png";
import RefManagement7 from "assets/png/landingPage/RefManagement7.png";
import RefManagement8 from "assets/png/landingPage/RefManagement8.png";
import RefManagement9 from "assets/png/landingPage/RefManagement9.png";
import ResearchManagement1 from "assets/png/landingPage/ResearchManagement1.png";
import ResearchManagement2 from "assets/png/landingPage/ResearchManagement2.png";
import StarIcon from "assets/png/landingPage/StarIcon.png";
import TitleDeco from "assets/png/landingPage/TitleDeco.png";

import GeneralText from "components/atoms/text/GeneralText";

import { useDevice } from "utils/device";

import AOS from "aos";
import "aos/dist/aos.css";

const Container = styled.div`
    margin: 80px 0 0 0;
    width: 100%;
`;

const WidthLimit = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    max-width: 920px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 0 40px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 80px;
        `};
`;

const FirstSection = styled.div`
    position: relative;
    padding: 80px 0;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 160px 0;
        `};
`;

const SecondSection = styled.div`
    position: relative;
    padding: 80px 0;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 160px 0;
        `};
`;

const ThirdSection = styled.div`
    position: relative;
    padding: 80px 0;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 160px 0;
        `};
`;

const LastSection = styled.div`
    position: relative;
    margin: 0 0 24px 0;
    padding: 80px 0;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 0 0 80px 0;
        `};
`;

const SectionTitleWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TitleDecoWrap = styled.div`
    margin: 0 0 8px 0;
    width: 44px;
    height: 44px;
    background-image: url("${(props) => props.bgImage}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const SectionTitle = styled.h1`
    margin: 0 0 8px 0;
    font-size: 32px;
    font-family: "LINESeedKR";
    font-weight: 400;
    color: var(--color-Black);
`;

const SubSectionWrap = styled.div`
    position: relative;
    margin: 80px 0 0 0;
    width: 100%;
`;

const SubSecTitleWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 40px 0;
`;

const SubSecTitle = styled.h2`
    margin: 0 0 12px 0;
    font-size: 24px;
    font-family: "LINESeedKR";
    font-weight: 400;
    color: var(--color-Black);

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 0 0 16px 0;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            margin: 0 0 24px 0;
        `};
`;

const SubSecDecoBar = styled.div`
    width: 48px;
    height: 2px;
    background-color: var(--color-DarkKey);

    ${(props) =>
        props.device.isPC &&
        css`
            width: 64px;
        `};
`;

const ContentBoxWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            justify-content: space-between;
        `};
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 40px 0;
    width: ${(props) => props.width};

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 0;
        `};
`;

const ImageBox = styled.div`
    margin: 0 auto 24px auto;
    padding: ${(props) => props.paddingTop || "57%"} 0 0 0;
    width: calc(100% - 24px);
    border-radius: 8px;
    background-image: url("${(props) => props.bgImage}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12);
    overflow: hidden;
`;

const ContentTitleWrap = styled.div`
    margin: 0 0 24px 0;
    width: ${(props) => props.width || "100%"};
    text-align: center;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 0 auto;
            text-align: left;
        `};
`;

const TitleLine = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 4px 0;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            justify-content: flex-start;
            margin: 0 0 8px 0;
        `};
`;

const ContentTitle = styled.h3`
    margin: 4px 6px 0 0;
    font-size: 20px;
    font-family: "LINESeedKR";
    font-weight: 400;
    color: var(--color-Black);
`;

const NoteDescriptionWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    width: calc(100% - 16px);
`;

const CopyText = styled.p`
    margin: 0 0 16px 0;
    font-size: 18px;
    font-family: "LineSeedKR";
    font-weight: 400;
    color: var(--color-Black);

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            font-size: 20px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            font-size: 24px;
        `};
`;

const EmphasisText = styled.span`
    margin: 0 0 0 8px;
    font-weight: 700;
    color: var(--color-DarkKey);
`;

const StartText = styled.h2`
    margin: 0 0 24px 0;
    font-size: 32px;
    font-family: "LineSeedKR";
    font-weight: 700;
    color: var(--color-Black);

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 0 0 48px 0;
            font-size: 40px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            font-size: 48px;
        `};
`;

const StartButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 28px;
    border: transparent;
    border-radius: 12px;
    background: linear-gradient(180deg, #00725d, #066786);
    outline: none;
    transition: all 120ms ease-in;

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.6);
        transition: all 200ms ease-in;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 16px 40px;
        `};
`;

const StartIconWrap = styled.div`
    margin: 0 8px 2px 0;
    width: 18px;
    height: 18px;
    z-index: 1;

    img {
        width: 100%;
    }

    ${(props) =>
        props.device.isPC &&
        css`
            margin: 0 12px 4px 0;
            width: 24px;
            height: 24px;
        `};
`;

const StartButtonText = styled.p`
    margin: 1px 0 0 0;
    font-size: 18px;
    font-family: "LineSeedKR";
    font-weight: 700;
    color: var(--color-White);
    z-index: 1;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            font-size: 20px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            font-size: 24px;
        `};
`;

const LastSecBgImage = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    width: calc(100% - 48px);
    height: 100%;
    border-radius: 16px;
    text-align: center;
    overflow: hidden;
    z-index: -1;

    img {
        height: 100%;
    }

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            width: calc(100% - 80px);
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            width: calc(100% - 160px);
        `};
`;

const FreeBadge = styled.div`
    padding: 1px 4px;
    min-width: 40px;
    background-color: var(--color-White);
    border: solid 2px var(--color-DarkKey);
    border-radius: 6px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 2px 6px;
            border-radius: 8;
        `};
`;

const SectionBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    margin: 0 auto;
    width: 100%;
    max-width: unset;
    overflow: hidden;
    z-index: -1;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: block;
            width: calc(100% - 64px);
            height: 600px;
            border-radius: 16px;

            > img {
                width: auto;
                height: 100%;
            }
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            width: calc(100% - 160px);
            max-width: 1280px;

            > img {
                width: 100%;
            }
        `};
`;

function IntroductionSection(props) {
    const device = useDevice();
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 800,
            easing: "ease-in-out",
            once: true,
        });
    }, []);

    return (
        <Container>
            {/* 참고문헌 PDF 분석 section */}
            <FirstSection device={device}>
                <SectionBackground device={device}>
                    <img src={LandingBackground1} alt={"섹션구분백그라운드이미지"} />
                </SectionBackground>
                <WidthLimit device={device}>
                    <SectionTitleWrap
                        {...(device.isTabletOrPC
                            ? {
                                  "data-aos-delay": "400",
                              }
                            : {})}
                        data-aos="fade-up"
                    >
                        <TitleDecoWrap bgImage={TitleDeco} />
                        <SectionTitle device={device}>참고문헌 PDF 분석</SectionTitle>
                        <GeneralText size={device.isTabletOrPC ? "landing" : "large"} textAlign={"center"}>
                            논문 한 편 읽는데 하루가 걸린다고요?
                            <br />
                            AI 기술로 논문을 빠르게 파악하세요.
                        </GeneralText>
                    </SectionTitleWrap>

                    {/* PDF 자동 분석 */}
                    <SubSectionWrap>
                        <SubSecTitleWrap className="firstSection1" data-aos="fade-up">
                            <SubSecTitle device={device}>PDF 자동 분석</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".firstSection1",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={PDFAnalyseImage1} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>AI 요약</ContentTitle>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        연구 주제, 연구 방법, 연구 결과를 간결하게 제시합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".firstSection1",
                                          "data-aos-delay": "300",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={PDFAnalyseImage2} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>AI 비평</ContentTitle>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        동료 연구자의 입장에서 논문의 훌륭한 점과 아쉬운 점, 보완 방향을 제시합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>

                    {/* PDF 리뷰 환경 */}
                    <SubSectionWrap>
                        <SubSecTitleWrap className="firstSection2" data-aos="fade-up">
                            <SubSecTitle device={device}>PDF 리뷰 환경</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(33.3% - 27.7px)" : "80%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".firstSection2",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={PDFAnalyseImage3} paddingTop={"89.36%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>하이라이트</ContentTitle>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        선택 영역에 메모를 추가할 수 있고, AI에게 번역, 요약, 설명을 요청할 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(33.3% - 27.7px)" : "80%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".firstSection2",
                                          "data-aos-delay": "300",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={PDFAnalyseImage4} paddingTop={"89.36%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>자유질문</ContentTitle>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        하이라이트 없이 질문해도 논문에서 연관된 부분을 찾아 대답합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(33.3% - 27.7px)" : "80%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".firstSection2",
                                          "data-aos-delay": "400",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={PDFAnalyseImage5} paddingTop={"89.36%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>스레드 관리</ContentTitle>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        대화 기록을 스레드로 관리하여 맥락을 고려한 답변을 생성합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>
                </WidthLimit>
            </FirstSection>

            {/* 참고문헌 목록 관리 section */}
            <SecondSection device={device}>
                <SectionBackground device={device}>
                    <img src={LandingBackground1} alt={"섹션구분백그라운드이미지"} />
                </SectionBackground>
                <WidthLimit device={device}>
                    <SectionTitleWrap data-aos="fade-up">
                        <TitleDecoWrap bgImage={TitleDeco} />
                        <SectionTitle device={device}>참고문헌 목록 관리</SectionTitle>
                        <GeneralText size={device.isTabletOrPC ? "landing" : "large"} textAlign={"center"}>
                            PDF 파일은 한 폴더에 저장만 하면 끝이라고요?
                            <br />
                            참고문헌 관리는 기본! 무료로 이용하세요.
                        </GeneralText>
                    </SectionTitleWrap>

                    {/* 논문 검색 & 추천 */}
                    <SubSectionWrap>
                        <SubSecTitleWrap className="secondSection1" data-aos="fade-up">
                            <SubSecTitle device={device}>논문 검색 & 추천</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection1",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement1} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>논문 DB 검색</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        키워드로 논문을 검색하고, 내 참고문헌 목록에 추가할 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection1",
                                          "data-aos-delay": "300",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement2} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>연관 논문 추천</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        참고문헌을 선택하면 연관된 논문을 추천받을 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>

                    {/* 참고문헌 저장소 */}
                    <SubSectionWrap>
                        <SubSecTitleWrap className="secondSection2" data-aos="fade-up">
                            <SubSecTitle device={device}>참고문헌 저장소</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection2",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement3} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>참고문헌 저장공간</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        PDF 파일을 업로드 하기 위한 웹 저장소를 제공하여 어디서나 접근 가능합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection2",
                                          "data-aos-delay": "300",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement4} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>참고문헌 추가</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        논문 DB 검색, PDF 업로드, BibTeX, 논문 ID (ArXiv ID, DOI, PubMed ID)로
                                        참고문헌을 추가할 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>

                    {/* 서지정보 관리 */}
                    <SubSectionWrap>
                        <SubSecTitleWrap className="secondSection3" data-aos="fade-up">
                            <SubSecTitle device={device}>서지정보 관리</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(33.3% - 27.7px)" : "80%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection3",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement5} paddingTop={"89.36%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>서지정보 자동 추출</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        PDF 파일에서 논문의 서지정보를 자동으로 인식하여 BibTeX로 관리합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(33.3% - 27.7px)" : "80%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection3",
                                          "data-aos-delay": "300",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement6} paddingTop={"89.36%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>인용 스타일 변환</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"small"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        ACM, APA, Chicago, MLA 등 다양한 포맷을 지원합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(33.3% - 27.7px)" : "80%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection3",
                                          "data-aos-delay": "400",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement7} paddingTop={"89.36%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>BibTeX 다운로드</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"small"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        참고문헌 목록을 선택하여 BibTeX 파일로 다운받을 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>

                    {/* PDF 상세 검색 */}
                    <SubSectionWrap>
                        <SubSecTitleWrap className="secondSection4" data-aos="fade-up">
                            <SubSecTitle device={device}>PDF 상세 검색</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection4",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement8} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>AI 태그 추천</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        PDF 업로드시 태그가 자동 지정되어, 참고문헌을 쉽게 분류하고 검색할 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "calc(50% - 20px)" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".secondSection4",
                                          "data-aos-delay": "300",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={RefManagement9} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>전문 (Full-text) 검색</ContentTitle>
                                        <FreeBadge device={device}>
                                            <GeneralText
                                                size={"regular"}
                                                fontWeight={"600"}
                                                color={"var(--color-DarkKey)"}
                                            >
                                                무료
                                            </GeneralText>
                                        </FreeBadge>
                                    </TitleLine>
                                    <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                        제목 뿐만 아니라 본문의 텍스트도 검색합니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>
                </WidthLimit>
            </SecondSection>

            {/* 리서치 목록 관리 section */}
            <ThirdSection device={device}>
                <SectionBackground device={device}>
                    <img src={LandingBackground1} alt={"섹션구분백그라운드이미지"} />
                </SectionBackground>
                <WidthLimit device={device}>
                    <SectionTitleWrap data-aos="fade-up">
                        <TitleDecoWrap bgImage={TitleDeco} />
                        <SectionTitle device={device}>리서치 노트 관리</SectionTitle>
                        <GeneralText size={device.isTabletOrPC ? "landing" : "large"} textAlign={"center"}>
                            논문 쓸 때 빈 화면만 보면 아득하다고요?
                            <br />
                            참고문헌을 바탕으로 논문 초안 작성도 AI와 함께 스피드업!
                        </GeneralText>
                    </SectionTitleWrap>
                    <SubSectionWrap>
                        <SubSecTitleWrap className="thirdSection1" data-aos="fade-up">
                            <SubSecTitle device={device}>리서치 노트 편집 환경</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "100%" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".thirdSection1",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={ResearchManagement1} paddingTop={"44%"} />
                                <NoteDescriptionWrap>
                                    <ContentTitleWrap
                                        device={device}
                                        width={device.isTabletOrPC ? "calc(50% - 32px)" : "calc(100% - 16px)"}
                                    >
                                        <TitleLine device={device}>
                                            <ContentTitle>AI 편집 도구</ContentTitle>
                                        </TitleLine>
                                        <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                            텍스트 교정, 번역, 늘이기, 줄이기 등으로 AI와 함께 더 나은 글을 작성합니다.
                                        </GeneralText>
                                    </ContentTitleWrap>
                                    <ContentTitleWrap
                                        device={device}
                                        width={device.isTabletOrPC ? "calc(50% - 32px)" : "calc(100% - 16px)"}
                                    >
                                        <TitleLine device={device}>
                                            <ContentTitle>서식 편집 도구</ContentTitle>
                                            <FreeBadge device={device}>
                                                <GeneralText
                                                    size={"regular"}
                                                    fontWeight={"600"}
                                                    color={"var(--color-DarkKey)"}
                                                >
                                                    무료
                                                </GeneralText>
                                            </FreeBadge>
                                        </TitleLine>
                                        <GeneralText size={device.isTabletOrPC ? "xlarge" : "large"}>
                                            텍스트와 이미지 블록을 드래그하여 문단의 순서를 조정할 수 있습니다.
                                            <br />
                                            글자 크기, 색상, 배경색, 글머리 목록 등 다양한 서식을 지원합니다.
                                        </GeneralText>
                                    </ContentTitleWrap>
                                </NoteDescriptionWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>
                    <SubSectionWrap>
                        <SubSecTitleWrap className="thirdSection2" data-aos="fade-up">
                            <SubSecTitle device={device}>참고문헌 자동 분석</SubSecTitle>
                            <SubSecDecoBar device={device} />
                        </SubSecTitleWrap>
                        <ContentBoxWrap device={device}>
                            <ContentBox
                                device={device}
                                width={device.isTabletOrPC ? "100%" : "90%"}
                                {...(device.isTabletOrPC
                                    ? {
                                          "data-aos-anchor": ".thirdSection2",
                                          "data-aos-delay": "200",
                                      }
                                    : {})}
                                data-aos="fade-up"
                            >
                                <ImageBox bgImage={ResearchManagement2} paddingTop={"44%"} />
                                <ContentTitleWrap device={device}>
                                    <TitleLine device={device}>
                                        <ContentTitle>관련연구 초안 작성</ContentTitle>
                                    </TitleLine>
                                    <GeneralText
                                        size={device.isTabletOrPC ? "xlarge" : "large"}
                                        width={"calc(100% - 16px)"}
                                    >
                                        리서치에 참고문헌을 인용할 수 있고, 인용된 참고문헌 목록을 바탕으로 논문에
                                        들어갈 관련 연구 초안을 작성할 수 있습니다.
                                    </GeneralText>
                                </ContentTitleWrap>
                            </ContentBox>
                        </ContentBoxWrap>
                    </SubSectionWrap>
                </WidthLimit>
            </ThirdSection>

            {/* 가입 권유 section */}
            <LastSection device={device}>
                <WidthLimit device={device}>
                    <CopyText className="lastSection" device={device} data-aos="fade-up">
                        <span>연구자를 위한 최고의 선택</span>
                        <EmphasisText>프렙!</EmphasisText>
                    </CopyText>
                    <StartText device={device} data-aos-anchor=".lastSection" data-aos="fade-up" data-aos-delay="50">
                        지금 시작해보세요
                    </StartText>
                    <StartButton
                        device={device}
                        data-aos-anchor=".lastSection"
                        data-aos="fade-in"
                        data-aos-delay="400"
                        onClick={(e) => {
                            navigate("/signup");
                        }}
                    >
                        <StartIconWrap device={device}>
                            <img src={StarIcon} alt={"별아이콘"} />
                        </StartIconWrap>
                        <StartButtonText device={device}>회원가입하기</StartButtonText>
                    </StartButton>
                </WidthLimit>
                <LastSecBgImage device={device}>
                    <img src={LandingBackground2} alt={"백그라운드이미지"} />
                </LastSecBgImage>
            </LastSection>
        </Container>
    );
}

export default IntroductionSection;
