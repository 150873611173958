const generateCitationKey = () => {
    const char_source = "abcdefghijklmnopqrstuvwxyz-0123456789";
    const len = 10;

    let result = "";
    const len_char_source = char_source.length;
    for (let i = 0; i < len; i++) {
        result += char_source.charAt(Math.floor(Math.random() * len_char_source));
    }

    return result;
};

export const getDefaultBibtex = (display_name = "제목없는 참고문헌") => {
    return {
        display_name: display_name,
        bib_json: {
            citation_key: generateCitationKey(),
            entry_type: "misc",
            title: "The title of the miscellaneous entry",
            author: "A. Author",
            month: "1",
            year: "2024",
            note: "An optional note",
        },
    };
};
