import React from "react";

import styled, { css } from "styled-components";

import Icon from "components/atoms/Icon";
import GeneralText from "components/atoms/text/GeneralText";

import { useDevice } from "utils/device";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 671px;
    height: 48px;
    background-color: #ca1313;
`;

const WidthLimit = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    max-width: 1000px;

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 80px;
        `};
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const ViewPlanBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 16px;
    padding: 0 12px;
    min-width: 80px;
    height: 24px;
    outline: none;
    background-color: transparent;
    border: solid 1px var(--color-White);
    border-radius: 12px;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-White);

        p {
            color: var(--color-Black);
        }
    }
`;

function AlertBanner(props) {
    const device = useDevice();

    return (
        <Container>
            <WidthLimit device={device}>
                <Row>
                    <Icon name={"warn"} size={"14"} color={"var(--color-White)"} />
                    <GeneralText size={"regular"} margin={"0 0 0 12px"} color={"var(--color-White)"}>
                        {props.alertMessage}
                    </GeneralText>
                </Row>
                <ViewPlanBtn>
                    <GeneralText size={"small"}>요금제 확인</GeneralText>
                </ViewPlanBtn>
            </WidthLimit>
        </Container>
    );
}

export default AlertBanner;
