import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Background = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-Black);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    max-width: 800px;
    min-height: 100vh;
    background-color: white;
    border-right: solid 1px var(--color-Outline);
    border-left: solid 1px var(--color-Outline);
`;

const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.margin || "24px 0 0 0"};

    &:first-child {
        margin: 0;
    }
`;

const ComponentList = styled.ul`
    list-style-type: none;
    margin: 24px 0 0 0;
    padding: 0;
`;

const ComponentItem = styled.li`
    margin: 0 0 16px 0;
`;

const ComponentLink = styled(Link)`
    display: block;
    padding: 16px;
    background-color: var(--color-Base1);
    border: solid 2px var(--color-Base2);
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    color: var(--color-Black);
    transition: all 150ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
        border: solid 2px var(--color-DarkKey);
    }
`;

function UxListPage() {
    const components = [
        { name: "Color", path: "/ux/component/color" },
        { name: "Icon", path: "/ux/component/icon" },
    ];

    return (
        <Background>
            <Container>
                <HtmlHead title={"UX 컴포넌트 목록"} />
                <SectionWrap>
                    <TitleText size={"large"} textAlign={"center"}>
                        UX 컴포넌트 목록
                    </TitleText>
                    <ComponentList>
                        {components.map((component, index) => (
                            <ComponentItem key={index}>
                                <ComponentLink to={component.path}>
                                    <GeneralText size={"xlarge"} fontWeight={"600"} color={"var(--color-SubBlack)"}>
                                        {component.name}
                                    </GeneralText>
                                </ComponentLink>
                            </ComponentItem>
                        ))}
                    </ComponentList>
                </SectionWrap>
            </Container>
        </Background>
    );
}

export default UxListPage;
