import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useTagReferenceCreate, useTags } from "hooks/queries/useBibliographies";

const Container = styled.div`
    width: 100%;
`;

const MessageLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 40px 0;
    width: 100%;
    height: 320px;
`;

const NormalView = styled.div`
    display: block;
    padding: 8px;
    width: 100%;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-Base1);
    border-radius: 4px;
    overflow: auto;
`;

const BlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TagListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const TagItem = styled.div`
    margin: 0 4px 4px 0;
    padding: 6px;
    max-width: 240px;
    height: 28px;
    background-color: ${(props) => (props.selected ? "var(--color-TagActive)" : "var(--color-Tag)")};
    border: solid 1px transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 120ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }
`;

function ReferenceTagMultiAddModal(props) {
    const { exitModal, referenceIds } = props;

    const tagsQuery = useTags();

    const [tags, setTags] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]);

    useEffect(() => {
        if (tagsQuery.data) {
            setTags(tagsQuery.data);
        }
    }, [tagsQuery.data]);

    const tagReferenceCreate = useTagReferenceCreate();

    const handleCheck = (id) => {
        if (selectedTagIds.includes(id)) {
            setSelectedTagIds(selectedTagIds.filter((item) => item !== id));
        } else {
            setSelectedTagIds([...selectedTagIds, id]);
        }
    };

    const handleAdd = () => {
        tagReferenceCreate.mutate({
            reference_ids: referenceIds,
            tag_ids: selectedTagIds,
            recommended_tags: [],
            delete_old_tag: false,
            delete_old_reference: false,
        });
        exitModal();
    };

    return (
        <Container>
            <MessageLine>
                <GeneralText size={"regular"}>이 참고문헌에 연결할 태그를 선택해주세요.</GeneralText>
            </MessageLine>

            <ContentsWrapper>
                <NormalView>
                    {tags.length > 0 ? (
                        <TagListWrap>
                            {tags.map((tag, index) => (
                                <TagItem
                                    key={index}
                                    selected={selectedTagIds.includes(tag.id)}
                                    onClick={(e) => handleCheck(tag.id)}
                                >
                                    <GeneralText ellipsis size={"small"} color={"var(--color-Black)"}>
                                        {tag.name}
                                    </GeneralText>
                                </TagItem>
                            ))}
                        </TagListWrap>
                    ) : (
                        <BlankView>
                            <GeneralText size={"small"} textAlign={"center"} color={"var(--color-DisabledText)"}>
                                아직 태그가 없습니다.
                            </GeneralText>
                        </BlankView>
                    )}
                </NormalView>
            </ContentsWrapper>

            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"적용"}
                leftOnClick={props.exitModal}
                rightOnClick={handleAdd}
            />
        </Container>
    );
}

export default ReferenceTagMultiAddModal;
