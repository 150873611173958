import React from "react";
import { Helmet } from "react-helmet-async";

const HtmlHead = (props) => {
    // props로 content 내용을 불러올 예정임
    return (
        <Helmet>
            <title>{props.title}</title>

            <meta name="description" content={props.description} />
            <meta name="keywords" content={props.keywords} />
            <meta name="theme-color" content="var(--color-White)" />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={props.title} />
            <meta property="og:site_name" content={props.title} />
            <meta property="og:description" content="AI 학술연구 보조 서비스" />
            <meta property="og:image" content={props.imgsrc} />
            <meta property="og:url" content={props.url} />

            <link rel="canonical" href={props.url} />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        </Helmet>
    );
};

export default HtmlHead;
