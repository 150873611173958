import React from "react";

import styled, { css } from "styled-components";

import PageHeader from "components/templates/header/PageHeader";

import Constants from "utils/constants";
import { useDevice } from "utils/device";

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const BodyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: ${Constants.MOBILE_HEADER_HEIGHT}px 0 0 0;
    padding: 0;
    width: 100%;
    height: auto;
    min-height: calc(100vh - ${Constants.MOBILE_HEADER_HEIGHT}px);
    max-height: calc(100vh - ${Constants.MOBILE_HEADER_HEIGHT}px);
    background-color: transparent;
    overflow: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            align-items: center;
            margin: ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px 0 0 0;
            padding: 80px 0;
            max-height: unset;
            background-color: var(--color-Base1);
        `};
`;

const BoxWrapper = styled.div`
    position: relative;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: block;
            bottom: 20px;
            width: ${(props) => props.width}px;
        `};
`;

const RealBox = styled.div`
    position: inherit;
    box-sizing: border-box;
    padding: 40px 24px 120px 24px;
    width: 100%;
    max-width: 480px;
    height: auto;
    border-radius: 0px;
    border: transparent;
    background-color: var(--color-White);
    z-index: 1;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 40px;
            width: ${(props) => props.width}px;
            max-width: unset;
            border-radius: 12px;
            border: solid 1px var(--color-Outline);
        `};
`;

function GuestUserSingleCardLayout(props) {
    const device = useDevice();

    return (
        <Container>
            <PageHeader />
            <BodyContainer device={device}>
                <BoxWrapper width={props.width} device={device}>
                    <RealBox width={props.width} device={device}>
                        {props.children}
                    </RealBox>
                </BoxWrapper>
            </BodyContainer>
        </Container>
    );
}

export default GuestUserSingleCardLayout;
