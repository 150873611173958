import { apiUrl } from "utils/urls";

import axios from "axios";

export const postBibliography = async ({ params, body }) => {
    const { data } = await axios.post(apiUrl("bibliography"), body, {
        params: params,
    });
    return data;
};

export const patchBibliography = async (body) => {
    let headers = {};
    if (body.file) {
        headers = {
            "Content-Type": "multipart/form-data",
        };
    }
    const { data } = await axios.patch(apiUrl("bibliography") + "/" + body.id, body, { headers: headers });
    return data;
};

export const getBibliographyList = async (params) => {
    const { data } = await axios.get(apiUrl("bibliography"), { params: params });
    return data;
};

export const getBibliography = async (params) => {
    const { data } = await axios.get(apiUrl("bibliography") + "/uuid", {
        params: params,
    });
    return data;
};

export const deleteBibliography = async (body) => {
    const { data } = await axios.delete(apiUrl("bibliography") + "/bulk_delete", {
        data: body,
    });

    return data;
};

export const getJsonToBib = async (body) => {
    const { data } = await axios.post(apiUrl("bibliography") + "/json-to-bib", body);
    return data;
};
