import { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import ThreadSampleImage from "assets/png/others/ThreadSampleImage.png";

import AiCursor from "components/atoms/AiCursor";
import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import SentenceText from "components/atoms/text/SentenceText";

import Constants from "utils/constants";

const Container = styled.div`
    padding: 20px 16px;
    width: 100%;
    height: auto
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Line);
    transition: all 80ms ease-in;

    &:hover {
        background-color: var(--color-Base1);
    }
`;

const ItemWrap = styled.div`
    position: relative;
`;

const UserSection = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
`;

const IconBox = styled.div`
    position: relative;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    min-width: 24px;
    width: 24px;
    height: 24px;
    background-color: var(--color-Base1);
    border-radius: 12px;
`;

const UserContentsWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    height: auto;
`;

const DateWrap = styled.div`
    display: flex;
    align-items: center;
`;

const HighlightPlace = styled.div`
    display: flex;
    margin: 4px 0 8px 0px;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
`;

const HighlightLine = styled.span`
    display: block;
    margin: 0 8px 0 0;
    width: 2px;
    height: auto;
    background-color: ${({ color }) => {
        return color;
    }};
    opacity: 0.4;
    border-radius: 1px;
`;

const HighlightImage = styled.div`
    display: block;
    width: 100%;
    height: auto;

    > img {
        width: 100%;
    }
`;

const PageText = styled.span`
    margin: 0 4px 0 0;
    font-weight: 500;
`;

const BotSection = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 8px 0 0 0;
    width: 100%;
`;

const BotTextBox = styled.div`
    position: relative;
    flex: 1;
    padding: 8px;
    width: 50%; // 출처를 한 줄로 줄일때 너비 깨지는 현상 임시 수정
    height: auto;
    background-color: var(--color-InfoBox);
    border-radius: 4px;
`;

const BotText = styled.div`
    // margin: 0;
    // font-size: 12px;
    // font-weight: 400;
    // color: var(--color-SubBlack);
    // word-break: break-word;
    // text-align: justify;

    // text-overflow: ellipsis;
    // display: ${({ omission }) => (omission ? "-webkit-box;" : "block")};
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: ${({ omission }) => (omission ? "hidden;" : "none")};
`;

const BoxButtonWrap = styled.div`
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: ${({ active }) => (active ? "flex" : "none")};
`;

const BoxButton = styled.button`
    margin: 0 0 0 4px;
    padding: 2px 4px;
    background-color: var(--color-Button3);
    border-radius: 2px;
    border: transparent;
    outline: none;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover3);
    }
`;

const EnterThreadBtn = styled.button`
    display: ${(props) => props.disabled || "block"};
    margin: 4px 0 0 32px;
    font-size: 11px;
    font-weight: 400;
    color: var(--color-Grey1);
    background-color: transparent;
    border: transparent;
    outline: none;
    text-decoration: underline;
    transition: all 120ms ease-in;

    &:hover {
        color: var(--color-ButtonHover1);
    }
`;

const OptionBtnWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: ${({ active }) => (active ? "flex" : "none")};
    align-items: center;
    padding: 2px;
    height: 30px;
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Outline);
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.05);
`;

const EditorView = styled.div`
    flex: 1;
    width: 100%;
    height: auto;
    background-color: var(--color-White);
    border: solid 1px #6e97ae;
    border-radius: 4px;
    overflow: hidden;
`;

const EditorOption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    width: 100%;
    height: 32px;
    background-color: var(--color-Base1);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
`;

const EditorInput = styled.textarea`
    margin: 0;
    padding: 8px;
    width: 100%;
    min-height: 80px;
    max-height: 480px;
    border: transparent;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    outline: none;
    resize: none;
`;

const ColorPalleteWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 16px;
`;

const ColorItem = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: ${({ active }) => (active ? "solid 2px var(--color-Black)" : "solid 2px var(--color-Base1)")};
    cursor: pointer;

    &.red {
        background-color: var(--color-HighlightRed);
    }

    &.blue {
        background-color: var(--color-HighlightBlue);
    }

    &.yellow {
        background-color: var(--color-HighlightYellow);
    }

    &.green {
        background-color: var(--color-HighlightGreen);
    }

    &.purple {
        background-color: var(--color-HighlightPurple);
    }
`;

const EditorButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DivideLine = styled.div`
    display: block;
    margin: 8px 0 0 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-Line);
`;

const SourceSubText = styled.div`
    margin: 2px 0 0 0;
    font-size: 10px;
    font-weight: 400;
    color: var(--color-Grey1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        cursor: pointer;
        background-color: var(--color-Base1);
    }
`;

const SourceNumText = styled.span`
    margin: 0 4px 0 4px;
    font-weight: 400;
    color: var(--color-SubBlack);
`;

const DeletedView = styled.div`
    margin: 4px 0 0 0;
    padding: 6px;
    width: 100%;
    background-color: var(--color-InfoBox);
    border-radius: 4px;
`;

const ResponseOptionButtonStyle = {
    margin: "0 0 0 4px",
    width: "48px",
    height: "20px",
    borderRadius: "2px",
    bgColor: "var(--color-Button3)",
    hoverBgColor: "var(--color-ButtonHover3)",
    fontSize: "xsmall",
};

const EditorButtonStyle = {
    width: "32px",
    height: "20px",
    borderRadius: "2px",
    hoverBgColor: "var(--color-ButtonHover2)",
    fontSize: "xsmall",
};

function ReviewChatThreadItem(props) {
    // 인용된 내용이 텍스트일때와 이미지일때
    const imgOrText = false;

    // 스레드 호버시 나타나는 버튼
    const [showMessageOption, setShowMessageOption] = useState(false);

    // 메시지 더보기 또는 줄이기
    const botTextRef = useRef();
    const [isBotTextHover, setIsBotTextHover] = useState(false);
    const [isBotTextBusy, setIsBotTextBusy] = useState(false);
    const [isBotTextCollapsed, setIsBotTextCollapsed] = useState(false);
    const [isBotTextCollapsable, setIsBotTextCollapsable] = useState(false);

    // 메시지 수정 관련
    const editorRef = useRef();
    const [highlightColor, setHighlightColor] = useState(null);
    const [highlightOldColor, setHighlightOldColor] = useState(null);
    const [showEditor, setShowEditor] = useState(false);
    const [editorValue, setEditorValue] = useState("");
    const [editorOldValue, setEditorOldValue] = useState("");

    // 메시지 관리
    const handleDeleteMessage = () => {
        Confirm("warn", "스레드 삭제", "삭제하시겠습니까? 삭제 후에는 취소할 수 없습니다.", "삭제", (e) => {
            props.deleteChatOrMemo(props.message);
        });
    };

    // 줄이기 활성화
    useEffect(() => {
        if (botTextRef.current) {
            if (botTextRef.current.scrollHeight > 50) {
                setIsBotTextCollapsable(true);
            }
        }
    }, [botTextRef.current]);

    // 작성중지 활성화
    useEffect(() => {
        if (props.busyMessage) {
            if (props.busyMessage.id === props.message?.id) {
                setIsBotTextBusy(true);
            } else {
                setIsBotTextBusy(false);
            }
        }
    }, [props.busyMessage]);

    //  에디터 처리
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.style.height = "auto";
            editorRef.current.style.height = `${editorRef.current.scrollHeight}px`;
        }
    }, [editorRef.current]);

    const enterEditorView = () => {
        setEditorValue(props.message.req_text);
        setEditorOldValue(editorValue);
        setHighlightColor(props.message.highlight_color);
        setHighlightOldColor(highlightColor);
        setShowEditor(true);
    };

    const leaveEditorView = () => {
        setEditorValue(editorOldValue);
        setHighlightColor(highlightOldColor);
        setShowEditor(false);
    };

    const parseDate = (datetime) => {
        return datetime.split(" ")[0].replaceAll("-", ".");
    };
    const parseTime = (datetime) => {
        return datetime.split(" ")[1].substring(0, "HH:mm".length);
    };

    if (!props.message) {
        return <></>;
    }

    // 삭제된 메시지 처리
    if (props.message.is_deleted) {
        if (props.showDeletedMessage) {
            return (
                <Container>
                    <ItemWrap>
                        <UserSection>
                            <IconBox style={{ position: "relative", top: "4px" }}>
                                <Icon name={"userFill"} color={"var(--color-SubBlack)"} />
                            </IconBox>
                            <UserContentsWrap>
                                <DateWrap>
                                    <GeneralText size={"xsmall"} margin={"0 4px 0 0"} color={"var(--color-Grey1)"}>
                                        {parseDate(props.message.updated_at)}
                                    </GeneralText>
                                    <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                        {parseTime(props.message.updated_at)}
                                    </GeneralText>
                                </DateWrap>

                                <DeletedView>
                                    <GeneralText size={"small"} color={"var(--color-Grey1)"}>
                                        삭제되었습니다.
                                    </GeneralText>
                                </DeletedView>
                            </UserContentsWrap>
                        </UserSection>

                        {/* 추가 메시지 버튼으로 스레드 진입 */}
                        {props.isThread && props.thread.messages.length - 1 > 0 && (
                            <EnterThreadBtn
                                onClick={() => {
                                    props.enterThreadDetailView(props.thread);
                                }}
                            >
                                {props.thread.messages.length - 1}개의 추가 메시지
                            </EnterThreadBtn>
                        )}
                    </ItemWrap>
                </Container>
            );
        } else {
            return <></>;
        }
    }

    return (
        <Container
            onMouseEnter={(e) => {
                setShowMessageOption(true);
            }}
            onMouseLeave={(e) => {
                setShowMessageOption(false);
            }}
        >
            <ItemWrap>
                {/* 스레드 호버 옵션 버튼 */}
                <OptionBtnWrap active={showMessageOption}>
                    {/* 스레드 상세 진입 버튼은 목록에서만 보이도록 함 */}
                    {props.isThread && (
                        <Tooltip message={"추가 메시지보기"}>
                            <Button
                                onlyIcon
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={() => {
                                    props.enterThreadDetailView(props.thread);
                                }}
                            >
                                <Icon name={"thread"} size={"12"} color={"var(--color-SubBlack)"} />
                            </Button>
                        </Tooltip>
                    )}
                    {(props.message.message_type === Constants.REVIEW_MESSAGE_MEMO ||
                        !props.message.highlight_page) && (
                        <Tooltip message={"수정"}>
                            <Button
                                onlyIcon
                                bgColor={"transparent"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                onClick={enterEditorView}
                            >
                                <Icon name={"modify"} size={"12"} color={"var(--color-SubBlack)"} />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip message={"삭제"}>
                        <Button
                            onlyIcon
                            bgColor={"transparent"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={handleDeleteMessage}
                        >
                            <Icon name={"delete"} size={"12"} color={"var(--color-SubBlack)"} />
                        </Button>
                    </Tooltip>
                </OptionBtnWrap>

                {/* 유저 (질문) 영역 */}
                <UserSection>
                    <IconBox style={{ position: "relative", top: "4px" }}>
                        <Icon name={"userFill"} color={"var(--color-SubBlack)"} />
                    </IconBox>
                    <UserContentsWrap>
                        <DateWrap>
                            <GeneralText size={"xsmall"} margin={"0 4px 0 0"} color={"var(--color-Grey1)"}>
                                {parseDate(props.message.updated_at)}
                            </GeneralText>
                            <GeneralText size={"xsmall"} color={"var(--color-Grey1)"}>
                                {parseTime(props.message.updated_at)}
                            </GeneralText>
                        </DateWrap>

                        {props.message.highlight_page && (
                            <HighlightPlace
                                onClick={(e) => {
                                    props.jumpToHighlightArea(props.message.highlight_area[0]);
                                }}
                            >
                                <HighlightLine color={props.message.highlight_color} />
                                {/* 인용 내용이 이미지일때와 텍스트일때를 구분 */}
                                {imgOrText ? (
                                    <HighlightImage>
                                        <img src={ThreadSampleImage} />
                                    </HighlightImage>
                                ) : (
                                    <SentenceText
                                        multiEllipsis
                                        size={"small"}
                                        color={"var(--color-Grey1)"}
                                        webkitLineClamp={"3"}
                                    >
                                        <PageText>p.{props.message.highlight_page} </PageText>
                                        {props.message.highlight_text}
                                    </SentenceText>
                                )}
                            </HighlightPlace>
                        )}

                        {showEditor ? (
                            <EditorView>
                                <EditorInput
                                    autoFocus
                                    ref={editorRef}
                                    value={editorValue}
                                    onChange={(e) => {
                                        setEditorValue(e.target.value);
                                    }}
                                    rows={1}
                                    placeholder="여기에 질문을 입력해주세요."
                                />
                                <EditorOption>
                                    {props.message.highlight_page && props.message.highlight_page > 0 && (
                                        <ColorPalleteWrap>
                                            {["red", "blue", "yellow", "green", "purple"].map((color, index) => {
                                                return (
                                                    <ColorItem
                                                        key={index}
                                                        className={color}
                                                        onClick={() => setHighlightColor(color)}
                                                        active={highlightColor === color}
                                                    />
                                                );
                                            })}
                                        </ColorPalleteWrap>
                                    )}
                                    <EditorButtonWrap>
                                        <Button
                                            onlyText
                                            {...EditorButtonStyle}
                                            bgColor={"var(--color-Button3)"}
                                            buttonText={"취소"}
                                            onClick={leaveEditorView}
                                        />
                                        <Button
                                            onlyText
                                            {...EditorButtonStyle}
                                            margin={"0 0 0 2px"}
                                            bgColor={"var(--color-Button2)"}
                                            buttonText={"저장"}
                                            onClick={() => {
                                                if (
                                                    editorOldValue !== editorValue ||
                                                    highlightColor !== highlightOldColor
                                                ) {
                                                    const new_message = {
                                                        ...props.message,
                                                        req_text: editorValue,
                                                        highlight_color: highlightColor,
                                                    };
                                                    props.updateChatOrMemo(new_message);
                                                }
                                                leaveEditorView();
                                            }}
                                        />
                                    </EditorButtonWrap>
                                </EditorOption>
                            </EditorView>
                        ) : (
                            <GeneralText size={"small"}>{props.message.req_text}</GeneralText>
                        )}
                    </UserContentsWrap>
                </UserSection>

                {/* AI 봇 (답변) 영역 */}
                {props.message.message_type === Constants.REVIEW_MESSAGE_CHAT && (
                    <BotSection>
                        <IconBox>
                            <Icon name={"aiResponse"} size={"12px"} color={"var(--color-Key)"} />
                        </IconBox>
                        <BotTextBox
                            onMouseEnter={() => {
                                setIsBotTextHover(true);
                            }}
                            onMouseLeave={() => {
                                setIsBotTextHover(false);
                            }}
                        >
                            <BotText ref={botTextRef}>
                                <SentenceText
                                    size={"small"}
                                    multiEllipsis={isBotTextCollapsed}
                                    webkitLineClamp={isBotTextCollapsed ? 3 : "inherit"}
                                >
                                    {props.message.res_text}
                                    {isBotTextBusy && <AiCursor />}
                                </SentenceText>
                            </BotText>
                            <BoxButtonWrap active={isBotTextBusy || isBotTextHover}>
                                {isBotTextBusy && (
                                    <Button
                                        onlyText
                                        {...ResponseOptionButtonStyle}
                                        buttonText={"작성 중지"}
                                        onClick={() => {
                                            props.stopChat(props.message);
                                        }}
                                    />
                                )}
                                {!isBotTextBusy && isBotTextCollapsed && (
                                    <Button
                                        onlyText
                                        {...ResponseOptionButtonStyle}
                                        buttonText={"더보기"}
                                        onClick={() => {
                                            setIsBotTextCollapsed(false);
                                        }}
                                    />
                                )}
                                {!isBotTextBusy && !isBotTextCollapsed && isBotTextCollapsable && (
                                    <Button
                                        onlyText
                                        {...ResponseOptionButtonStyle}
                                        buttonText={"줄이기"}
                                        onClick={() => {
                                            setIsBotTextCollapsed(true);
                                        }}
                                    />
                                )}
                            </BoxButtonWrap>
                            {/* 출처 */}
                            {props.message.sources && props.message.sources.length > 0 && (
                                <>
                                    <DivideLine />
                                    {props.message.sources.map((source, index) => {
                                        return (
                                            <SourceSubText
                                                key={index}
                                                onClick={(e) => {
                                                    props.jumpToHighlightArea({
                                                        pageIndex: source.source_page - 1,
                                                        top: 0,
                                                        left: 0,
                                                        width: 100,
                                                        height: 100,
                                                    });
                                                }}
                                            >
                                                <SourceNumText>[{index + 1}]</SourceNumText>
                                                P.{source.source_page} {source.source_text}
                                            </SourceSubText>
                                        );
                                    })}
                                </>
                            )}
                        </BotTextBox>
                    </BotSection>
                )}

                {/* 추가 메시지 버튼으로 스레드 진입 */}
                {props.isThread && props.thread.messages.filter((x) => x.is_deleted === false).length - 1 > 0 && (
                    <EnterThreadBtn
                        onClick={() => {
                            props.enterThreadDetailView(props.thread);
                        }}
                    >
                        {props.thread.messages.filter((x) => x.is_deleted === false).length - 1}
                        개의 추가 메시지
                    </EnterThreadBtn>
                )}
            </ItemWrap>
        </Container>
    );
}

export default ReviewChatThreadItem;
