import React from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import Constants from "utils/constants";

const Container = styled.div`
    width: 100%;
`;

const PlanInfoWrap = styled.div`
    margin: 40px 0 0 16px;
    width: calc(100% - 16px);
`;

const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px 0;
    width: 100%;
    height: 32px;
    border-bottom: dashed 1px var(--color-Line);

    &:last-child {
        margin: 0;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const SubInfoBox = styled.div`
    margin: 40px 0 0 0;
    padding: 8px 16px;
    width: 100%;
    background-color: var(--color-InfoBox);
    border-radius: 8px;
`;

const InfoListWrap = styled.ul`
    margin: 0;
    padding: 0 0 0 16px;
`;

const SubInfoItem = styled.li`
    margin: 0 0 8px 0;
    line-height: 1.4;
    align-text: justify;
    color: var(--color-SubBlack);

    &:last-child {
        margin: 0;
    }
`;

const subTitleStyle = {
    size: "subRegular",
    fontWeight: "regular",
    color: "var(--color-Grey1)",
};

const contentStyle = {
    size: "regular",
    color: "var(--color-Black)",
};

const listStyle = {
    size: "small",
    textAlign: "justify",
};

function PlanInfoModal(props) {
    return (
        <Container>
            <TitleText withBarType size={"subRegular"}>
                요금제 정보
            </TitleText>
            <PlanInfoWrap>
                <InfoItem>
                    <TitleText {...subTitleStyle}>요금제</TitleText>
                    <GeneralText {...contentStyle}>{props.planName}</GeneralText>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>구독 요금</TitleText>
                    <Row>
                        <GeneralText {...contentStyle}>
                            {props.amount === 0 ? "무료" : `${props.amount} 원`}
                        </GeneralText>
                    </Row>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>자동 결제일</TitleText>
                    <Row>
                        {props.planId === Constants.FREE_PLAN ? (
                            <GeneralText {...contentStyle}>없음</GeneralText>
                        ) : (
                            <>
                                <GeneralText {...contentStyle} margin={"0 4px 0 0"}>
                                    매월
                                </GeneralText>
                                <GeneralText {...contentStyle}>{props.today}</GeneralText>
                                <GeneralText {...contentStyle} margin={"0 0 0 2px"}>
                                    일
                                </GeneralText>
                            </>
                        )}
                    </Row>
                </InfoItem>
            </PlanInfoWrap>
            {props.planId === Constants.FREE_PLAN ? (
                <SubInfoBox>
                    <InfoListWrap>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>
                                무료 요금제는 저장 공간이 작고, AI의 텍스트 생성 기능에 제한이 있습니다.
                            </GeneralText>
                        </SubInfoItem>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>
                                유료 구독 중 무료로 변경하면, 잔여 기간의 서비스 요금은 환불됩니다. 변경 당일도 서비스
                                이용일로 간주됩니다.
                            </GeneralText>
                        </SubInfoItem>
                    </InfoListWrap>
                </SubInfoBox>
            ) : (
                <SubInfoBox>
                    <InfoListWrap>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>등록된 결제카드로 매달 요금이 자동 결제됩니다.</GeneralText>
                        </SubInfoItem>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>
                                자동 결제일의 날짜가 없는 달에는 말일에 자동 결제가 진행됩니다.
                            </GeneralText>
                        </SubInfoItem>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>구독 중 결제 실패시 무료 요금제로 자동 변경됩니다.</GeneralText>
                        </SubInfoItem>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>
                                쿠폰은 설정&#62;결제 메뉴에서 사용하실 수 있습니다.
                            </GeneralText>
                        </SubInfoItem>
                    </InfoListWrap>
                </SubInfoBox>
            )}
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"구독"}
                leftOnClick={props.exitModal}
                rightOnClick={(e) => props.subscribeAct()}
            />
        </Container>
    );
}

export default PlanInfoModal;
