import React, { useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";

import InputWithLabel from "components/molecules/InputWithLabel";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useResearchCreate } from "hooks/queries/useResearch";

const Container = styled.div`
    width: 100%;
`;

function ResearchAddModal(props) {
    const { exitModal, lastOrder, user } = props;
    const [displayName, setDisplayName] = useState("");
    const researchMutation = useResearchCreate();

    const addResearch = () => {
        researchMutation.mutate({
            display_name: displayName,
            order: lastOrder + 1,
            ai_response_language: user.env_ai_language,
        });
        exitModal();
    };

    return (
        <Container>
            <GeneralText size={"regular"}>새로 추가할 리서치의 이름을 입력해주세요.</GeneralText>
            <InputWithLabel
                width="100%"
                margin="40px 0 0 0"
                labelText="새 리서치 이름"
                essenMarkDp="none"
                bgColor="var(--color-Base1)"
                placeholder="새 리서치 이름을 입력해주세요."
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && displayName && displayName.length > 0) {
                        addResearch();
                    }
                }}
            />
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"리서치 만들기"}
                leftOnClick={props.exitModal}
                rightOnClick={addResearch}
                disabled={displayName.trim() === ""}
            />
        </Container>
    );
}

export default ResearchAddModal;
