import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useSessionPageHistory = (maxHistory = 10) => {
    const location = useLocation();
    const historyRef = useRef([]);

    // 초기화 시 sessionStorage에서 히스토리 불러오기
    useEffect(() => {
        const savedHistory = sessionStorage.getItem("session-page-history");
        if (savedHistory) {
            historyRef.current = JSON.parse(savedHistory);
        }
    }, []);

    useEffect(() => {
        const currentPath = location.pathname;

        // 연속된 중복 경로는 제외
        if (historyRef.current[historyRef.current.length - 1] !== currentPath) {
            historyRef.current.push(currentPath);

            // 최대 히스토리 크기 제한
            if (historyRef.current.length > maxHistory) {
                historyRef.current.shift();
            }

            // sessionStorage에 저장
            sessionStorage.setItem("session-page-history", JSON.stringify(historyRef.current));
        }
    }, [location, maxHistory]);

    const getPrevPage = () => {
        const history = historyRef.current;
        return history && history.length < 2 ? null : history[history.length - 2];
    };

    const clearPageHistory = () => {
        historyRef.current = [];
        sessionStorage.removeItem("session-page-history");
    };

    return {
        prevPage: getPrevPage(),
        pageHistory: historyRef.current,
        clearPageHistory: clearPageHistory,
    };
};
