import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import RightSideBar from "components/molecules/RightSideBar";
import Table from "components/molecules/Table";
import ResearchDetailTitleInput from "components/organisms/research/ResearchDetailTitleInput";
import ResearchEditor from "components/organisms/research/ResearchEditor";
import AbstractModal from "components/page/modal/AbstractModal";
import ResearchAnalysisModal from "components/page/modal/ResearchAnalysisModal";

import { useBibliographies } from "hooks/queries/useBibliographies";
import { useSelectBoxOptions } from "hooks/queries/useOptions";
import {
    useReferenceCitationCreate,
    useReferenceCitationDeleteByUuid,
    useResearch,
    useResearchDelete,
    useResearchReferencesCreate,
    useResearchUpdate,
} from "hooks/queries/useResearch";
import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { permissionErrorHandler } from "utils/request/common";
import { apiUrl } from "utils/urls";

import axios from "axios";

const WidthLimit = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
`;

const Header = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Line);
    z-index: 1000;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 48px 0 0 0;
    width: 100%;
`;

const ContentsWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CategoryItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: ${(props) => props.direction || "column"};
    justify-content: flex-start;
    align-items: flex-start;
    margin: ${(props) => props.margin || "0 0 24px 0"};
    width: 100%;
    height: 100%;
`;

const ResearchExplainWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    width: 100%;
`;

const MainHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 16px;
    width: 100%;
    border-bottom: 1px solid var(--color-Line);
    flex-shrink: 0;
`;

const SubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    flex-shrink: 0;
`;

const SelectBoxSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const ItemWrapper = styled.div`
    flex: 1;
    overflow-y: scroll;
    padding-bottom: 80px;
`;

const HeaderLeftSection = styled.div`
    display: flex;
    align-items: center;
`;

const ReferenceSection = styled.div`
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center; /* 가운데 정렬을 위해 추가 */
    padding: 20px 0;
    background-color: #f5f5f5;
`;

const ReferenceWrapper = styled.div`
    width: 70%;
    max-width: 800px;
    background-color: var(--color-White);
    border: 1px solid var(--color-Line);
`;

const ReferenceListTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid var(--color-Line);
`;

const ReferenceList = styled.div`
    padding: 8px 16px;
`;

const ReferenceListItem = styled.div`
    padding: 8px 10px;
    border-bottom: 1px solid var(--color-Line);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }

    &:hover .reference-button-section {
        display: flex;
    }
`;

const BlankView = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const BulletWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
`;

const ReferenceButtonWrap = styled.div`
    display: none;
    width: 54px;
    height: 28px;
    background: #ffffff;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    position: absolute;
    left: 100%;
    transform: translateX(calc(-100% - 8px)) translateY(calc(-50% - 8px));

    justify-content: center;
    align-items: center;
`;

const ReferenceListButton = styled.div`
    position: fixed;
    top: 110px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 12px;
    width: 40px;
    height: 40px;
    background-color: var(--color-Button1);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
    transition: all 240ms ease-in-out;
    cursor: pointer;
    z-index: 10;

    & > .reference-list-button-text {
        opacity: 0;
        transition: all 400ms ease-in-out;
        transition-delay: 80ms;
    }

    &:hover {
        width: 124px;
        background-color: var(--color-ButtonHover1);

        & > .reference-list-button-text {
            opacity: 1;
        }
    }
`;

const RefIconWrap = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
`;

const ViewButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 4px 0 0;
    padding: 0 4px;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.activeViewMenu ? "var(--color-White)" : "var(--color-Button4)")};
    border: solid 1.5px ${(props) => (props.activeViewMenu ? "var(--color-Button2)" : "transparent")};
    border-radius: 4px;
    outline: none;
    position: relative; /* 상대적인 위치 설정 */

    &:hover {
        background-color: ${(props) => (props.activeViewMenu ? "" : "var(--color-ButtonHover4)")};
    }
`;

const ViewMenuContainer = styled.div`
    position: absolute;
    top: 36px;
    right: 0;
    width: 200px;
    background-color: var(--color-White);
    border-radius: 4px;
    border: solid 1.5px var(--color-DarkKey);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 8px 0;
`;

const SelectBoxWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
`;

function ResearchDetailPage(props) {
    const { uuid } = useParams();

    const uuidRef = useRef(null);
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const userQuery = useUser();

    const researchInitialState = {
        id: "",
        display_name: "",
        created_at: "",
        updated_at: "",
        cited_references: [],
        html_content: "",
        references: [],
    };
    const [research, setResearch] = useState();
    const [showResearchAnalysisModal, setShowResearchAnalysisModal] = useState(false);
    const researchQuery = useResearch({ uuid: uuid });
    const deleteResearchMutation = useResearchDelete();
    const updateResearchMutation = useResearchUpdate();

    const [showReferenceSidebar, setShowReferenceSidebar] = useState(false);
    const [referenceFilter, setReferenceFilter] = useState("cited");
    const [referenceOrder, setReferenceOrder] = useState("-created_at");
    const [references, setReference] = useState([]);
    const referencesQuery = useBibliographies({
        order: referenceOrder,
        citation_style: research?.citation_style,
        enabled: !!research?.citation_style, // 인용 스타일이 정해지기 전에 fetch 를 막아서 중복 호출 절감
    });

    const referenceCitationMutation = useReferenceCitationCreate();
    const referenceCitationDeleteMutation = useReferenceCitationDeleteByUuid();
    const referenceResearchCreateMutation = useResearchReferencesCreate();

    const optionsQuery = useSelectBoxOptions();
    const [styleList, setStyleList] = useState([]);

    const { setToast } = useToast();
    const editorRef = useRef();

    const { setLoading } = useLoading();

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const [editorContentChangeLoading, setEditorContentChangeLoading] = useState(false);
    const [previousEditorContent, setPreviousEditorContent] = useState("");
    const [isEditorInitialized, setIsEditorInitialized] = useState(false);
    const inputTimeoutRef = useRef(null);

    const [settingMenu, setSettingMenu] = useState(false);

    const viewButtonRef = useRef(null);
    const checkedBibsRef = useRef([]);

    // 에디터 내용의 변경사항이 있다면, beforeunload
    useBeforeUnload(
        React.useCallback(
            (e) => {
                if (previousEditorContent !== editorRef?.current?.getEditor()?.root.innerHTML) {
                    e.preventDefault();
                    e.returnValue = "";
                }
            },
            [previousEditorContent, editorRef?.current?.getEditor()?.root.innerHTML],
        ),
    );

    const editorContentChange = (type = "user") => {
        setEditorContentChangeLoading(true);
        const content = editorRef.current.getEditor().root.innerHTML;
        axios
            .patch(apiUrl("research") + `/${research.id}`, {
                id: research.id,
                html_content: content,
            })
            .then((response) => {
                setEditorContentChangeLoading(false);
                setPreviousEditorContent(content);
                if (type === "user") {
                    setToast("저장되었습니다.", "info");
                }
                setResearch((prev) => ({
                    ...prev,
                    updated_at: response.data.updated_at,
                }));
            })
            .catch((error) => {
                setEditorContentChangeLoading(false);
                setToast("저장에 실패했습니다.", "error");
            });
    };

    useEffect(() => {
        if (!isEditorInitialized && research?.id) {
            setPreviousEditorContent(editorRef?.current?.getEditor()?.root.innerHTML);
            setIsEditorInitialized(true);
            return;
        }

        if (isEditorInitialized) {
            // 변경된 내용이 있는 경우 3초 후에 저장
            if (inputTimeoutRef.current) {
                clearTimeout(inputTimeoutRef.current);
            }

            inputTimeoutRef.current = setTimeout(() => {
                if (previousEditorContent !== editorRef?.current?.getEditor()?.root.innerHTML) {
                    editorContentChange("system");
                }
            }, 3000);

            return () => clearTimeout(inputTimeoutRef.current);
        }
    }, [editorRef?.current?.getEditor()?.root.innerHTML, research?.id, isEditorInitialized]);

    useEffect(() => {
        if (!showResearchAnalysisModal) {
            if (isFetching > 0 || isMutating > 0) {
                setLoading(true);
            } else {
                setLoading(false);
            }
        }
    }, [isFetching, isMutating, showResearchAnalysisModal]);

    useEffect(() => {
        if (optionsQuery.data) {
            setStyleList(optionsQuery.data.env_citation_style);
        }
    }, [optionsQuery.data]);

    // 초기화
    useEffect(() => {
        return () => {
            setResearch(researchInitialState);
            researchQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (uuid !== uuidRef.current) {
            setResearch(researchInitialState);
            researchQuery.refetch();
            uuidRef.current = uuid;
        }
    }, [uuid]);

    useEffect(() => {
        if (researchQuery.data) {
            setResearch((prev) => ({
                ...prev,
                ...researchQuery.data,
            }));
        }

        if (researchQuery.error) {
            permissionErrorHandler(researchQuery.error, navigate);
        }

        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [researchQuery.data, researchQuery.error, userQuery.data]);

    useEffect(() => {
        if (referencesQuery.data) {
            setReference(referencesQuery.data);
        }
    }, [referencesQuery.data]);

    // 리서치 삭제
    const handleDeleteResearch = () => {
        Confirm("warn", "리서치 삭제", "리서치를 삭제하시겠습니까?", "삭제", (e) => {
            deleteResearchMutation.mutate(
                { id: research.id },
                {
                    onSuccess: (data, variables, context) => {
                        setToast("성공적으로 삭제되었습니다.", "info");
                        navigate("/reference");
                    },
                },
            );
        });
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === "s") {
                event.preventDefault();
                editorContentChange("user");
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [research?.html_content]);

    const citationButtonRef = useRef(null);

    useEffect(() => {
        const updateCitationButtonPosition = () => {
            if (editorRef.current) {
                const quillRight = editorRef.current.getEditor().root.getBoundingClientRect().right;
                citationButtonRef.current.style.left = `${quillRight + 16}px`;
            }
        };

        const checkEditorRef = () => {
            if (editorRef.current) {
                updateCitationButtonPosition();
            } else {
                setTimeout(checkEditorRef, 100); // 100ms 후에 다시 시도
            }
        };

        // 초기 위치 설정
        checkEditorRef();

        // 윈도우 리사이즈 이벤트에 대응하여 위치 업데이트
        window.addEventListener("resize", updateCitationButtonPosition);

        // 클린업 함수로 이벤트 리스너 제거
        return () => {
            window.removeEventListener("resize", updateCitationButtonPosition);
        };
    }, [editorRef.current]);

    useEffect(() => {
        // viewButton 외부 클릭 시 메뉴 닫기
        const handleClickOutside = (event) => {
            if (viewButtonRef.current && !viewButtonRef.current.contains(event.target)) {
                setSettingMenu(false);
            }
        };

        window.addEventListener("mousedown", handleClickOutside);

        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const columns = React.useMemo(
        () => [
            {
                id: "id",
                accessorKey: "id",
                name: "선택",
                size: 45,
                minSize: 45,
                maxSize: 45,
                enableResizing: false,
                content: (info) => {
                    const value = info.getValue();
                    return (
                        <Checkbox
                            id={value}
                            checked={checkedBibsRef.current.includes(value)}
                            onChange={handleCheckboxChange}
                        />
                    );
                },
            },
            {
                id: "styled_text",
                accessorKey: "styled_text",
                name: "참고문헌",
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "research_connection",
                accessorKey: "research_connection",
                name: "연결",
                size: 60,
                minSize: 60,
                maxSize: 60,
                enableResizing: false,
                content: (info) => {
                    return (
                        <Button
                            onlyText
                            height={"24px"}
                            width={"40px"}
                            bgColor={
                                research?.references?.find((item) => item.id === info.row.original.id)
                                    ? "var(--color-Button3)"
                                    : "var(--color-Button2)"
                            }
                            hoverBgColor={
                                research?.references?.find((item) => item.id === info.row.original.id)
                                    ? "var(--color-ButtonHover3)"
                                    : "var(--color-ButtonHover2)"
                            }
                            fontColor={"var(--color-White)"}
                            buttonText={
                                research?.references?.find((item) => item.id === info.row.original.id) ? "해제" : "연결"
                            }
                            onClick={() => {
                                research?.references?.find((item) => item.id === info.row.original.id)
                                    ? referenceCitationDeleteMutation.mutate({
                                          research_id: research.id,
                                          research_uuid: research.uuid,
                                          reference_id: info.row.original.id,
                                          html_content: editorRef.current.getEditor().root.innerHTML,
                                      })
                                    : referenceResearchCreateMutation.mutate({
                                          research_ids: [research.id],
                                          reference_ids: [
                                              info.row.original.id,
                                              ...research.references.map((item) => item.id),
                                          ],
                                          delete_old_reference: false,
                                      });
                            }}
                        />
                    );
                },
            },
            {
                id: "uuid",
                accessorKey: "uuid",
                name: "인용",
                size: 60,
                minSize: 60,
                maxSize: 60,
                enableResizing: false,
                content: (info) => {
                    return (
                        <Button
                            onlyText
                            height={"24px"}
                            width={"40px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            fontColor={"var(--color-White)"}
                            onClick={() => {
                                editorRef.current.addReference(info.getValue());
                            }}
                            buttonText={"인용"}
                        />
                    );
                },
            },
        ],
        [research?.id, research?.references],
    );

    const rerenderTable = React.useReducer(() => ({}), {})[1];

    // Checkbox 전체 선택
    const handleCheckboxAllChange = (event) => {
        // 전체 선택이면 모든 id를, 그렇지 않으면 빈 배열을 반환
        checkedBibsRef.current = event.target.checked
            ? referenceFilter === "all"
                ? references.map((item) => item.id)
                : research?.references.map((item) => item.id)
            : [];
        rerenderTable();
    };

    const handleCheckboxChange = (event) => {
        let { id } = event.target;
        id = Number(id);

        const index = checkedBibsRef.current.indexOf(id);
        if (index > -1) {
            checkedBibsRef.current = checkedBibsRef.current.filter((item) => item !== id);
        } else {
            checkedBibsRef.current = [...checkedBibsRef.current, id];
        }
        rerenderTable();
    };

    useEffect(() => {
        checkedBibsRef.current = [];
        rerenderTable();
    }, [referenceFilter]);

    return (
        <>
            <HtmlHead title={research?.display_name} />
            <Header>
                <WidthLimit>
                    <Tooltip message={"뒤로"}>
                        <Button
                            onlyIcon
                            bgColor={"var(--color-Button4)"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            fontColor={"var(--color-Black)"}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <Icon name="arrowLeft" size="12px" />
                        </Button>
                    </Tooltip>

                    <ResearchDetailTitleInput
                        name={"display_name"}
                        placeholder={"리서치 표시 이름을 입력해주세요."}
                        value={research?.display_name}
                        onChange={(e) => {
                            setResearch({ ...research, display_name: e.target.value });
                        }}
                        allowEmpty={false}
                        saveAct={(e) => {
                            updateResearchMutation.mutate({
                                id: research.id,
                                display_name: research?.display_name,
                            });
                        }}
                        fontsize={"16px"}
                        fontweight={"500"}
                        padding={"2px"}
                    />

                    <Tooltip message={"노트 설정"} position={"left"}>
                        <ViewButton
                            activeViewMenu={settingMenu}
                            onClick={() => setSettingMenu((prevState) => !prevState)}
                            ref={viewButtonRef}
                        >
                            <Icon
                                name={settingMenu ? "x" : "setting"}
                                color={settingMenu ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                size={settingMenu ? "12px" : "12px"}
                            />
                            {settingMenu && (
                                <ViewMenuContainer>
                                    <SelectBoxWrapper>
                                        <GeneralText size={"small"} color={"var(--color-Black)"}>
                                            AI 응답 언어
                                        </GeneralText>
                                        <SelectBox
                                            value={research?.ai_response_language}
                                            items={[
                                                { id: "ko", name: "한국어" },
                                                { id: "en", name: "English" },
                                            ]}
                                            onChange={(value) => {
                                                updateResearchMutation.mutate({
                                                    id: research.id,
                                                    ai_response_language: value,
                                                });
                                            }}
                                            width={"90px"}
                                        />
                                    </SelectBoxWrapper>
                                    <SelectBoxWrapper>
                                        <GeneralText size={"small"} color={"var(--color-Black)"}>
                                            인용 스타일
                                        </GeneralText>
                                        <SelectBox
                                            value={research?.citation_style}
                                            items={styleList}
                                            onChange={(value) => {
                                                updateResearchMutation.mutate({
                                                    id: research.id,
                                                    html_content: research?.html_content,
                                                    citation_style: value,
                                                });
                                            }}
                                            width={"90px"}
                                        />
                                    </SelectBoxWrapper>
                                </ViewMenuContainer>
                            )}
                        </ViewButton>
                    </Tooltip>

                    <Tooltip message={"삭제"}>
                        <Button
                            onlyIcon
                            bgColor={"var(--color-Button4)"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            fontColor={"var(--color-Black)"}
                            onClick={handleDeleteResearch}
                        >
                            <Icon name="delete" size="12px" />
                        </Button>
                    </Tooltip>
                </WidthLimit>
            </Header>

            <Body>
                <ContentsWrapper>
                    <CategoryItem direction="column" margin="0">
                        <ResearchExplainWrap>
                            {research?.uuid && (
                                <ResearchEditor
                                    value={research?.html_content}
                                    onChange={(e) => {
                                        if (e === research.html_content) return;
                                        setResearch((prev) => ({ ...prev, html_content: e }));
                                    }}
                                    setShowReferenceSidebar={setShowReferenceSidebar}
                                    research={research}
                                    updateResearchMutation={updateResearchMutation}
                                    referenceCitationMutation={referenceCitationMutation}
                                    updateResearchEditor={editorContentChange}
                                    editorContentChangeLoading={editorContentChangeLoading}
                                    ref={editorRef}
                                />
                            )}
                        </ResearchExplainWrap>
                    </CategoryItem>
                </ContentsWrapper>
                <ReferenceSection>
                    <ReferenceWrapper>
                        <ReferenceListTitle>
                            <TitleText size={"regular"} color={"var(--color-Black)"}>
                                참고문헌
                            </TitleText>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Tooltip message={"복사"}>
                                    <Button
                                        onlyIcon
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        margin={"0 8px"}
                                        onClick={() => {
                                            const styledTexts = research?.cited_references
                                                .map((reference) => reference.styled_text)
                                                .join("\n");

                                            navigator.clipboard
                                                .writeText(styledTexts)
                                                .then(() => {
                                                    setToast("복사되었습니다", "info");
                                                })
                                                .catch((error) => {
                                                    console.error("복사 실패:", error);
                                                });
                                        }}
                                    >
                                        <Icon name={"copy"} size={"12"} color={"var(--color-White)"} />
                                    </Button>
                                </Tooltip>
                                <SelectBox
                                    value={research?.citation_style}
                                    items={styleList}
                                    onChange={(value) => {
                                        updateResearchMutation.mutate({
                                            id: research.id,
                                            html_content: research?.html_content,
                                            citation_style: value,
                                        });
                                    }}
                                    width={"120px"}
                                />
                            </div>
                        </ReferenceListTitle>
                        <ReferenceList>
                            {research?.cited_references?.length !== 0 ? (
                                research?.cited_references?.map((reference, index) => (
                                    <ReferenceListItem key={index}>
                                        <GeneralText
                                            size={"small"}
                                            style={{ cursor: "default" }}
                                            color={"var(--color-Black)"}
                                        >
                                            {reference.styled_text}
                                        </GeneralText>
                                        <ReferenceButtonWrap className={"reference-button-section"}>
                                            <Tooltip message={"인용해제"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"transparent"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={() => {
                                                        referenceCitationDeleteMutation.mutate({
                                                            research_id: research.id,
                                                            reference_id: reference.id,
                                                            research_uuid: research.uuid,
                                                            html_content: editorRef.current.getEditor().root.innerHTML,
                                                        });
                                                    }}
                                                >
                                                    <Icon
                                                        name={"quoteRemove"}
                                                        color={"var(--color-Black)"}
                                                        size={"12px"}
                                                    />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip message={"상세보기"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"transparent"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={() => {
                                                        window.open(`/reference/${reference.uuid}`, "_blank");
                                                    }}
                                                >
                                                    <Icon
                                                        name={"newTabOpen"}
                                                        size={"12px"}
                                                        color={"var(--color-Black)"}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </ReferenceButtonWrap>
                                    </ReferenceListItem>
                                ))
                            ) : (
                                <BlankView>
                                    <BulletWrap>
                                        <Icon name={"bullet"} color={"var(--color-Grey2)"} />
                                    </BulletWrap>
                                    <GeneralText size={"large"} color={"var(--color-Grey2)"}>
                                        인용된 참고문헌이 없습니다.
                                    </GeneralText>
                                </BlankView>
                            )}
                        </ReferenceList>
                    </ReferenceWrapper>
                </ReferenceSection>
            </Body>
            <RightSideBar
                right={showReferenceSidebar ? "0" : "-560px"}
                onClose={() => setShowReferenceSidebar(false)}
                top={"48px"}
            >
                <MainHeader>
                    <HeaderLeftSection>
                        <Button
                            onlyIcon
                            bgColor={"transparent"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={() => setShowReferenceSidebar(false)}
                        >
                            <Icon name={"x"} />
                        </Button>
                        <GeneralText size={"large"} color={"var(--color-Black)"} margin={"0 0 0 8px"}>
                            참고문헌 관리
                        </GeneralText>
                    </HeaderLeftSection>
                </MainHeader>
                <SubHeader>
                    <HeaderLeftSection>
                        <Checkbox
                            id={"all"}
                            indeterminate={
                                checkedBibsRef.current.length > 0 &&
                                (referenceFilter === "all"
                                    ? checkedBibsRef.current.length < references?.length
                                    : checkedBibsRef.current.length < research?.references.length)
                            }
                            checked={
                                referenceFilter === "all"
                                    ? checkedBibsRef.current.length === references?.length
                                    : checkedBibsRef.current.length === research?.references.length
                            }
                            onChange={handleCheckboxAllChange}
                            margin={"0 8px 0 0"}
                        />
                        <Button
                            onlyText
                            height={"24px"}
                            width={"60px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"자동분석"}
                            disabled={checkedBibsRef.current.length === 0}
                            onClick={() => {
                                if (user.plan_id === Constants.FREE_PLAN) {
                                    Alert(
                                        "info",
                                        "유료 기능 알림",
                                        "유료 기능입니다.\n유료 요금제를 구독하시면 사용하실 수 있습니다.",
                                    );
                                    return;
                                }
                                setShowResearchAnalysisModal(true);
                            }}
                        />
                    </HeaderLeftSection>
                    <SelectBoxSection>
                        <SelectBox
                            width="calc(100% - 28px)"
                            maxWidth="120px"
                            optionWidth="100%"
                            optionMinWidth="120px"
                            topBottom="top"
                            reverse="180deg"
                            margin={"0 4px 0 0"}
                            value={referenceFilter}
                            items={[
                                { id: "all", name: "모든 참고문헌" },
                                { id: "cited", name: "연결된 참고문헌" },
                            ]}
                            onChange={(value) => setReferenceFilter(value)}
                        />
                        <SelectBox
                            width="120px"
                            maxWidth="120px"
                            optionWidth="100%"
                            optionMinWidth="120px"
                            topBottom="top"
                            reverse="180deg"
                            value={referenceOrder}
                            items={[
                                { id: "-created_at", name: "최근 추가순" },
                                { id: "-updated_at", name: "최근 활동순" },
                                { id: "display_name", name: "제목 순" },
                            ]}
                            onChange={(value) => setReferenceOrder(value)}
                        />
                    </SelectBoxSection>
                </SubHeader>
                <ItemWrapper>
                    {references.length !== 0 ? (
                        referenceFilter === "all" ? (
                            <Table
                                tableKey={"researchReference"}
                                data={references}
                                columns={columns}
                                useSizingColumns={false}
                                useSortingColumns={false}
                                onRowClick={(row) => {
                                    window.open(`/reference/${row.original.uuid}`, "_blank");
                                }}
                            />
                        ) : (
                            <Table
                                tableKey={"researchReference"}
                                data={research?.references}
                                columns={columns}
                                useSizingColumns={false}
                                useSortingColumns={false}
                                onRowClick={(row) => {
                                    window.open(`/reference/${row.original.uuid}`, "_blank");
                                }}
                            />
                        )
                    ) : (
                        <BlankView>
                            <BulletWrap>
                                <Icon name={"bullet"} color={"var(--color-Grey2)"} />
                            </BulletWrap>
                            <GeneralText size={"large"} color={"var(--color-Grey2)"}>
                                연결된 참고문헌이 없습니다.
                            </GeneralText>
                        </BlankView>
                    )}
                </ItemWrapper>
            </RightSideBar>
            {showResearchAnalysisModal && (
                <AbstractModal
                    modalTitle="참고문헌 자동분석"
                    width={800}
                    height={820}
                    exitModal={(e) => setShowResearchAnalysisModal(!showResearchAnalysisModal)}
                >
                    <ResearchAnalysisModal
                        research={research}
                        user={user}
                        references={references.filter((reference) => checkedBibsRef.current.includes(reference.id))}
                        exitModal={(e) => setShowResearchAnalysisModal(!showResearchAnalysisModal)}
                        editorRef={editorRef}
                        referenceCitationMutation={referenceCitationMutation}
                        updateResearchMutation={updateResearchMutation}
                    />
                </AbstractModal>
            )}
            <ReferenceListButton onClick={() => setShowReferenceSidebar(true)} ref={citationButtonRef}>
                <GeneralText
                    ellipsis
                    margin={"0 20px 0 0"}
                    size={"small"}
                    color={"var(--color-White)"}
                    className={"reference-list-button-text"}
                >
                    참고문헌 목록
                </GeneralText>
                <RefIconWrap>
                    <Icon name={"quote"} size={"16px"} color={"var(--color-White)"} />
                </RefIconWrap>
            </ReferenceListButton>
        </>
    );
}

export default ResearchDetailPage;
