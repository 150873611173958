import React from "react";

import styled from "styled-components";

const Container = styled.label`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
`;

const ToggleBtn = styled.input`
    position: relative;
    bottom: 1px;

    &[type="checkbox"] {
        appearance: none;
        display: block;
        position: relative;
        border: 2px solid #cddae0;
        border-radius: 1.25em;
        width: 42px;
        height: 24px;
        cursor: pointer;
    }

    &[type="checkbox"]::before {
        content: "";
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        transform: scale(0.8);
        background-color: var(--color-DisabledButton);
        transition: left 100ms ease-in;
    }

    &[type="checkbox"]:checked::before {
        background-color: var(--color-Key);
        left: 18px;
    }

    &[type="checkbox"]:checked {
        background-color: var(--color-White);
        border-color: var(--color-Key);
    }

    &[type="checkbox"]:disabled {
        border-color: lightgray;
        opacity: 0.7;
        cursor: not-allowed;
    }

    &[type="checkbox"]:disabled:before {
        background-color: lightgray;
    }

    &[type="checkbox"]:disabled + span {
        opacity: 0.7;
        cursor: not-allowed;
    }

    [type="checkbox"]:focus-visible {
        outline-offset: max(2px, 0.1em);
        outline: max(2px, 0.1em) solid tomato;
    }

    [type="checkbox"]:enabled:hover {
        border-color: var(--color-Key);
    }
`;

function ToggleButton(props) {
    return (
        <Container>
            <ToggleBtn type="checkbox" checked={props.checked} onChange={props.onChange} />
        </Container>
    );
}

export default ToggleButton;
