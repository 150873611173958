import React from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import GeneralText from "components/atoms/text/GeneralText";
import useToast from "components/atoms/toast/useToast";

const Container = styled.div`
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translate(-50%);
    width: auto;
    height: 48px;
    background-color: Transparent;
    z-index: 10000;
`;

const ToastBox = styled.div`
    display: flex;
    align-items: center;
    padding: 6px;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
`;

const WhiteBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;
    width: 24px;
    height: 24px;
    background-color: var(--color-White);
    border-radius: 4px;
`;

function Toast(props) {
    const { text, display, type } = useToast();
    const renderContents = () => {
        if (type === "info") {
            return (
                <ToastBox backgroundColor={"var(--color-Key)"}>
                    <WhiteBox>
                        <Icon name={"check"} size={"16"} color={"var(--color-Key)"} />
                    </WhiteBox>
                    <GeneralText size={"large"} color={"var(--color-White)"}>
                        {text}
                    </GeneralText>
                </ToastBox>
            );
        } else if (type === "warn") {
            return (
                <ToastBox backgroundColor={"var(--color-Warn)"}>
                    <WhiteBox>
                        <Icon name={"warn"} size={"16"} color={"var(--color-Warn)"} />
                    </WhiteBox>
                    <GeneralText size={"large"} color={"var(--color-White)"}>
                        {text}
                    </GeneralText>
                </ToastBox>
            );
        }
    };

    return <>{display && <Container className="toastAnimation">{renderContents()}</Container>}</>;
}

export default Toast;
