import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import useToast from "components/atoms/toast/useToast";

import Constants from "utils/constants";
import { getJsonToBib } from "utils/request/bibliography";

import saveAs from "file-saver";

const BibTeXContainer = styled.div`
    position: relative;
    padding: 6px;
    width: 100%;
    height: calc(100% - 80px);
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);
    background-color: var(--color-CodeBack);
    border: solid 2px var(--color-Black);
    border-radius: 4px;
    overflow: inherit;

    .buttonWrap {
        button {
            visibility: hidden;
            opacity: 0;
        }
    }

    &:hover {
        .buttonWrap {
            right: 16px;

            button {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    ${Constants.BIG_HEIGHT_MQ} {
        min-height: calc(100vh - 560px);
        max-height: calc(100vh - 560px);
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    height: calc(100vh - 416px);
    min-height: 100%;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-White);
    background-color: transparent;
    border: transparent;
    outline: none;
    resize: none;

    &::placeholder {
        color: var(--color-DisabledText);
    }

    &::-webkit-scrollbar {
        width: 6px;
        display: initial;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-Black);
    }
`;

const ButtonColumn = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 52px;
    transition: all 300ms ease-in-out;
`;

function ReferenceBibInfoViewModal(props) {
    const { setToast } = useToast();
    const { setLoading } = useLoading();

    const textareaRef = useRef(null);

    const [bibtex, setBibtex] = useState("");

    useEffect(() => {
        setLoading(true);
        getJsonToBib(props.bib_json)
            .then((res) => {
                if (res.bib) {
                    setLoading(false);
                    setBibtex(res.bib);
                } else {
                    Alert("warn", "에러", "BibTeX 변환에 실패했습니다.");
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [props.bib_json]);

    const copyToClipboard = () => {
        if (textareaRef.current) {
            const bib = textareaRef.current.value;

            navigator.clipboard
                .writeText(bib)
                .then(() => {
                    setToast("복사되었습니다", "info");
                })
                .catch((error) => {
                    console.error("복사 실패:", error);
                });
        }
    };

    return (
        <BibTeXContainer>
            <Textarea autoFocus ref={textareaRef} value={bibtex} readOnly />
            <ButtonColumn className="buttonWrap">
                <Tooltip message={"복사"}>
                    <Button
                        onlyIcon
                        bgColor={"rgba(30, 30, 30, 0.8)"}
                        hoverBgColor={"var(--color-Key)"}
                        onClick={copyToClipboard}
                    >
                        <Icon name={"copy"} size={"12"} color={"var(--color-White)"} />
                    </Button>
                </Tooltip>
                <Tooltip message={"다운로드"}>
                    <Button
                        onlyIcon
                        bgColor={"rgba(30, 30, 30, 0.8)"}
                        hoverBgColor={"var(--color-Key)"}
                        onClick={(e) => saveAs(new Blob([bibtex]), `paper-${new Date().toISOString()}.bib`)}
                    >
                        <Icon name={"download"} size={"12"} color={"var(--color-White)"} />
                    </Button>
                </Tooltip>
            </ButtonColumn>
        </BibTeXContainer>
    );
}

export default ReferenceBibInfoViewModal;
