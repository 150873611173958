import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating, useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import Table from "components/molecules/Table";
import ReferenceAddMenu from "components/organisms/reference/ReferenceAddMenu";
import ReferenceResearchList from "components/organisms/reference/ReferenceResearchList";
import ReferenceTagList from "components/organisms/reference/ReferenceTagList";
import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceAddArxivModal from "components/page/modal/ReferenceAddArxivModal";
import ReferenceAddBibtexModal from "components/page/modal/ReferenceAddBibtexModal";
import ReferenceAddDoiModal from "components/page/modal/ReferenceAddDoiModal";
import ReferenceAddPubmedModal from "components/page/modal/ReferenceAddPubmedModal";
import ReferenceCitationMultiAddModal from "components/page/modal/ReferenceCitationMultiAddModal";
import ReferenceFileUploadModal from "components/page/modal/ReferenceFileUploadModal";
import ReferenceTagMultiAddModal from "components/page/modal/ReferenceTagMultiAddModal";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useBibliographies, useBibliographyDelete, useBibliographyUpdate } from "hooks/queries/useBibliographies";
import { useSessionPageHistory } from "hooks/useSessionPageHistory";

import { useDevice } from "utils/device";
import { postBibliography } from "utils/request/bibliography";
import { permissionErrorHandler } from "utils/request/common";

import moment from "moment/moment";

const Header = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    height: 48px;
    max-height: 48px;
    background-color: var(--color-White);
`;

const HeaderLeftWrap = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderRightWrap = styled.div`
    position: relative;
    margin: 0 0 0 40px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 48px 0 0 0;
    padding: 0 48px;
    width: 100%;
    height: calc(100vh - 104px);
`;

const BodyHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
`;

const BodyHeaderLeftSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    width: 171px;
`;

const BodyHeaderRightSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 24px;
`;

const SearchWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px 0 0;
    width: 100%;
    min-width: 200px;
    max-width: 320px;
`;

const SearchInputBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 4px;
    width: 100%;
    height: 28px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Line);
    border-radius: 4px;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const SearchInput = styled.input`
    padding: 0 8px;
    width: calc(100% - 24px);
    height: 24px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
    background-color: transparent;
    border: transparent;
    outline: var(--color-Key);
`;

const SearchButtonWrap = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
`;

const BookmarkWrap = styled.div`
    display: flex;
    align-items: center;
    min-width: 113px;
`;

const ViewButtonWrap = styled.div`
    display: flex;
    align-items: center;
`;

const OptionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.optionBoxActive ? "var(--color-White)" : "var(--color-Button4)")};
    border: solid 1.5px ${(props) => (props.optionBoxActive ? "var(--color-Button2)" : "transparent")};
    border-radius: 4px;
    outline: none;

    &:hover {
        background-color: ${(props) => (props.optionBoxActive ? "" : "var(--color-ButtonHover4)")};
    }
`;

const DivideLine = styled.span`
    display: block;
    margin: 0 12px;
    width: 1px;
    height: 16px;
    background-color: var(--color-Outline);
`;

const OptionBoxContainer = styled.div`
    position: absolute;
    top: 28px;
    right: 0;
    display: ${(props) => (props.visible ? "block" : "none")};
    padding: 8px;
    width: ${(props) => props.width};
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 3;
`;

const CheckOptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 200px;
`;

const CheckItemWrap = styled.div``;

const CheckRadioItemLabel = styled.label`
    display: flex;
    align-items: center;
    height: 24px;
`;

const BodyContent = styled.div`
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;

    ${(props) =>
        props.isFileDragging &&
        css`
            border: dashed 2px var(--color-Key);
            background-color: var(--color-Base1);
        `};
`;

const BodyContentUploadView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const UploadDropBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: dashed 2px var(--color-Key);
    background-color: var(--color-Base1);
`;

const BodyContentBlankView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FileUploadSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AddInfoSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;

const AddInfoBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-InfoBox);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 24px;
        `};
`;

const AddInfoItem = styled.div`
    width: 50%;
    max-width: calc(50% - 8px);
`;

const AddInfoItemRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 8px 6px;
    width: 100%;

    &:last-child {
        margin: 0 0 0 6px;
    }
`;

const AddInfoSubItemWrap = styled.div`
    margin: 0 0 8px 32px;
`;

const AddInfoSubItem = styled.div`
    display: flex;
    align-items: center;
`;

const AddFileBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    border-radius: 8px;
    border: dashed 2px var(--color-Outline);
    transition: all 150ms ease-in;

    ${(props) =>
        props.isFileDragging &&
        css`
            border: dashed 2px var(--color-Key);
            background-color: var(--color-Base1);
        `};
`;

const AddBoxIcon = styled.div`
    margin: 0 0 24px 0;
    width: 100px;
    height: 100px;
`;

const CheckIconStyle = {
    name: "check",
    size: "14",
    color: "var(--color-DarkKey)",
};

const ItemTextStyle = {
    margin: "0 0 0 8px",
    size: "large",
    color: "var(--color-SubBlack)",
};

const SubItemTextStyle = {
    margin: "0 0 4px 4px",
    size: "small",
    color: "var(--color-SubBlack)",
};

const defaultSearchParams = {
    filter: "",
    page: 1,
    page_size: 10,
    ordering: "",
};

function ReferenceListPage(props) {
    const device = useDevice();
    const navigate = useNavigate();
    const location = useLocation();
    const tableRef = useRef();

    const [referenceList, setReferenceList] = useState([]);
    const checkedBibsRef = useRef([]);

    const [isOpen, setIsOpen] = useState(false);
    const buttonWrapRef = useRef();
    const OptionBoxRef = useRef();

    const [searchFilter, setSearchFilter] = useState("");
    const [searchParams, setSearchParams] = useState({ ...defaultSearchParams });
    const bibliographiesQuery = useBibliographies(searchParams);

    useEffect(() => {
        // 좌측 사이드 메뉴에서 태그 선택해서 검색하는 경우 처리
        if (location.state) {
            setSearchParams({
                ...defaultSearchParams,
                ...location.state,
            });
            setSearchFilter(location.state.filter || defaultSearchParams.filter);
        }
    }, [location.state]);

    useEffect(() => {
        // 상세 페이지에서 돌아왔을 때 검색창에 텍스트 자동 입력 처리
        setSearchFilter(searchParams.filter);
    }, [searchParams.filter]);

    const PAGE_KEY = "reference-list";
    const { prevPage } = useSessionPageHistory();

    const saveSessionCache = () => {
        sessionStorage.setItem(
            PAGE_KEY,
            JSON.stringify({
                searchParams: searchParams,
            }),
        );
    };

    const loadSessionCache = () => {
        const sessionCacheJson = sessionStorage.getItem(PAGE_KEY);
        if (sessionCacheJson) {
            const sessionCacheObj = JSON.parse(sessionCacheJson);
            setSearchParams(sessionCacheObj.searchParams);
        }
    };

    const clearSessionCache = () => {
        sessionStorage.removeItem(PAGE_KEY);
    };

    useEffect(() => {
        // 참고문헌상세 페이지에서 이동해왔을 때 페이지 복원
        if (prevPage && prevPage.startsWith("/reference/")) {
            loadSessionCache();
            clearSessionCache(); // 새로고침 했을 때 기본값으로 처리되도록 삭제
        }
    }, [prevPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonWrapRef.current && !buttonWrapRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [modalState, setModalState] = useState({
        arxivModal: false,
        doiModal: false,
        pubmedModal: false,
        bibtexModal: false,
        researchMultiAddModal: false,
        tagMultiAddModal: false,
    });
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);

    const { setToast } = useToast();

    const deleteBibliographyMutation = useBibliographyDelete();

    const { setLoading } = useLoading();
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const tableKey = "reference";

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    {
        /* 초기화 */
    }
    useEffect(() => {
        return () => {
            bibliographiesQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (bibliographiesQuery.data) {
            setReferenceList(bibliographiesQuery.data);
        }
    }, [bibliographiesQuery.data]);

    useEffect(() => {
        if (bibliographiesQuery.error) {
            permissionErrorHandler(bibliographiesQuery.error, navigate);
        }
    }, [bibliographiesQuery.error]);

    useEffect(() => {
        if (deleteBibliographyMutation.isSuccess) {
            setToast("참고문헌이 삭제되었습니다.", "info");
        }
    }, [deleteBibliographyMutation.isSuccess]);

    // Checkbox 전체 선택
    const handleCheckboxAllChange = (event) => {
        // 전체 선택이면 모든 id를, 그렇지 않으면 빈 배열을 반환
        checkedBibsRef.current = event.target.checked ? referenceList?.results.map((item) => item.id) : [];
        rerenderTable();
    };

    const handleOpenAddButton = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleCheckboxChange = (event) => {
        let { id } = event.target;
        id = Number(id);

        const index = checkedBibsRef.current.indexOf(id);
        if (index > -1) {
            checkedBibsRef.current = checkedBibsRef.current.filter((item) => item !== id);
        } else {
            checkedBibsRef.current = [...checkedBibsRef.current, id];
        }
        rerenderTable();
    };

    // delete Function
    const deleteBibliography = () => {
        if (checkedBibsRef.current.length > 0) {
            let citation_exists = false;
            let message = "선택한 참고문헌을 삭제하시겠습니까?";
            referenceList?.results
                .filter((item) => checkedBibsRef.current.includes(item.id))
                .map((item) => {
                    if (item.citation_count > 0) {
                        citation_exists = true;
                    }
                });
            if (citation_exists) {
                message = "리서치에 인용된 참고문헌입니다. 삭제하시겠습니까?";
            }
            Confirm("warn", "참고문헌 삭제", message, "삭제", (e) => {
                handleDeleteConfirm();
            });
        } else {
            Alert("warn", "에러", "삭제할 참고문헌을 선택해주세요.");
        }
    };

    const handleDeleteConfirm = () => {
        deleteBibliographyMutation.mutate({ ids: checkedBibsRef.current });
        checkedBibsRef.current = [];
    };

    // 참고 문헌 추가 mutation
    const bibliographyMutation = useMutation(postBibliography, {
        onSuccess: (data, variables, context) => {
            if (variables.body.file_url) {
                // arxiv 원문 파일 포함하여 추가한 경우
                navigate("/reference/" + data.uuid, {
                    state: { file: variables.body.file_url },
                });
            } else {
                // 파일 없이 추가한 경우 (직접 추가, BibTeX 추가, 외부 DB의 서지정보만 가져와서 추가 등)
                navigate("/reference/" + data.uuid);
            }
        },
        onError: (error, variables, context) => {
            if (error.response.status === 401) {
                Alert("warn", "에러", "로그인이 필요합니다.");
                navigate("/login");
            } else if (error.response.status === 400) {
                if (error.response.data.bib_json) {
                    if (error.response.data.bib_json.citation_key) {
                        Alert("warn", "에러", error.response.data.bib_json.citation_key);
                    } else {
                        Alert("warn", "에러", error.response.data.bib_json);
                    }
                } else {
                    Alert("warn", "에러", "참고문헌 추가에 실패했습니다.");
                }
            } else {
                Alert("warn", "에러", "서버에 오류가 발생했습니다.");
            }
        },
    });

    // drag & drop 파일 업로드
    const [isFileDragging, setIsFileDragging] = useState(false);
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(true);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(true);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(false);
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsFileDragging(false);

        if (e.dataTransfer.files) {
            setUploadingFiles(Array.from(e.dataTransfer.files));
            setShowFileUploadModal(true);
        }
    };

    const [uploadingFiles, setUploadingFiles] = useState([]);
    const fileAddHandler = (e) => {
        setUploadingFiles(Array.from(e.target.files));
        setShowFileUploadModal(true);
    };

    const columns = React.useMemo(
        () => [
            {
                id: "id",
                accessorKey: "id",
                name: "선택",
                header: null,
                size: 45,
                minSize: 45,
                maxSize: 45,
                enableResizing: false,
                content: (info) => {
                    const value = info.getValue();
                    return (
                        <Checkbox
                            id={value}
                            checked={checkedBibsRef.current.includes(value)}
                            onChange={handleCheckboxChange}
                        />
                    );
                },
            },
            {
                id: "is_bookmarked",
                accessorKey: "is_bookmarked",
                name: "즐겨찾기",
                size: 45,
                minSize: 45,
                maxSize: 45,
                enableResizing: false,
                header: () => "",
                content: ({ row }) => {
                    return (
                        <Button
                            onlyIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                handleBookmarkClick(row.original);
                            }}
                            bgColor={"transparent"}
                            hoverBgColor={"transparent"}
                        >
                            {row.original.is_bookmarked ? (
                                <Icon name={"bookmark"} color={"var(--color-Key)"} size={"16px"} />
                            ) : (
                                <Icon name={"bookmarkDisactive"} size={"16px"} />
                            )}
                        </Button>
                    );
                },
            },
            {
                id: "bib_json.citation_key",
                accessorKey: "bib_json.citation_key",
                name: "인용키",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "display_name",
                accessorKey: "display_name",
                name: "표시이름",
                size: 250,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "bib_json.title",
                accessorKey: "bib_json.title",
                name: "제목",
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "bib_json.journal",
                accessorKey: "bib_json.journal",
                name: "발행처",
                size: 150,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "bib_json.year",
                accessorKey: "bib_json.year",
                name: "발행연도",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "bib_json.author",
                accessorKey: "bib_json.author",
                name: "저자",
                size: 150,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "bib_json.abstract",
                accessorKey: "bib_json.abstract",
                name: "초록",
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "researches",
                accessorKey: "researches",
                name: "리서치",
                size: 150,
                content: (info) => {
                    const researches = info.getValue();
                    if (!researches || researches.length === 0) {
                        return null;
                    }
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ReferenceResearchList showHeader={false} bibliography={info.row.original} />
                        </div>
                    );
                },
            },
            {
                id: "tag_mappings",
                accessorKey: "tag_mappings",
                name: "태그",
                size: 150,
                content: (info) => {
                    const tags = info.getValue();
                    if (!tags || tags.length === 0) {
                        return null;
                    }
                    return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ReferenceTagList showHeader={false} bibliography={info.row.original} />
                        </div>
                    );
                },
            },
            {
                id: "memo",
                accessorKey: "memo",
                name: "메모",
                size: 150,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "created_at",
                accessorKey: "created_at",
                name: "추가날짜",
                size: 100,
                text: (info) => {
                    return moment(info.getValue()).format("YYYY-MM-DD");
                },
            },
            {
                id: "updated_at",
                accessorKey: "updated_at",
                name: "최근활동",
                size: 100,
                text: (info) => {
                    return moment(info.getValue()).format("YYYY-MM-DD");
                },
            },
            {
                id: "file",
                accessorKey: "file",
                name: "PDF",
                size: 50,
                content: (info) => {
                    return (
                        <Tooltip position={"left"} message={"PDF보기"}>
                            <Button
                                onlyIcon
                                bgColor={"transparent"}
                                hoverBgColor={"transparent"}
                                onClick={(e) => {
                                    if (info.getValue()) {
                                        window.open(
                                            "/reference/" +
                                                info.row.original.uuid +
                                                "/review/" +
                                                info.row.original.file_uuid,
                                        );
                                    }
                                    e.stopPropagation();
                                }}
                            >
                                <Icon
                                    name={"pdf"}
                                    color={info.getValue() ? "var(--color-Key)" : "#C8D0CE"}
                                    size={"18px"}
                                />
                            </Button>
                        </Tooltip>
                    );
                },
            },
        ],
        [],
    );

    const rerenderTable = React.useReducer(() => ({}), {})[1];

    // 옵션박스 토글
    const [optionBoxActive, setOptionBoxActive] = useState(false);

    // 북마크
    const updateMutation = useBibliographyUpdate();

    const handleBookmarkClick = (bibliography) => {
        updateMutation.mutate({
            id: bibliography.id,
            is_bookmarked: !bibliography.is_bookmarked,
        });
    };

    useEffect(() => {
        if (optionBoxActive) {
            const handleClickOutside = (event) => {
                if (OptionBoxRef.current && !OptionBoxRef.current.contains(event.target)) {
                    setOptionBoxActive(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [optionBoxActive]);

    const [columnVisibility, setColumnVisibility] = useState(() => {
        const tableConfig = JSON.parse(localStorage.getItem("table-config")) || {}; // 기존 table-config 불러오기
        const savedVisibility = {};
        if (tableConfig[tableKey]) {
            Object.keys(tableConfig[tableKey]).forEach((columnId) => {
                savedVisibility[columnId] = tableConfig[tableKey][columnId].visibility;
            });
        } else {
            savedVisibility["bib_json.title"] = false;
            savedVisibility["bib_json.abstract"] = false;
            savedVisibility["updated_at"] = false;
            savedVisibility["memo"] = false;
        }
        return savedVisibility;
    });

    useEffect(() => {
        if (Object.keys(columnVisibility).length > 0) {
            const tableConfig = JSON.parse(localStorage.getItem("table-config")) || {};
            const updatedConfig = { ...tableConfig };

            updatedConfig[tableKey] = updatedConfig[tableKey] || {};

            Object.keys(columnVisibility).forEach((columnId) => {
                updatedConfig[tableKey][columnId] = {
                    ...updatedConfig[tableKey][columnId],
                    visibility: columnVisibility[columnId],
                };
            });

            localStorage.setItem("table-config", JSON.stringify(updatedConfig)); // 로컬 스토리지에 저장
        }
    }, [columnVisibility, tableKey]);

    const handleSortChange = (sort) => {
        const sortValue = sort
            .map((sortItem) => {
                return sortItem.desc ? `-${sortItem.id}` : sortItem.id;
            })
            .join(",");
        setSearchParams({ ...searchParams, ordering: sortValue, page: 1 });
    };

    const handlePageChange = (newPage) => {
        setSearchParams({ ...searchParams, page: newPage });
    };

    const handlePageSizeChange = (newSize) => {
        setSearchParams({ ...searchParams, page_size: newSize, page: 1 });
    };

    return (
        <LoginUserLayout>
            <HtmlHead title={"참고문헌 관리"} />

            <Header ref={tableRef}>
                <HeaderLeftWrap>
                    <TitleText size={"small"} margin={"0 16px 0 0"}>
                        참고문헌 관리
                    </TitleText>
                    <GeneralText size={"small"} margin={"0 2px 0 0"}>
                        전체
                    </GeneralText>
                    <GeneralText size={"small"}>{referenceList.count}건</GeneralText>
                </HeaderLeftWrap>
                <HeaderRightWrap ref={buttonWrapRef}>
                    <Button
                        onClick={handleOpenAddButton}
                        width={"132px"}
                        height={"32px"}
                        bgColor={isOpen ? "var(--color-White)" : "var(--color-Button2)"}
                        hoverBgColor={isOpen ? "transparent" : "var(--color-ButtonHover2)"}
                        border={isOpen ? "solid 2px var(--color-Button2)" : "solid 2px transparent"}
                        fontColor={isOpen ? "var(--color-Black)" : "var(--color-White)"}
                        buttonText={"참고문헌 추가"}
                    >
                        <Icon name={"plus"} size={"12"} color={isOpen ? "var(--color-Black)" : "var(--color-White)"} />
                    </Button>
                    <ReferenceAddMenu
                        width={isOpen ? "132px" : "100px"}
                        height={isOpen ? "252px" : "0px"}
                        opacity={isOpen ? "1" : "0"}
                        visibility={isOpen ? "visible" : "hidden"}
                        setModalState={setModalState}
                        modalState={modalState}
                        mutation={bibliographyMutation}
                        fileAddHandler={fileAddHandler}
                    />
                </HeaderRightWrap>
            </Header>

            <Body>
                {((referenceList && referenceList.count > 0) || searchParams.filter) && (
                    <BodyHeader>
                        <BodyHeaderLeftSection>
                            <Checkbox
                                margin={"0 8px 0 0"}
                                indeterminate={
                                    checkedBibsRef.current.length > 0 &&
                                    checkedBibsRef.current.length < referenceList?.results?.length
                                }
                                checked={checkedBibsRef.current.length === referenceList?.results?.length}
                                onChange={handleCheckboxAllChange}
                            />
                            <Tooltip message={"삭제"}>
                                <Button
                                    onlyIcon
                                    margin={"0 4px 0 0"}
                                    bgColor={"var(--color-Button2)"}
                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                    onClick={deleteBibliography}
                                    disabled={checkedBibsRef.current.length === 0}
                                >
                                    <Icon name={"delete"} size={"12"} color={"var(--color-White)"} />
                                </Button>
                            </Tooltip>
                            <Button
                                onlyText
                                margin={"0 4px 0 0"}
                                padding={"0 6px"}
                                width={"auto"}
                                height={"24px"}
                                bgColor={"var(--color-Button1)"}
                                hoverBgColor={"var(--color-ButtonHover1)"}
                                onClick={(e) =>
                                    setModalState({
                                        ...modalState,
                                        researchMultiAddModal: !modalState.researchMultiAddModal,
                                    })
                                }
                                buttonText={"리서치 연결"}
                                disabled={checkedBibsRef.current.length === 0}
                            />
                            <Button
                                onlyText
                                padding={"0 6px"}
                                width={"auto"}
                                height={"24px"}
                                bgColor={"var(--color-Button1)"}
                                hoverBgColor={"var(--color-ButtonHover1)"}
                                onClick={(e) =>
                                    setModalState({
                                        ...modalState,
                                        tagMultiAddModal: !modalState.tagMultiAddModal,
                                    })
                                }
                                buttonText={"태그 연결"}
                                disabled={checkedBibsRef.current.length === 0}
                            />
                        </BodyHeaderLeftSection>
                        <BodyHeaderRightSection>
                            <SearchWrap>
                                <SearchInputBox>
                                    <SearchInput
                                        value={searchFilter}
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setSearchParams({ ...searchParams, filter: searchFilter, page: 1 });
                                            }
                                        }}
                                    />
                                    <SearchButtonWrap>
                                        {searchFilter && (
                                            <Tooltip message={"초기화"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"var(--color-White)"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={(e) => {
                                                        setSearchFilter("");
                                                        setSearchParams({ ...searchParams, filter: "", page: 1 });
                                                    }}
                                                >
                                                    <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <Tooltip message={"검색"}>
                                            <Button
                                                onlyIcon
                                                bgColor={"var(--color-White)"}
                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                onClick={(e) => {
                                                    setSearchParams({
                                                        ...searchParams,
                                                        filter: searchFilter,
                                                        page: 1,
                                                    });
                                                }}
                                            >
                                                <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                            </Button>
                                        </Tooltip>
                                    </SearchButtonWrap>
                                </SearchInputBox>
                            </SearchWrap>
                            <BookmarkWrap>
                                <Button
                                    onlyIcon
                                    margin={"0 8px 0 0"}
                                    buttonSize={"24px"}
                                    bgColor={"var(--color-White)"}
                                    hoverBgColor={"none"}
                                    border={
                                        searchParams.filter.includes("is:bookmarked")
                                            ? "solid 2px var(--color-Key)"
                                            : "solid 2px var(--color-DisabledButton)"
                                    }
                                    onClick={() => {
                                        if (searchParams.filter.includes("is:bookmarked")) {
                                            setSearchFilter((prev) => prev.replace("is:bookmarked", ""));
                                            setSearchParams({
                                                ...searchParams,
                                                filter: searchParams.filter.replace("is:bookmarked", ""),
                                                page: 1,
                                            });
                                        } else {
                                            setSearchFilter((prev) => prev + " is:bookmarked");
                                            setSearchParams({
                                                ...searchParams,
                                                filter: searchParams.filter + " is:bookmarked",
                                                page: 1,
                                            });
                                        }
                                    }}
                                >
                                    <Icon
                                        name={"bookmark"}
                                        size={"12px"}
                                        color={
                                            searchParams.filter.includes("is:bookmarked")
                                                ? "var(--color-Key)"
                                                : "var(--color-DisabledButton)"
                                        }
                                    />
                                </Button>
                                <GeneralText size={"small"}>즐겨찾기만 보기</GeneralText>
                            </BookmarkWrap>
                            <DivideLine />
                            <ViewButtonWrap>
                                <OptionButton
                                    optionBoxActive={optionBoxActive}
                                    onClick={() => {
                                        setOptionBoxActive((prev) => !prev);
                                    }}
                                    ref={OptionBoxRef}
                                >
                                    <Icon
                                        name={"option"}
                                        color={optionBoxActive ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                        size={"14px"}
                                    />
                                    <OptionBoxContainer width={"240px"} visible={optionBoxActive}>
                                        <GeneralText
                                            margin={"0 0 16px 0"}
                                            size={"regular"}
                                            fontWeight={"600"}
                                            textAlign={"left"}
                                        >
                                            테이블 항목
                                        </GeneralText>
                                        <CheckOptionWrap onClick={(e) => e.stopPropagation()}>
                                            {columns.map((column) => {
                                                return ["id", "is_bookmarked", "display_name"].includes(
                                                    column.id,
                                                ) ? null : (
                                                    <CheckItemWrap key={column.id}>
                                                        <CheckRadioItemLabel>
                                                            <input
                                                                {...{
                                                                    type: "checkbox",
                                                                    style: { display: "block" },
                                                                    checked:
                                                                        columnVisibility[column.id] !== undefined
                                                                            ? columnVisibility[column.id]
                                                                            : true,
                                                                    onChange: (e) => {
                                                                        setColumnVisibility((prev) => ({
                                                                            ...prev,
                                                                            [column.id]: !(
                                                                                columnVisibility[column.id] ?? true
                                                                            ),
                                                                        }));
                                                                        e.stopPropagation();
                                                                    },
                                                                }}
                                                            />{" "}
                                                            <GeneralText margin={"0 0 0 4px"} size={"small"}>
                                                                {column.name}
                                                            </GeneralText>
                                                        </CheckRadioItemLabel>
                                                    </CheckItemWrap>
                                                );
                                            })}
                                        </CheckOptionWrap>
                                    </OptionBoxContainer>
                                </OptionButton>
                            </ViewButtonWrap>
                        </BodyHeaderRightSection>
                    </BodyHeader>
                )}
                {referenceList && referenceList.count > 0 ? (
                    <>
                        <BodyContent
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            isFileDragging={isFileDragging}
                        >
                            {isFileDragging ? (
                                <BodyContentUploadView>
                                    <UploadDropBox>
                                        <Icon name={"fileUploadOn"} size={"100px"} />
                                        <GeneralText margin={"24px 0 0 0"} size={"large"} textAlign={"center"}>
                                            <strong>참고문헌 파일</strong>을 업로드합니다.
                                        </GeneralText>
                                    </UploadDropBox>
                                </BodyContentUploadView>
                            ) : (
                                <>
                                    <Table
                                        tableKey={"reference"}
                                        data={referenceList?.results || []}
                                        columns={columns}
                                        useSizingColumns={true}
                                        useSortingColumns={true}
                                        useOrderColumns={true}
                                        columnVisibility={columnVisibility}
                                        setColumnVisibility={setColumnVisibility}
                                        pagination={{
                                            pageIndex: searchParams.page,
                                            pageSize: searchParams.page_size,
                                            count: referenceList?.count,
                                        }}
                                        setPagination={(page) => {
                                            if (Number(searchParams.page_size) !== Number(page.pageSize)) {
                                                handlePageSizeChange(page.pageSize);
                                            } else if (searchParams.page !== Number(page.pageIndex)) {
                                                handlePageChange(page.pageIndex);
                                            } else {
                                                return;
                                            }
                                        }}
                                        sorting={
                                            searchParams?.ordering
                                                ? [
                                                      {
                                                          id: searchParams.ordering.replace("-", ""),
                                                          desc: searchParams.ordering.startsWith("-"),
                                                      },
                                                  ]
                                                : []
                                        }
                                        setSorting={handleSortChange}
                                        onRowClick={(row) => {
                                            saveSessionCache();
                                            navigate(`/reference/${row.original.uuid}`);
                                        }}
                                    />
                                </>
                            )}
                        </BodyContent>
                    </>
                ) : (
                    <>
                        {searchParams.filter ? (
                            <BodyContentBlankView>
                                <GeneralText size={"large"} margin={"0 0 16px 0"}>
                                    일치하는 참고문헌이 없습니다.
                                </GeneralText>
                                <GeneralText size={"small"} textAlign={"center"} color={"var(--color-Grey2)"}>
                                    다른 검색어로 찾아보세요.
                                </GeneralText>
                            </BodyContentBlankView>
                        ) : (
                            <BodyContentBlankView>
                                <FileUploadSection>
                                    <AddFileBox
                                        onDragEnter={handleDragEnter}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        isFileDragging={isFileDragging}
                                    >
                                        <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                            등록된 참고문헌이 없습니다.
                                        </GeneralText>
                                        <AddBoxIcon>
                                            <Icon
                                                name={isFileDragging ? "fileUploadOn" : "fileUploadOff"}
                                                size={"100px"}
                                            />
                                        </AddBoxIcon>
                                        <GeneralText margin={"0 0 4px 0"} size={"large"} textAlign={"center"}>
                                            <strong>참고문헌 추가 버튼</strong>을 선택하여 참고문헌을 추가하세요.
                                        </GeneralText>
                                        <GeneralText size={"large"} textAlign={"center"}>
                                            또는 <strong>PDF 파일을 이곳에 드래그</strong>하여 참고문헌을 추가할 수
                                            있습니다.
                                        </GeneralText>
                                    </AddFileBox>
                                </FileUploadSection>
                                <AddInfoSection>
                                    <AddInfoBox device={device}>
                                        <AddInfoItem>
                                            <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                                참고문헌을 추가하면 다음 기능을 이용할 수 있습니다.
                                            </GeneralText>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>웹 저장소로 어디서나 접근</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>연관 논문 추천</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>
                                                    리서치에 인용 및 인용문 스타일 전환
                                                </GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>BibTeX 다운로드</GeneralText>
                                            </AddInfoItemRow>
                                        </AddInfoItem>
                                        <AddInfoItem>
                                            <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                                PDF 파일을 추가하면 더 많은 기능들을 사용할 수 있습니다.
                                            </GeneralText>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>서지정보, 태그 자동 추출</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>PDF 리뷰 환경</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoSubItemWrap>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>하이라이트 메모</GeneralText>
                                                </AddInfoSubItem>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>
                                                        AI 번역, 요약, 설명, 질문
                                                    </GeneralText>
                                                </AddInfoSubItem>
                                            </AddInfoSubItemWrap>
                                            <AddInfoItemRow>
                                                <Icon {...CheckIconStyle} />
                                                <GeneralText {...ItemTextStyle}>PDF 자동 요약 및 비평</GeneralText>
                                            </AddInfoItemRow>
                                            <AddInfoSubItemWrap>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>
                                                        연구 주제, 방법, 결과 요약
                                                    </GeneralText>
                                                </AddInfoSubItem>
                                                <AddInfoSubItem>
                                                    <GeneralText size={"large"} color={"var(--color-SubBlack)"}>
                                                        &#183;
                                                    </GeneralText>
                                                    <GeneralText {...SubItemTextStyle}>
                                                        논문에 대한 AI의 비평문 초안
                                                    </GeneralText>
                                                </AddInfoSubItem>
                                            </AddInfoSubItemWrap>
                                        </AddInfoItem>
                                    </AddInfoBox>
                                </AddInfoSection>
                            </BodyContentBlankView>
                        )}
                    </>
                )}
            </Body>
            {modalState.arxivModal && (
                <AbstractModal
                    modalTitle="ArXiv ID로 참고문헌 추가"
                    width={480}
                    exitModal={(e) => setModalState({ ...modalState, arxivModal: !modalState.arxivModal })}
                >
                    <ReferenceAddArxivModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                arxivModal: !modalState.arxivModal,
                            })
                        }
                        mutation={bibliographyMutation}
                    />
                </AbstractModal>
            )}
            {modalState.doiModal && (
                <AbstractModal
                    modalTitle="DOI로 참고문헌 추가"
                    width={480}
                    exitModal={(e) => setModalState({ ...modalState, doiModal: !modalState.doiModal })}
                >
                    <ReferenceAddDoiModal
                        exitModal={(e) => setModalState({ ...modalState, doiModal: !modalState.doiModal })}
                        mutation={bibliographyMutation}
                    />
                </AbstractModal>
            )}
            {modalState.pubmedModal && (
                <AbstractModal
                    modalTitle="PubMed ID로 참고문헌 추가"
                    width={480}
                    exitModal={(e) =>
                        setModalState({
                            ...modalState,
                            pubmedModal: !modalState.pubmedModal,
                        })
                    }
                >
                    <ReferenceAddPubmedModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                pubmedModal: !modalState.pubmedModal,
                            })
                        }
                        mutation={bibliographyMutation}
                    />
                </AbstractModal>
            )}
            {modalState.bibtexModal && (
                <AbstractModal
                    modalTitle="BibTeX로 참고문헌 추가"
                    width={560}
                    exitModal={(e) =>
                        setModalState({
                            ...modalState,
                            bibtexModal: !modalState.bibtexModal,
                        })
                    }
                >
                    <ReferenceAddBibtexModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                bibtexModal: !modalState.bibtexModal,
                            })
                        }
                    />
                </AbstractModal>
            )}
            {showFileUploadModal && (
                <AbstractModal
                    modalTitle="파일 업로드"
                    width={480}
                    exitModal={(e) => {
                        setShowFileUploadModal(false);
                        bibliographiesQuery.refetch();
                    }}
                >
                    <ReferenceFileUploadModal
                        uploadingFiles={uploadingFiles}
                        exitModal={(e) => {
                            setShowFileUploadModal(false);
                            bibliographiesQuery.refetch();
                        }}
                    />
                </AbstractModal>
            )}
            {modalState.researchMultiAddModal && (
                <AbstractModal
                    modalTitle="리서치 연결"
                    width={480}
                    exitModal={(e) =>
                        setModalState({
                            ...modalState,
                            researchMultiAddModal: !modalState.researchMultiAddModal,
                        })
                    }
                >
                    <ReferenceCitationMultiAddModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                researchMultiAddModal: !modalState.researchMultiAddModal,
                            })
                        }
                        referenceIds={checkedBibsRef.current}
                    />
                </AbstractModal>
            )}
            {modalState.tagMultiAddModal && (
                <AbstractModal
                    modalTitle="태그 연결"
                    width={480}
                    exitModal={(e) =>
                        setModalState({
                            ...modalState,
                            tagMultiAddModal: !modalState.tagMultiAddModal,
                        })
                    }
                >
                    <ReferenceTagMultiAddModal
                        exitModal={(e) =>
                            setModalState({
                                ...modalState,
                                tagMultiAddModal: !modalState.tagMultiAddModal,
                            })
                        }
                        referenceIds={checkedBibsRef.current}
                    />
                </AbstractModal>
            )}
        </LoginUserLayout>
    );
}

export default ReferenceListPage;
