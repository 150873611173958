import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import LandingHeroImage from "assets/png/landingPage/LandingHeroImage.png";

import HtmlHead from "components/atoms/HtmlHead";
import Image from "components/atoms/Image";
import SentenceText from "components/atoms/text/SentenceText";

import FlashingStar from "components/organisms/landing/FlashingStar";
import IntroductionSection from "components/organisms/landing/IntroductionSection";
import GuestUserLayout from "components/templates/GuestUserLayout";

import { useDevice } from "utils/device";

import AOS from "aos";
import "aos/dist/aos.css";

const ContentsWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: calc(100vh - 48px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            min-height: calc(100vh - 56px);
        `};
`;

const TopSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 64px 0 0 0;
    width: 100%;
    height: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 24px 0 0 0;
        `};
`;

const WidthLimit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 40px;
    width: 100%;
    max-width: 1080px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            flex-direction: row;
            justify-content: space-between;
        `};
`;

const LogoCopyWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            align-items: flex-start;
            margin: 0 20px 0 0;
            width: calc(100% - 340px);
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            width: calc(100% - 460px);
        `};
`;

const LandingLogoWrap = styled.div`
    height: 32px;
    transform: scale(0.8);

    > img {
        height: 100%;
    }

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            hegiht: 40px;
            transform: scale(1);
        `};
`;

const HeroMessage = styled.h1`
    margin: 24px 0 0 0;
    font-size: 32px;
    font-family: "LINESeedKR";
    color: var(--color-NormalBlack);
    text-align: center;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            font-size: 40px;
            text-align: left;
            margin: 40px 0 0 0;
        `};
`;

const KeyColorText = styled.span`
    color: var(--color-TemporaryKey);
`;

const LandingImageWrap = styled.div`
    position: relative;
    margin: 24px 0 0 0;
    padding: 24px 0;
    width: 240px;

    > img {
        width: 100%;
        opacity: 0.9;
    }

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: block;
            margin: 0;
            width: 360px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            width: 440px;
        `};
`;

const AnimationWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;

function LandingPage(props) {
    const device = useDevice();

    const navigate = useNavigate();

    useEffect(() => {
        // 로그인 상태에서는 참고문헌 관리로 이동
        if (localStorage.getItem("token")) {
            navigate("/reference");
        }
    }, []);

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 800,
            easing: "ease-in-out",
            once: true,
        });
    }, []);

    return (
        <GuestUserLayout>
            <HtmlHead title={"PREP"} />
            <ContentsWrap device={device}>
                <TopSection device={device}>
                    <WidthLimit device={device}>
                        <LogoCopyWrap data-aos="fade-up" device={device}>
                            <LandingLogoWrap device={device}>
                                <Image name={"landingLogo"} />
                            </LandingLogoWrap>
                            <HeroMessage device={device}>
                                연구자를 위한 {!device.isTabletOrPC && <br />}최고의 선택{" "}
                                <KeyColorText>프렙</KeyColorText>
                            </HeroMessage>

                            <SentenceText
                                size={"xlarge"}
                                margin={device.isTabletOrPC ? "24px 0 0 0" : "16px 0 0 0"}
                                textAlign={device.isTabletOrPC ? "left" : "center"}
                            >
                                서지정보 관리부터 논문 초안 작성까지
                                <br />
                                이제 AI로 선행 논문을 요약하여 빠르게 학습하고,
                                <br />
                                새로운 논문의 초안 작성, 번역, 교정도 AI에게 맡겨보세요.
                            </SentenceText>
                        </LogoCopyWrap>
                        <LandingImageWrap data-aos="fade-up" data-aos-delay="50" device={device}>
                            <img src={LandingHeroImage} alt={"배경이미지"} />
                            <AnimationWrap>
                                <FlashingStar />
                            </AnimationWrap>
                        </LandingImageWrap>
                    </WidthLimit>
                </TopSection>

                <IntroductionSection />
            </ContentsWrap>
        </GuestUserLayout>
    );
}

export default LandingPage;
