import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const GoogleAnalyticsService = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        // 구글 애널리틱스 운영서버만 적용
        if (process.env.REACT_APP_ENV === "prd") {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
            ReactGA.gtag("config", "AW-16518517814");
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.set({ page: location.pathname });
            ReactGA.send("pageview");
        }
    }, [initialized, location]);
};

export default GoogleAnalyticsService;
