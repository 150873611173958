import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div``;

const ItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 8px 0;
    width: 180px;
    height: 20px;
`;

const TagItemWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: auto;
    max-width: 180px;
    height: 20px;
`;

const TagItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.margin || "2px"};
    padding: 2px 4px;
    max-width: 180px;
    height: 20px;
    outline: none;
    background-color: var(--color-Tag);
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-TagActive);
    }
`;

const MoreTag = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 2px 4px;
    max-width: 180px;
    height: 20px;
    outline: none;
    background-color: var(--color-TagActive);
    border: transparent;
    border-radius: 2px;
    cursor: pointer;
`;

const BlankBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 20px;
    background-color: var(--color-BaseBlue);
    border-radius: 2px;
`;

const MoreBox = styled.div`
    position: fixed;
    top: ${({ position }) => position.top}px;
    right: ${({ position }) => position.right}px;
    display: ${({ active }) => (active ? "block" : "none")};
    width: 240px;
    max-width: 240px;
    min-height: 80px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 1;
`;

const MoreBoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 32px;
    border-bottom: solid 1px var(--color-Outline);
`;

const MoreContentWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 8px 8px 8px;
    max-width: 240px;
    max-height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
`;

function ReferenceTagList(props) {
    const navigate = useNavigate();
    const moreBoxRef = useRef();

    const [showTagBox, setShowTagBox] = useState(false);
    const [moreBoxPosition, setMoreBoxPosition] = useState({ top: 0, right: 0 });

    const handleMoreTagClick = (e) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        setMoreBoxPosition({
            top: rect.top + rect.height + 8,
            right: window.innerWidth - rect.right,
        });
        setShowTagBox((prev) => !prev);
        e.stopPropagation();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreBoxRef.current && !moreBoxRef.current.contains(event.target)) {
                setShowTagBox(false);
            }
        };

        const handleScroll = () => {
            setShowTagBox(false);
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("scroll", handleScroll, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <Container>
            {props.showHeader && (
                <ItemHeader>
                    <TitleText withBarType size={"subSmall"}>
                        태그
                    </TitleText>
                    <Tooltip message={"태그 선택"}>
                        <Button
                            onlyIcon
                            buttonSize={"20px"}
                            bgColor={"transparent"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={props.onModifyClick}
                        >
                            <Icon name={"modify"} color={"var(--color-SubBlack)"} />
                        </Button>
                    </Tooltip>
                </ItemHeader>
            )}
            {props.bibliography?.tag_mappings?.length > 0 ? (
                <TagItemWrap>
                    <TagItem
                        margin={"0 4px 0 0"}
                        style={
                            props.bibliography.tag_mappings.length > 1 ? { maxWidth: "140px" } : { maxWidth: "180px" }
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/reference`, {
                                state: {
                                    filter: `t:"${props.bibliography.tag_mappings[0].name}"`,
                                },
                            });
                        }}
                    >
                        <GeneralText ellipsis size={"xsmall"}>
                            {props.bibliography.tag_mappings[0].name}
                        </GeneralText>
                    </TagItem>
                    {props.bibliography.tag_mappings.length > 1 && (
                        <MoreTag
                            onClick={(e) => {
                                handleMoreTagClick(e);
                            }}
                            ref={moreBoxRef}
                        >
                            <GeneralText ellipsis size={"xsmall"}>
                                +{props.bibliography.tag_mappings.length - 1}
                            </GeneralText>
                            <MoreBox active={showTagBox} position={moreBoxPosition}>
                                <MoreBoxHeader>
                                    <TitleText size={"subSmall"} color={"var(--color-SubBlack)"}>
                                        태그 목록
                                    </TitleText>
                                    <Icon
                                        name={"x"}
                                        size={"8"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowTagBox(!showTagBox);
                                        }}
                                    />
                                </MoreBoxHeader>
                                <MoreContentWrap>
                                    {props.bibliography.tag_mappings.map((tag, index) => (
                                        <TagItem
                                            key={index + "tag"}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/reference`, {
                                                    state: {
                                                        filter: `t:"${tag.name}"`,
                                                    },
                                                });
                                            }}
                                        >
                                            <GeneralText ellipsis size={"xsmall"}>
                                                {tag.name}
                                            </GeneralText>
                                        </TagItem>
                                    ))}
                                </MoreContentWrap>
                            </MoreBox>
                        </MoreTag>
                    )}
                </TagItemWrap>
            ) : (
                <BlankBox>
                    <GeneralText size={"small"} color={"var(--color-Grey1)"}>
                        선택된 태그가 없습니다.
                    </GeneralText>
                </BlankBox>
            )}
        </Container>
    );
}

export default ReferenceTagList;
