import React from "react";

import styled, { css } from "styled-components";

import PageFooter from "components/templates/footer/PageFooter";
import PageHeader from "components/templates/header/PageHeader";

import Constants from "utils/constants";
import { useDevice } from "utils/device";

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const BodyContainer = styled.div`
    position: relative;
    margin: ${Constants.MOBILE_HEADER_HEIGHT}px 0 0 0;
    width: 100%;
    height: auto;
    overflow: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px 0 0 0;
        `};
`;

const ContentsSection = styled.div`
    display: flex;
    justify-content: center;
    height: auto;
    min-height: unset;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            min-height: 855px;
        `};
`;

function GuestUserLayout(props) {
    const device = useDevice();

    return (
        <Container>
            {props.noHeader ? <></> : <PageHeader />}
            <BodyContainer device={device}>
                <ContentsSection device={device}>{props.children}</ContentsSection>
                <PageFooter />
            </BodyContainer>
        </Container>
    );
}

export default GuestUserLayout;
