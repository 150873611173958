import React, { useEffect, useState } from "react";

import styled, { css } from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Placeholder from "components/atoms/Placeholder";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import AbstractModal from "components/page/modal/AbstractModal";
import PlanInfoModal from "components/page/modal/PlanInfoModal";
import GuestUserLayout from "components/templates/GuestUserLayout";
import LoginUserLayout from "components/templates/LoginUserLayout";

import {
    usePaymentBillingKeyCreate,
    usePaymentBillingKeyDelete,
    usePaymentPlanSubscriptionHistoryCreate,
} from "hooks/queries/usePayment";

import Constants from "utils/constants";
import { useDevice } from "utils/device";
import { userCheck } from "utils/request/auth";
import { getPaymentBillingKeyList, getPaymentPlan, getPaymentPlanSubscriptionHistory } from "utils/request/payment";
import { isGroupPlanUser } from "utils/validation";

import moment from "moment";

const Body = styled.div`
    display: flex;
    width: 100%;
    max-height: ${(props) => (props.isLoggedIn ? "calc(100vh - 56px)" : "unset")};
    overflow: auto;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 80px 0;
    width: 100%;
    height: fit-content;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 0 0 160px 0;
        `};
`;

const WidthLimit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 767px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 0 40px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 80px;
            max-width: 1080px;
        `};
`;

const MobileWidthLimit = styled.div`
    padding: 0 24px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 0;
        `};
`;

// 타이틀 섹션

const TitleSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0 0 0;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 40px 0 0 0;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            align-items: flex-start;
        `};
`;

const TitleColor = styled.span`
    color: var(--color-Key);
`;

// 플랜 카드 섹션

const CardSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 40px 0 0 0;
    padding: 0 24px;
    overflow-x: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 0;
        `};
`;

const CardItem = styled.div`
    position: relative;
    margin: 0 8px 0 0;
    width: calc(33% - 4px);
    min-width: 240px;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 8px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: 0;
            min-width: calc(33% - 4px);
            max-width: calc(33% - 4px);
            height: 360px;
            max-height: 360px;
        `};
`;

const CardHeader = styled.div`
    padding: 12px 0;
    width: 100%;
    background-color: ${(props) => (props.isSubscribe ? "var(--color-Key)" : "var(--color-Base2)")};
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 16px 0;
        `};
`;

const CardContentsWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 12px 12px;
    width: 100%;
    height: calc(100% - 52px);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 16px 16px 16px;
        `};
`;

const TopWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 72px);
`;

const PriceWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 16px 0 0 0;
`;

const CardButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    min-height: 32px;
    background-color: var(--color-Button2);
    border: solid 1px var(--color-Button2);
    border-radius: 4px;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover2);
        border: solid 1px var(--color-ButtonHover2);
    }
    &:disabled {
        background-color: var(--color-White);
        border: solid 1px var(--color-Button2);

        p {
            color: var(--color-Black);
        }
    }

    ${(props) =>
        props.device.isPC &&
        css`
            height: 40px;
            min-height: 40px;
        `};
`;

// 문의하기 박스

const ContactBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: Center;
    margin: 40px 0 0 0;
    padding: 24px 0;
    width: 100%;
    background-color: var(--color-InfoBox);
    border-radius: 8px;
    border: solid 1px var(--color-Outline);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 40px 0;
        `};
`;

const priceStyle = {
    size: "large",
    margin: "0 2px 0 0",
};

const monthStyle = {
    size: "small",
    margin: "2px 0 0 0",
    color: "var(--color-Grey1)",
};

const cardDescriptionStyle = {
    size: "regular",
    margin: "40px 0 0 0",
    textAlign: "center",
};

function PlanPage(props) {
    const device = useDevice();
    const { setLoading } = useLoading();

    const [user, setUser] = useState({});
    const [plan, setPlan] = useState([]);
    const [userPlan, setUserPlan] = useState({});
    const [billingKey, setBillingKey] = useState({ is_deleted: true });
    const [initPlanStatus, setInitPlanStatus] = useState(false);
    // 구독했던 이력
    const [showPlanInfoModal, setShowPlanInfoModal] = useState(false);
    const [selectedPlanInfo, setSelectedPlanInfo] = useState({
        id: 0,
        planName: "",
        amount: "",
        today: "",
    });

    const subscribeHistoryMutation = usePaymentPlanSubscriptionHistoryCreate();
    const billingKeyMutation = usePaymentBillingKeyCreate();
    const billingKeyDelete = usePaymentBillingKeyDelete();

    const isLoggedIn = localStorage.getItem("token") ? true : false;
    const ContainerComponent = isLoggedIn ? LoginUserLayout : GuestUserLayout;

    useEffect(() => {
        const initialize = async () => {
            await initPlan();

            if (isLoggedIn) {
                await initUserPlan();
            }

            setInitPlanStatus(true);
        };

        initialize();
        const jquery = document.createElement("script");
        jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
        const iamport = document.createElement("script");
        iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
        document.head.appendChild(jquery);
        document.head.appendChild(iamport);

        return () => {
            document.head.removeChild(jquery);
            document.head.removeChild(iamport);
        };
    }, []);

    const initPlan = async () => {
        const plan_data = await getPaymentPlan();
        setPlan(plan_data);
    };

    const initUserPlan = async () => {
        const user_data = await userCheck();
        setUser(user_data);

        const sub_history = await getPaymentPlanSubscriptionHistory();
        const user_plan_data = sub_history[sub_history.length - 1];
        setUserPlan(user_plan_data);

        const billing_key_list = await getPaymentBillingKeyList();
        const billing_key_data =
            billing_key_list.length === 0 ? { is_deleted: true } : billing_key_list[billing_key_list.length - 1];
        setBillingKey(billing_key_data);
    };

    // 코드 가독성을 위해 선언한 함수
    const refreshUserPlan = async () => {
        setLoading(true, "사용자 정보를 갱신 중입니다.");
        await initUserPlan();
        await setLoading(false);
    };

    const subscribe = () => {
        if (isGroupPlanUser(user)) {
            Alert(
                "warn",
                "에러",
                "현재 단체 플랜 구독 중입니다. 먼저 설정의 결제 화면에서 단체 플랜 구독을 해지해주세요.",
            );
            return;
        }
        if (selectedPlanInfo.id === Constants.FREE_PLAN && userPlan.plan === Constants.PAID_PLAN) {
            Confirm("warn", "구독 변경", "무료 플랜으로 변경하시겠습니까?", "확인", (e) => {
                setLoading(true);
                // 유료 플랜 -> 무료 플랜으로 변경 시 일할 계산 환불
                subscribeHistoryMutation.mutate(
                    { plan: selectedPlanInfo.id },
                    {
                        onSuccess: () => {
                            setLoading(false);
                            if (billingKey.id) {
                                billingKeyDelete.mutate(billingKey.id);
                            }
                            Alert("info", "변경", "구독 요금제가 변경되었습니다.");
                            refreshUserPlan();
                        },
                        onError: () => {
                            setLoading(false);
                            Alert("warn", "에러", "구독 정보 변경 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
                        },
                    },
                );
            });
        }

        if (selectedPlanInfo.id === Constants.PAID_PLAN && userPlan.plan === Constants.FREE_PLAN) {
            pay_for_subscription();
        }

        setShowPlanInfoModal(false);
    };

    const pay_for_subscription = () => {
        const { IMP } = window;
        IMP.init("imp65161342");
        let randomNumber = Math.floor(Math.random() * 90000) + 10000;
        let merchant_uid = `subscribe_${user.email.split("@")[0]}_${randomNumber}`;
        let customer_uid = `${user.email.split("@")[0]}_${randomNumber}`;

        const callback = (response) => {
            document.getElementById("iamport-modal").style.display = "none";
            if (response.success) {
                setLoading(true, "결제 요청 중입니다.");
                billingKeyMutation.mutate(response, {
                    onSuccess: () => {
                        subscribeHistoryMutation.mutate(
                            { plan: selectedPlanInfo.id, merchant_uid: merchant_uid },
                            {
                                onSuccess: () => {
                                    setLoading(false);
                                    Alert("info", "구독", "결제가 완료되었습니다.");
                                    refreshUserPlan();
                                },
                                onError: () => {
                                    setLoading(false);
                                    Alert(
                                        "warn",
                                        "에러",
                                        "구독 정보 변경 중 오류가 발생했습니다. 관리자에게 문의해주세요.",
                                    );
                                },
                            },
                        );
                    },
                    onError: () => {
                        setLoading(false);
                        Alert("warn", "에러", "결제 정보 등록 중 오류가 발생했습니다. 결제 정보를 다시 확인해주세요.");
                    },
                });
            } else {
                if (response.error_msg === "사용자가 결제를 취소하셨습니다") {
                    return;
                }
                Alert("warn", "에러", "결제 중 오류가 발생했습니다. 결제 정보를 다시 확인해주세요.");
            }
        };
        document.getElementById("iamport-modal").style.display = "block";
        IMP.request_pay(
            {
                pg: `${process.env.REACT_APP_ENV !== "prd" ? "danal_tpay.9810030929" : "danal_tpay"}`,
                pay_method: "card",
                merchant_uid: merchant_uid,
                name: `자동결제 등록 > ${selectedPlanInfo.planName}`,
                period: {
                    from: moment().format("YYYY-MM-DD"),
                    to: moment().add(1, "months").add(-1, "days").format("YYYY-MM-DD"),
                },
                amount: selectedPlanInfo.amount,
                customer_uid: customer_uid,
                buyer_email: user.email,
            },
            callback,
        );
    };

    const openSubscribeModal = (planId) => {
        if (!user.email) {
            Alert("warn", "에러", "로그인 후 이용해주세요.");
            return;
        }

        let selectedPlan = plan.find((item) => item.id === planId);
        setSelectedPlanInfo({
            id: selectedPlan.id,
            planName: selectedPlan.name,
            amount: selectedPlan.price,
            today: moment().format("DD"),
        });
        setShowPlanInfoModal(true);
    };

    return (
        <ContainerComponent minWidth={"unset"}>
            <HtmlHead title={"요금 안내"} />
            <Body isLoggedIn={isLoggedIn}>
                <ContentsWrapper device={device}>
                    <WidthLimit device={device}>
                        {/* 타이틀 섹션 */}
                        <MobileWidthLimit device={device}>
                            <TitleSection device={device}>
                                <GeneralText size={"small"} margin={"0 0 8px 0"} color={"var(--color-Grey1)"}>
                                    요금안내
                                </GeneralText>
                                <TitleText
                                    size={"large"}
                                    margin={"0 0 24px 0"}
                                    textAlign={device.isPC ? "left" : "center"}
                                >
                                    프렙의 모든 AI 기능을 경험하세요 <br />
                                    <TitleColor>
                                        무료 플랜부터 맞춤 플랜까지 {!device.isTabletOrPC && <br />}다양한 옵션을
                                        제공합니다
                                    </TitleColor>
                                </TitleText>
                                <GeneralText size={"regular"} textAlign={device.isPC ? "left" : "center"}>
                                    유료 구독으로 더 좋은 경험을 해보세요.
                                    <br />
                                    무료 플랜도 참고문헌 관리에는 충분하지만,{!device.isTabletOrPC && <br />} 유료
                                    플랜에서는 생성형 AI와 새로운 쓰기 경험을 할 수 있습니다.
                                </GeneralText>
                            </TitleSection>
                        </MobileWidthLimit>

                        {/* 플랜 카드 섹션 */}
                        <CardSection device={device}>
                            <CardItem device={device}>
                                <CardHeader device={device} isSubscribe={user?.plan_id === Constants.FREE_PLAN}>
                                    <TitleText
                                        size={"subRegular"}
                                        textAlign={"center"}
                                        color={
                                            user?.plan_id === Constants.FREE_PLAN
                                                ? "var(--color-White)"
                                                : "var(--color-Black)"
                                        }
                                    >
                                        {initPlanStatus ? (
                                            plan.find((item) => item.id === Constants.FREE_PLAN)?.name
                                        ) : (
                                            <Placeholder style={{ width: "100px", height: "19px" }} />
                                        )}
                                    </TitleText>
                                </CardHeader>
                                <CardContentsWrap device={device}>
                                    <TopWrap>
                                        <PriceWrap>
                                            {initPlanStatus ? (
                                                <>
                                                    <TitleText {...priceStyle}>무료</TitleText>
                                                    <GeneralText {...monthStyle}>/ 매달</GeneralText>
                                                </>
                                            ) : (
                                                <TitleText {...priceStyle}>
                                                    <Placeholder style={{ width: "100px", height: "29px" }} />
                                                </TitleText>
                                            )}
                                        </PriceWrap>

                                        <GeneralText {...cardDescriptionStyle}>
                                            {initPlanStatus ? (
                                                "체계적인 참고문헌 관리를 위한 요금제. 언제 어디서든 참고문헌을 관리하고 리뷰할 수 있습니다."
                                            ) : (
                                                <>
                                                    <Placeholder style={{ width: "150px", height: "20px" }} />
                                                    <Placeholder style={{ width: "40px", height: "20px" }} />
                                                </>
                                            )}
                                        </GeneralText>
                                    </TopWrap>
                                    <CardButton
                                        device={device}
                                        onClick={(e) =>
                                            openSubscribeModal(plan.find((item) => item.id === Constants.FREE_PLAN).id)
                                        }
                                        disabled={user?.plan_id === Constants.FREE_PLAN || initPlanStatus === false}
                                    >
                                        <GeneralText size={"regular"} color={"var(--color-White)"}>
                                            {user?.plan_id === Constants.FREE_PLAN ? "구독중" : "구독하기"}
                                        </GeneralText>
                                    </CardButton>
                                </CardContentsWrap>
                            </CardItem>
                            <CardItem device={device}>
                                <CardHeader device={device} isSubscribe={user?.plan_id === Constants.PAID_PLAN}>
                                    <TitleText
                                        size={"subRegular"}
                                        textAlign={"center"}
                                        color={
                                            user?.plan_id === Constants.PAID_PLAN
                                                ? "var(--color-White)"
                                                : "var(--color-Black)"
                                        }
                                    >
                                        {initPlanStatus ? (
                                            plan.find((item) => item.id === Constants.PAID_PLAN)?.name
                                        ) : (
                                            <Placeholder style={{ width: "100px", height: "19px" }} />
                                        )}
                                    </TitleText>
                                </CardHeader>
                                <CardContentsWrap device={device}>
                                    <TopWrap>
                                        <PriceWrap>
                                            {initPlanStatus ? (
                                                <>
                                                    <TitleText {...priceStyle}>
                                                        <span style={{ textDecoration: "line-through" }}>
                                                            {
                                                                plan.find((item) => item.id === Constants.PAID_PLAN)
                                                                    ?.price
                                                            }{" "}
                                                            원
                                                        </span>
                                                    </TitleText>
                                                    <GeneralText {...monthStyle}>/ 매달</GeneralText>
                                                </>
                                            ) : (
                                                <TitleText {...priceStyle}>
                                                    <Placeholder style={{ width: "100px", height: "29px" }} />
                                                </TitleText>
                                            )}
                                        </PriceWrap>
                                        <GeneralText {...cardDescriptionStyle}>
                                            {initPlanStatus ? (
                                                "매달 무료 쿠폰이 발급되고 있습니다. 로그인 후 [설정 > 결제] 탭에서 쿠폰을 확인하세요."
                                            ) : (
                                                <>
                                                    <Placeholder style={{ width: "150px", height: "20px" }} />
                                                    <Placeholder style={{ width: "140px", height: "20px" }} />
                                                </>
                                            )}
                                        </GeneralText>
                                    </TopWrap>
                                    <CardButton
                                        device={device}
                                        onClick={(e) =>
                                            openSubscribeModal(plan.find((item) => item.id === Constants.PAID_PLAN)?.id)
                                        }
                                        // disabled={user?.plan_id === Constants.PAID_PLAN || initPlanStatus === false}
                                        disabled={true} // 무료 기간 중 구독하기 비활성화
                                    >
                                        <GeneralText
                                            disabled={user?.plan_id === Constants.PAID_PLAN}
                                            size={"regular"}
                                            color={"var(--color-White)"}
                                        >
                                            {user?.plan_id === Constants.PAID_PLAN ? "구독중" : "구독하기"}
                                        </GeneralText>
                                    </CardButton>
                                </CardContentsWrap>
                            </CardItem>
                            <CardItem device={device}>
                                <CardHeader device={device}>
                                    <TitleText size={"subRegular"} textAlign={"center"}>
                                        {initPlanStatus ? (
                                            "단체 플랜"
                                        ) : (
                                            <Placeholder style={{ width: "100px", height: "19px" }} />
                                        )}
                                    </TitleText>
                                </CardHeader>
                                <CardContentsWrap device={device}>
                                    <TopWrap>
                                        <GeneralText size={"regular"} textAlign={"center"}>
                                            {initPlanStatus ? (
                                                <>
                                                    {"2인 이상 할인 요금제가 필요하신가요?"}
                                                    <br />
                                                    {"지금 프렙 서비스팀에 문의해주세요."}
                                                </>
                                            ) : (
                                                <>
                                                    <Placeholder style={{ width: "150px", height: "20px" }} />
                                                    <Placeholder style={{ width: "140px", height: "20px" }} />
                                                </>
                                            )}
                                        </GeneralText>
                                    </TopWrap>
                                    <a href={"mailto:prep@datansoft.com"} style={{ display: "block", width: "100%" }}>
                                        <CardButton device={device} disabled={initPlanStatus === false}>
                                            <GeneralText size={"regular"} color={"var(--color-White)"}>
                                                문의하기
                                            </GeneralText>
                                        </CardButton>
                                    </a>
                                </CardContentsWrap>
                            </CardItem>
                        </CardSection>

                        {/* 구독 컨텍트 */}
                        <MobileWidthLimit device={device}>
                            <ContactBox device={device}>
                                <TitleText size={"regular"} textAlign={"center"}>
                                    구독 관련 문의사항이 있으신가요?
                                    <br />
                                    지금 프렙 서비스팀에 문의해보세요.
                                </TitleText>
                                <a href={"mailto:prep@datansoft.com"}>
                                    <Button
                                        onlyText
                                        margin={"24px 0 0 0"}
                                        width={"200px"}
                                        height={"40px"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        fontColor={"var(--color-White)"}
                                        buttonText={"문의하기"}
                                    />
                                </a>
                            </ContactBox>
                        </MobileWidthLimit>
                    </WidthLimit>
                </ContentsWrapper>
            </Body>

            {showPlanInfoModal && (
                <AbstractModal
                    modalTitle="구독하기"
                    width={500}
                    height={640}
                    exitModal={(e) => setShowPlanInfoModal(!showPlanInfoModal)}
                >
                    <PlanInfoModal
                        planId={selectedPlanInfo.id}
                        planName={selectedPlanInfo.planName}
                        amount={selectedPlanInfo.amount}
                        today={selectedPlanInfo.today}
                        exitModal={(e) => setShowPlanInfoModal(!showPlanInfoModal)}
                        subscribeAct={(e) => subscribe()}
                    />
                </AbstractModal>
            )}
            <div
                style={{
                    display: "none",
                    overFlow: "scroll",
                    position: "fixed",
                    zIndex: 10000,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    padding: "120px 0",
                    background: "rgba(0,0,0,.48)",
                    boxSizing: "border-box",
                }}
                id={"iamport-modal"}
            ></div>
        </ContainerComponent>
    );
}

export default PlanPage;
