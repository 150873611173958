import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import FormInput from "components/molecules/FormInput";
import GuestUserSingleCardLayout from "components/templates/GuestUserSingleCardLayout";

import { apiUrl } from "utils/urls";
import { emailValidation } from "utils/validation";

import axios from "axios";

const LoginWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px 0;
    width: 100%;
`;

const LoginLink = styled.button`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    background-color: transparent;
    border: transparent;
    border-bottom: solid 1px var(--color-SubBlack);

    &:hover {
        color: var(--color-Key);
        border-bottom: solid 1px var(--color-Key);
    }
`;

function PasswordResetPage() {
    const { loading, setLoading } = useLoading();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();
    const getResetPassword = () => {
        setLoading(true);
        axios
            .get(apiUrl("resetPassword"), { params: { email: email } })
            .then((res) => {
                setLoading(false);
                if (res.status === 204) {
                    Alert("info", "비밀번호 재발급", `입력하신 이메일 주소로 새로운 비밀번호를 발송했습니다.`);
                    navigate("/login");
                }
            })
            .catch((e) => {
                setLoading(false);
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    const emailChangeHandler = (email) => {
        setEmail(email);
    };

    const emailValidationHandler = (email) => {
        if (email === "") {
            setError("이메일을 입력해주세요.");
        } else if (!emailValidation(email)) {
            setError("올바른 이메일 주소를 입력해주세요.");
        } else {
            setError("");
        }
    };

    return (
        <GuestUserSingleCardLayout width={480}>
            <HtmlHead title={"비밀번호 재발급"} />
            <TitleText size={"large"} margin={"0 0 60px 0"}>
                비밀번호 재발급
            </TitleText>
            <TitleText withBarType size={"small"} margin={"0 0 40px 0"}>
                가입 이메일 주소 입력
            </TitleText>
            <GeneralText size={"regular"} margin={"0 0 40px 0"}>
                이메일 주소로 비밀번호를 재발급하여 보내드립니다.
            </GeneralText>
            <FormInput
                label="이메일"
                subMessageDp="none"
                margin="0 0 60px 0"
                width="100%"
                placeholder="이메일 주소를 입력해주세요"
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => emailChangeHandler(e.target.value)}
                onBlur={(e) => emailValidationHandler(e.target.value)}
                onFocus={(e) => setError("")}
                errorMessage={error}
            />
            <Button
                onlyText
                margin="0 0 60px 0"
                bgColor="var(--color-Button2)"
                fontColor="var(--color-White)"
                buttonText="비밀번호 재발급"
                type="submit"
                onClick={getResetPassword}
                disabled={!(email !== "" && emailValidation(email))}
            />
            <LoginWrap>
                <Link to={"/login"}>
                    <LoginLink>로그인하기</LoginLink>
                </Link>
            </LoginWrap>
        </GuestUserSingleCardLayout>
    );
}

export default PasswordResetPage;
