import { useMediaQuery } from "react-responsive";

import Constants from "utils/constants";

export const useDevice = () => {
    const isTabletOrPC = useMediaQuery({ minWidth: Constants.TABLET_WIDTH });
    const isPC = useMediaQuery({ minWidth: Constants.PC_WIDTH });

    return { isTabletOrPC, isPC };
};
