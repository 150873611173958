import React from "react";

import styled, { css } from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import GuestUserSingleCardLayout from "components/templates/GuestUserSingleCardLayout";

import { useDevice } from "utils/device";

const BodyTextWrap = styled.div`
    min-height: calc(100vh - 320px);
    max-height: unset;
    overflow: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            align-items: flex-start;
            max-height: calc(100vh - 320px);
        `};
`;

const JangWrap = styled.div`
    margin: 0 0 120px 0;

    &:first-child {
        margin: 40px 0 120px 0;
    }

    &:last-child {
        margin: 0;
    }
`;

const JoWrap = styled.div`
    margin: 0 0 40px 0;
`;

const NumberListWrap = styled.div`
    margin: 24px 0 0 24px;
    width: calc(100% - 24px);
`;

const AbcListWrap = styled.div`
    margin: 16px 0 0 0;
    padding: 0 0 0 24px;
    width: calc(100% - 24px);
    border-left: solid 2px var(--color-Grey3);
`;

const titleStyle = {
    size: "regular",
    fontWeight: "bold",
};

const explainStyle = {
    size: "large",
    margin: "24px 0 0 0",
};

const numberListStyle = {
    size: "large",
    margin: "0 0 16px 0",
};

const abcListStyle = {
    size: "large",
    margin: "0 0 16px 0",
};

function TermsOfServicePage() {
    const device = useDevice();

    return (
        <GuestUserSingleCardLayout width={840}>
            <HtmlHead title={"서비스 이용약관"} />
            <TitleText size={"xlarge"} fontWeight={"bold"} textAlign={device.isTabletOrPC ? "left" : "center"}>
                프렙 서비스 이용약관
            </TitleText>
            <BodyTextWrap device={device}>
                {/* 1장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 1장 총칙
                    </TitleText>
                    {/* 1장 - 1조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 1조 목적</TitleText>
                        <GeneralText {...explainStyle}>
                            이 약관은 주식회사 데이탄소프트(이하 ‘회사')가 운영하는 프렙(Prep) 서비스(이하 ‘서비스’)의
                            이용에 필요한 사항을 규정함을 목적으로 합니다.
                        </GeneralText>
                    </JoWrap>
                    {/* 1장 - 2조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 2조 용어의 정의</TitleText>
                        <GeneralText {...explainStyle}>이 약관에서 사용하는 용어는 다음과 같이 정의합니다.</GeneralText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 웹사이트: 회사가 서비스를 제공하기 위해 운영하는 웹사이트
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회원: 회사와 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 주체
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 아이디: 회원이 서비스 이용을 위해 선정하고 회사가 승인하는 문자와 숫자의 조합
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 비밀번호: 회원이 서비스상 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 콘텐츠: 회사가 회원에게 제공하는 모든 내용물 및 회원이 서비스상 작성한 모든 내용물
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                6. 유료서비스: 서비스 내에서 제공하는 유료 기능
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 1장 - 3조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 3조 약관의 공시 및 변경 등</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스의 초기 화면에 게시합니다.
                                다만, 약관의 내용은 회원이 연결 화면을 통하여 볼 수 있도록 할 수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사가 이 약관을 개정할 경우에는 적용 일자 및 개정 사유를 명시하여 공지합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 제3항에 따라 공지된 적용일자까지 회원이 명시적으로 계약 해지의 의사를 표명하지 않을
                                경우에는 개정된 약관에 동의하는 것으로 봅니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자
                                보호에 관한 법률, 약관의 규제에 관한 법률, 기타 관계법령 또는 상관례에 따릅니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                {/* 2장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 2장 이용계약
                    </TitleText>
                    {/* 2장 - 4조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 4조 회원가입 및 관리</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 이용계약은 회원가입 안내에서 약관에 동의 후 서비스 이용 신청하면, 회사가 승인함으로써
                                체결됩니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용이
                                불가합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회원은 회원가입 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 이메일 등의
                                방법으로 회사에 변경사항을 알려야 합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 제3항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지
                                않습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 2장 - 5조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 5조 이용신청의 승인</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 아래 사항에 해당하는 경우에 그 제한사유가 해소될 때까지 승인을 유보할 수
                                있습니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>a. 서비스 관련 설비에 여유가 없는 경우</GeneralText>
                                <GeneralText {...abcListStyle}>b. 기술상 또는 업무상 지장이 있는 경우</GeneralText>
                                <GeneralText {...abcListStyle}>c. 회원 정보가 누락 또는 허위로 기재된 경우</GeneralText>
                                <GeneralText {...abcListStyle}>
                                    d. 기타 회사 사정상 필요하다고 인정되는 경우
                                </GeneralText>
                            </AbcListWrap>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                {/* 3장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 3장 계약 당사자의 의무
                    </TitleText>
                    {/* 3장 - 6조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 6조 회사의 의무</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 약관에 반하는 행위를 하지 않으며, 안정적인 서비스를 제공하도록 최선을 다해야
                                합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
                                준수합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리해야
                                합니다. 단, 즉시 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보해야 합니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 3장 - 7조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 7조 회원의 의무</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회원은 이 약관 및 회사의 공지사항, 웹사이트 이용안내 등을 숙지하고 준수해야 하며 아래
                                각 호의 행위를 해서는 안 됩니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. 서비스의 신청 또는 변경 시 허위내용의 기재
                                </GeneralText>
                                <GeneralText {...abcListStyle}>b. 타인의 정보 또는 결제수단의 도용</GeneralText>
                                <GeneralText {...abcListStyle}>
                                    c. 회사에 게시된 정보의 변경 또는 또는 서비스에 장애를 주는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    d. 다른 회원의 개인정보 및 계정정보를 무단으로 수집∙저장∙게시∙유포하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    e. 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를
                                    복제, 분해 또는 모방 기타 변형하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    f. 해킹, 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를
                                    이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    g. 본인 아닌 제3자에게 계정을 대여, 양도하는 등 접속권한을 부여하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    h. 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    i. 도박 등 사행행위를 하거나 유도하는 행위, 음란⋅저속한 정보를 입력⋅교류⋅게재하거나
                                    음란 사이트를 연결(링크)하는 행위, 수치심⋅혐오감 또는 공포심을 일으키는
                                    말⋅소리⋅글⋅그림⋅사진 또는 영상을 타인에게 전송 또는 유포하는 행위 등 서비스를
                                    불건전하게 이용하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    j. 게시판을 도배할 목적에서 동일 또는 유사한 게시글 또는 의미가 없는 게시글을
                                    반복적으로 개시하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    k. 회사의 동의 없이 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의
                                    용도로 서비스를 이용하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    l. 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제∙유통∙조장하거나 상업적으로
                                    이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    m. 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게
                                    피해를 입히는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    n. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해하는 행위
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    o. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    p. 기타 불법적이거나 부당한 행위
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                2. 회원은 관계법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항,
                                회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는
                                안 됩니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회원은 본인의 아이디, 비밀번호, 결제수단정보 등에 대한 관리책임을 부담하며, 회원의
                                관리소홀로 인하여 발생한 문제에 대하여 회사는 책임을 지지 않습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                {/* 4장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 4장 서비스의 제공 및 이용
                    </TitleText>
                    {/* 4장 - 8조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 8조 서비스 이용</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회원은 이 약관의 규정된 사항을 준수해 서비스를 이용합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사가 회원의 이용신청을 승낙한 때부터 회원은 서비스를 이용할 수 있습니다. 단 일부
                                서비스의 경우에는 지정된 일자 내지 일정 요건 충족 시에 서비스를 이용할 수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 서비스의 이용은 연중무휴, 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상 또는
                                기술상의 이유로 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전에 공지하며,
                                사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 이를 공지합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 회원은 회사의 서비스에 대하여 아래의 FUP 정책(Fair Use Policy, 공정사용정책)을
                                따릅니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. FUP (Fair Use Policy, 공정사용정책)이란 무제한 사용을 허락하는 서비스에서
                                    합리적이고 책임 있는 방식으로 서비스를 사용할 것을 약속 받는 정책입니다.
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    b. 비정상적으로 많은 사용량과 계정 로그인 세부 정보를 다른 사용자와 공유하는 것으로
                                    합리적으로 의심되는 활동이 적발되는 경우 사전 통보 없이 계정이 차단 혹은 영구 삭제가
                                    될 수 있습니다. 이에 관련하여 어떠한 환불도 이루어지지 않습니다.
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                5. 이 약관에 명시되지 않은 서비스 이용에 관한 사항은 회사가 정해 웹사이트 내에
                                게시하거나 또는 별도로 공지하는 내용에 따릅니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 4장 - 9조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 9조 유료서비스</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 회원에게 회사의 서비스의 전부 또는 일부를 유료로 제공할 수 있으며,
                                유료서비스에 관한 구체적인 사항은 유료서비스 공지사항 또는 별도의 유료서비스 운영
                                정책에서 정한 바에 따릅니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 다음 사항을 해당 유료서비스의 공지사항 또는 별도의 유료서비스 운영정책 등을
                                통해 회원이 알기 쉽게 표기합니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>a. 유료서비스의 명칭</GeneralText>
                                <GeneralText {...abcListStyle}>
                                    b. 유료서비스의 내용, 이용방법, 이용료, 결제방법 기타 이용조건
                                </GeneralText>
                                <GeneralText {...abcListStyle}>c. 유료서비스 대금 환불의 조건 및 절차</GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    d. 유료서비스의 중단 및 변경에 관한 사항
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                3. 유료서비스를 이용하고자 하는 회원은 유료서비스 공지사항 또는 유료서비스 운영정책을
                                준수하여야 하며, 이 약관의 내용과 유료서비스 공지사항 또는 유료서비스 운영정책의 내용이
                                상충하는 경우 유료서비스 공지사항 또는 유료서비스 운영정책의 내용이 우선합니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 4장 - 10조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 10조 서비스 제공의 중지</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부의 제공을 중지할 수
                                있습니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. 기간통신사업자/인터넷망사업자가 서비스를 중지했을 경우
                                </GeneralText>
                                <GeneralText {...abcListStyle}>b. 정전으로 서비스 제공이 불가능할 경우</GeneralText>
                                <GeneralText {...abcListStyle}>
                                    c. 설비의 이전, 보수 또는 공사로 인해 부득이한 경우
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    d. 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 어려운
                                    경우
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    e. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가
                                    있는 경우
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    f. 회사가 서비스 제공에 이용하는 외부 서비스 업체 측 사유로 정상적인 서비스 제공이
                                    어려운 경우
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스
                                제공 화면에 공지한 바에 따릅니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사는 서비스가 불안정하거나 시급한 개선이 필요한 경우 긴급점검을 실시할 수 있으며,
                                이 경우 점검 시간을 서비스 내에 공지합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 회사는 상당한 이유 없이 서비스의 제공이 일시적으로 중단됨으로 인하여 회원이 입은
                                손해에 대하여 배상합니다. 다만, 본 조 제1항 각 호의 사유에 의하여 서비스가 중단된 경우
                                또는 서비스 중단에 대하여 회사가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
                                아니합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 회사는 영업양도∙분할∙합병 등에 따른 영업의 폐지, 콘텐츠 제공의 계약만료, 당해
                                서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운
                                경우에는 서비스를 중단할 수 있습니다. 이 경우 중단일자 및 사유를 서비스 제공 화면을 통해
                                공지하고 회원에게 통지합니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 4장 - 11조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 11조 회원 탈퇴 및 자격 상실</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회원은 회사에 언제든지 이용계약의 해지(탈퇴)를 요청할 수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 회원의 탈퇴 요청을 즉시 처리하되, 회원이 유료서비스를 신청하여 이용하던 중
                                회원 탈퇴를 요청할 경우 아래와 같이 회원 탈퇴 처리를 유예할 수 있습니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. 회원이 유료서비스 결제 후 다음 결제일까지 한 번도 이용한 기록이 없다면, 즉시 해지
                                    및 탈퇴처리 합니다.
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    b. 회원이 유료서비스를 이용한 기록이 있다면, 다음 번 결제일에 탈퇴처리 합니다.
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                3. 회사는 회원이 다음 각호의 사유에 해당하는 경우, 회원자격을 제한 및 정지시키거나
                                이용계약을 해지할 수 있습니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>a. 가입신청 시에 허위내용을 등록한 경우</GeneralText>
                                <GeneralText {...abcListStyle}>
                                    b. 회사의 서비스 이용대금, 기타 회사의 서비스 이용에 관련하여 회원이 부담하는 채무를
                                    기일에 이행하지 않은 경우
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    c. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를
                                    위협하는 경우
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    d. 서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는
                                    경우
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                4. 회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의 서비스 이용을 정지할
                                수 있습니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. 계정이 해킹 또는 도용당했거나, 범죄에 이용되었다는 정당한 신고가 접수된 경우
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    b. 그 밖에 이에 준하는 사유로 서비스 이용의 잠정 조치가 필요한 경우
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                5. 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를
                                통지하고, 회원등록 말소 전에 기간을 정하여 소명할 기회를 부여합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                6. 회원이 이 약관을 위반하여 회사가 본 조에 따라 제재 조치를 가한 경우, 회사는 회원에게
                                서비스를 이용하지 못하게 됨으로 인하여 발생하는 손해를 배상하지 아니하고, 회원의
                                유료서비스 이용과 관련하여 일체의 환불 또는 보상을 하지 아니합니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 4장 - 12조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 12조 정보의 제공</TitleText>
                        <GeneralText {...explainStyle}>
                            회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 이메일 등의 방법으로
                            회원에게 정보를 제공할 수 있습니다. 단 광고성 정보에 해당하는 경우 회원으로부터 수신 동의를
                            받습니다
                        </GeneralText>
                    </JoWrap>
                    {/* 4장 - 13조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 13조 광고게재</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 이메일 등에 광고 등을 게재할
                                수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에
                                연결될 수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 제 2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우, 해당 영역에서 제공하는
                                서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로
                                인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 4장 - 14조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 14조 게시물 관리</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 불법 여부 또는 AI 콘텐츠 윤리 위반 여부를 판단하기 위해 콘텐츠를 검토할 수
                                있으며 회사의 정책 또는 법규를 위반한다고 합리적으로 판단되는 콘텐츠는 삭제하거나 게시를
                                거부할 수 있습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 4장 - 15조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 15조 저작권 등의 귀속 등</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회원이 콘텐츠와 관련하여 보유하고 있는 모든 지적 재산권의 소유권은 유지됩니다. 즉,
                                회원이 보유한 콘텐츠는 회원에게 존속됩니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 서비스 특성상 회원이 서비스가 제공하는 기능을 사용하여, 서비스상 작성된
                                결과물이나 결과물 작성 과정에 노출된 각종 정보가 타인의 저작권과 기타 지적재산권을
                                침해하지 않음을 보증하지 않습니다. 회원이 서비스가 제공하는 기능을 사용하여, 서비스상
                                작성된 결과물의 사용으로 인한 책임은 회원에게 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사는 회원이 서비스 내에 게시한 게시글 및 회원이 서비스가 제공하는 기능을 사용하여,
                                서비스상 작성된 결과물을 서비스 내 노출, 서비스 홍보를 위한 활용, 서비스 운영, 개선 및
                                새로운 서비스 개발을 위한 연구 목적을 위하여 저장, 복제, 수정, 공중 송신, 전시, 배포,
                                2차적 저작물 작성의 방식으로 이용할 수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 회사는 서비스 이용 약관 및 개인정보처리방침에 설명된 경우를 제외하고는 귀하의 파일 및
                                데이터를 다른 사람과 공유하지 않습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 회원은 자신이 서비스 이용과정에서 입력하는 글, 이미지 기타 자료가 제3자의 지적재산권
                                기타 권리를 침해하지 않는다는 점을 보증합니다. 회원이 이를 위반하여 제3자와 회원 또는
                                회사 사이에 소송, 이의제기, 권리주장 기타 일체의 분쟁이 발생하는 경우, 회원은 회사를
                                면책하고 해당 분쟁을 해결하여야 하며, 이로 인해 회사가 손해를 입은 경우 해당 손해를
                                배상하여야 합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                6. 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로
                                적용됩니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                {/* 5장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 5장 재화의 주문 및 결제 관련
                    </TitleText>
                    {/* 5장 - 16조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 16조 대금결제</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회원은 유료서비스에 대한 “구독(하기)”, “구매(하기)”, “결제(하기)”, “(결제)확인” 등의
                                버튼을 클릭함으로써 본 이용약관 및 게시된 구매조건에 따라 유료서비스 이용계약이
                                성립하고, 이용요금이 결제됩니다. 대금의 부과와 납부는 원칙적으로 회원이 선택한
                                결제수단이 정하는 정책이나 방법에 따릅니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회원은 유료서비스에 대하여 선불카드, 직불카드, 신용카드 등의 각종 카드 결제 수단을
                                이용하여 결제할 수 있습니다. 이때 ‘회사’는 회원의 지급방법에 대하여 유료서비스 외 어떠한
                                명목의 수수료를 추가 징수하지 않습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사가 서비스 내 달리 공지하지 않는 한 회원이 대금을 결제한 때로부터 유료서비스는
                                개시됩니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 회원이 등록한 결제수단을 통하여 1개월을 주기로 이용요금이 자동으로 결제되며, 다음 달
                                동일한 날짜에 자동으로 결제되고 이용기간이 자동 갱신됩니다. 단, 결제 날짜가 특정 월에
                                포함되지 않은 날짜로 지정된 경우 해당 월의 마지막 날에 결제됩니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 회사는 프로모션 등의 목적으로 1개월 무료 이용 쿠폰을 회원에게 지급할 수 있으며,
                                회원이 쿠폰을 사용할 경우 다음 결제일이 1개월 연장됩니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 5장 - 17조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 17조 청약철회 등</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사와 유료서비스의 구매에 관한 계약을 체결한 회원은 언제든 청약 철회를 신청할 수
                                있습니다. 회사는 회원이 해당 유료서비스를 사용했는지에 따라 아래와 같이 처리합니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. 결제일부터 유료서비스를 전혀 사용하지 아니하였을 경우 즉시 청약철회를 처리합니다.
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    b. 결제일부터 현재까지 유료서비스 이용 기록이 있을 경우, 회사는 회원의 청약철회를
                                    다음 결제일에 처리합니다.
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                2. 기타 본 조에서 정하지 아니한 청약철회 또는 중도해지 등 사유로 인한 유료서비스
                                이용요금 환불 등에 관한 사항은 회사의 유료서비스 공지사항 또는 별도의 유료서비스
                                운영정책에 따릅니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 5장 - 18조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 18조 과오납금의 환급</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사는 과오납금이 발생하는 경우 과오납금을 회원에게 환급합니다. 다만, 과오납금이
                                회사의 고의 또는 과실 없이 회원의 과실로 인하여 발생한 경우에는 그 환급에 소요되는 실제
                                비용은 합리적인 범위 내에서 회원이 부담합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 회원이 선택한 결제수단에 따라 결제를 취소 또는 해당 결제수단을 통한 환급을 할
                                수 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사는 과오납금의 환급을 처리하기 위해 회원에게서 제공받은 정보를 통해 회원에게
                                연락할 수 있으며, 필요한 정보의 제공을 요청할 수 있습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                {/* 6장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 6장 AI 윤리에 관한 특약
                    </TitleText>
                    {/* 6장 - 19조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 19조 서비스 사용자의 의무</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회원은 서비스를 악의적인 목적으로 사용할 수 없습니다. 또한 회원은 악의적인 사용을
                                통해 회사와 서비스에 대한 평판 위험을 발생시켜서는 아니됩니다. 악의적 사용이란 서비스를
                                사용하는 과정에서 고의적인 입력값을 통해 부당한 차별적 의견 또는 부당한 가치 판단 등
                                편향적인 결과값을 발생시키는 것이 대표적이며, 그 밖에도 회원이 악의적으로 서비스를
                                사용하여 발생시키는 문제를 포함합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회원은 서비스 이용 과정에서 발생한 AI 결과값(이하 ‘AI 결과값’)을 SNS 서비스 및 기타
                                방법에 의해 외부에 공개하며 제3자에게 알리는 경우 출력 데이터에 저작권 침해, 폭력적
                                표현, 선정적 표현, 차별적 표현, 기타 부적절하거나 제3자에게 기타 권리침해, 불이익, 손해,
                                불쾌감을 주는 정보가 포함되어 있지 않음을 반드시 확인해야합니다. AI 결과값과 관련하여
                                법적∙윤리적 문제가 발생하거나, 제3자와 일체의 분쟁이 발생하는 경우 이에 대한 모든 책임은
                                회원에게 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회원은 서비스를 사용하며 발생한 ‘문제가 되거나 문제가 될 수 있다고 판단한 결과물’에
                                대해서는 발견 즉시 회사에 해당 내용을 알려야 합니다. 이를 통해 회원은 회사가 관련 부분을
                                개선할 수 있도록, 이에 대해 적극적으로 협조할 의무가 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. 본 규정을 준수하지 않은 회원은 제4장에 따라 별도의 공지 없이 이용이 정지되거나 제한될
                                수 있으며, 회사는 이에 관한 책임을 부담하지 않습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 회원이 AI 결과값을 외부에 공개하는 과정에서 회사에 피해를 입힌 경우 그에 대한 법률상
                                책임을 부담합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                6. 회원은 입력값과 결과값은 더 나은 서비스 제공을 위한 서비스 엔진 개선에 활용될 수
                                있으며 모델 평가에 사용될 수 있습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                {/* 7장 */}
                <JangWrap>
                    <TitleText size={"xlarge"} margin={"0 0 60px 0"} fontWeight={"bold"} textAlign={"center"}>
                        제 7장 기타
                    </TitleText>
                    {/* 7장 - 20조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 20조 면책 및 손해배상</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할
                                책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니 합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사는 아래 각 호의 사유로 인하여 회원에게 발생한 손해에 대하여 책임을 지지 않습니다.
                                다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
                            </GeneralText>
                            <AbcListWrap>
                                <GeneralText {...abcListStyle}>
                                    a. 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 서비스
                                    이용이 불가한 경우(다만 회사의 고의 또는 과실에 의한 경우는 제외)
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    b. 회원의 고의 또는 과실로 인한 서비스 이용의 장애
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    c. 다른 회원이 게시한 게시글에 신뢰성·정확성이 부족한 경우
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    d. 회원간 또는 회원과 타인간 서비스를 매개로 발생한 거래나 분쟁
                                </GeneralText>
                                <GeneralText {...abcListStyle}>e. 무료로 제공되는 서비스 이용</GeneralText>
                                <GeneralText {...abcListStyle}>
                                    f. 회원이 서비스를 이용을 통하여 기대하는 유익이 실현되지 않은 경우
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    g. 회원이 계정 비밀번호 등을 관리하지 않아 회원정보의 유출이 발생한 경우(다만 회사의
                                    고의 또는 중과실에 의한 경우는 제외)
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    h. 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우(다만 회사의 고의 또는
                                    중과실에 의한 경우는 제외)
                                </GeneralText>
                                <GeneralText {...abcListStyle}>
                                    i. 서버에 대한 제3자의 불법적인 접속 또는 서버의 불법적인 이용 및 프로그램을 이용한
                                    비정상적인 접속 방해(다만 회사의 고의 또는 중과실에 의한 경우는 제외)
                                </GeneralText>
                                <GeneralText size={"large"} margin={"0 0 24px 0"}>
                                    j. 기타 회사의 귀책사유가 인정되지 않는 사유로써 위 각 호에 준하는 사유
                                </GeneralText>
                            </AbcListWrap>
                            <GeneralText {...numberListStyle}>
                                3. 회사는 AI 결과값의 적법성, 독창성, 배타성, 신뢰도, 정확성, 진실성, 활용 가능성, 특정
                                목적에의 적합성을 보장하지 아니합니다. 회사는 회원이 서비스를 이용하여 기대하는 수익을
                                얻지 못한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 결과물로 인한
                                손해에 관하여 책임을 지지 않습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                4. AI 결과값의 이용과 관련하여 발생하는 모든 문제에 대한 책임은 회원에게 있으며, 회사는
                                이에 대하여 일체의 책임을 지지 아니합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                5. 회사는 회원이 게시판에 게재한 정보, 자료, 내용 등에 관하여 사실의 정확성, 신뢰도 등에
                                어떠한 책임도 부담하지 않으며 회원은 본인의 책임 아래 서비스를 이용해야 합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                6. 회사는 회원이 서비스와 관련하여 게재하였거나, 서비스 내에서 검색 또는 추천된
                                정보자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                7. 회사는 회원이 서비스 이용과 관련하여 회원에게 발생한 손해 중 회원의 고의, 과실에 의한
                                손해 및 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                8. 회원이 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택과 관련해
                                어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                9. 회원이 이 약관의 규정을 위반함으로써 회사에 손해가 발생하는 경우 이 약관을 위반한
                                회원은 회사에 발생한 모든 손해를 배상해야 하며 동 손해로부터 회사를 면책시켜야 합니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 7장 - 21조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 21조 회원에 대한 통지</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 회사가 회원에 대한 통지를 하는 경우, 회원이 서비스에 등록한 이메일을 통해 통지합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사가 불특정다수 회원에 대한 통지의 경우 7일이상 홈페이지에 게시함으로써 개별 통지에
                                갈음할 수 있습니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                    {/* 7장 - 22조 */}
                    <JoWrap>
                        <TitleText {...titleStyle}>제 22조 재판권 및 준거법</TitleText>
                        <NumberListWrap>
                            <GeneralText {...numberListStyle}>
                                1. 이 약관은 대한민국 법률에 따라 규율되고 해석됩니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                2. 회사와 회원은 본 서비스 이용과 관련해 발생한 분쟁을 원만하게 해결하기 위하여 필요한
                                모든 노력을 해야 합니다.
                            </GeneralText>
                            <GeneralText {...numberListStyle}>
                                3. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 민사소송법에 따른 법원을
                                관할 법원으로 합니다.
                            </GeneralText>
                        </NumberListWrap>
                    </JoWrap>
                </JangWrap>
                <JoWrap>
                    <TitleText {...titleStyle}>[부칙]</TitleText>
                    <TitleText {...titleStyle}>프렙 서비스 이용약관은 2023년 7월 1일부터 적용됩니다.</TitleText>
                </JoWrap>
            </BodyTextWrap>
        </GuestUserSingleCardLayout>
    );
}

export default TermsOfServicePage;
