import React from "react";

import styled, { css } from "styled-components";

const Size = css`
    ${(props) =>
        props.size === "xsmall" &&
        css`
            font-size: 1.1rem;
        `}

    ${(props) =>
        props.size === "small" &&
        css`
            font-size: 1.2rem;
        `}

    ${(props) =>
        props.size === "regular" &&
        css`
            font-size: 1.3rem;
        `}

    ${(props) =>
        props.size === "large" &&
        css`
            font-size: 1.4rem;
        `}

    ${(props) =>
        props.size === "xlarge" &&
        css`
            font-size: 1.5rem;
        `}

    ${(props) =>
        props.size === "landing" &&
        css`
            font-size: 1.8rem;
        `}
`;

const Text = styled.p`
    display: ${(props) => props.display};
    margin: ${(props) => props.margin || "0"};
    width: ${(props) => props.width};
    font-weight: ${(props) => props.fontWeight || "400"};
    line-height: 1.4;
    color: ${(props) => props.color || "var(--color-SubBlack)"};
    text-align: ${(props) => props.textAlign};
    transition: ${(props) => props.transition};
    ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}

    ${Size};

    ${(props) =>
        props.ellipsis &&
        css`
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        `}
`;

function GeneralText(props) {
    return (
        <Text
            ellipsis={props.ellipsis}
            display={props.display}
            margin={props.margin}
            width={props.width}
            color={props.color}
            fontWeight={props.fontWeight}
            textAlign={props.textAlign}
            transition={props.transition}
            size={props.size}
            whiteSpace={props.whiteSpace}
            className={props.className}
            {...props}
        >
            {props.children}
        </Text>
    );
}

export default GeneralText;
