import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import styled from "styled-components";

import GoogleIconImage from "assets/png/others/GoogleIconImage.png";

import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import FormInput from "components/molecules/FormInput";
import GuestUserSingleCardLayout from "components/templates/GuestUserSingleCardLayout";

import { encrypt } from "utils/crypto";
import { signUpApi } from "utils/request/auth";
import { apiUrl } from "utils/urls";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const ContentsWrap = styled.div`
    width: 100%;
    height: 100%;
    // max-height: calc(100% - 80px);
    overflow: auto;
`;

const AllSelectButton = styled.button`
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    padding: 10px 12px;
    width: 100%;
    height: 52px;
    background-color: ${(props) => (props.active ? "var(--color-Sub)" : "var(--color-Base1)")};
    border-radius: 4px;
    border: solid 2px ${(props) => (props.active ? "var(--color-Black)" : "var(--color-Outline)")};
    transition: all 120ms ease-in;

    &:hover {
        border: solid 2px ${(props) => (props.active ? "var(--color-Black)" : "var(--color-Sub)")};
    }
`;

const CheckWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 32px;
    height: 32px;
`;

const TermsItem = styled.div`
    display: flex;
    align-items: center;
    margin: ${(props) => props.margin || "0 12px 8px 12px"};
    height: 32px;
    width: calc(100% - 24px);
`;

const MoreButton = styled.button`
    margin: 0 0 0 8px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-Grey1);
    background-color: var(--color-White);
    border: transparent;
    border-bottom: solid 1px var(--color-Grey1);
    transition: all 50ms ease-in;

    &:hover {
        color: var(--color-Key);
        border-bottom: solid 1px var(--color-Key);
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 32px 0;
    width: 100%;
`;

const DivideWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
`;

const DivideLine = styled.div`
    flex: 1;
    height: 1px;
    border-top: solid 1px var(--color-Grey2);
`;

const LoginWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0 40px 0;
`;

const ImgWrap = styled.img`
    width: 100%;
`;

const termTextStyle = {
    size: "large",
    margin: "0 0 0 16px",
    width: "calc(100% - 78px)",
    fontWeight: "500",
    color: "var(--color-Black)",
};

const TextButtonStyle = {
    width: "fit-content",
    height: "fit-content",
    bgColor: "transparent",
    hoverBgColor: "transparent",
    fontSize: "small",
    fontColor: "var(--color-Grey1)",
    textAlign: "left",
    hoverTextStyle: "color: var(--color-Key); text-decoration: underline;",
};

function SignUpPage() {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [confirmCode, setConfirmCode] = useState("");
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState({});
    const [allSelected, setAllSelected] = useState(false);
    const [termsSelected, setTermsSelected] = useState([false, false, false]);
    const [countDown, setCountDown] = useState(0);
    const [signUpButtonActive, setSignUpButtonActive] = useState(false);
    const [confirmInterval, setConfirmInterval] = useState(null);

    const { setLoading } = useLoading();

    useEffect(() => {
        // 랜딩페이지 하단까지 스크롤 후 이 페이지에 진입하면 스크롤 위치가 하단에 남아있어서 수동으로 스크롤 위치를 올려줌
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    useEffect(() => {
        if (emailConfirmed) {
            setCountDown(0);
            clearInterval(confirmInterval);
        }
    }, [emailConfirmed]);

    const handleAllSelect = () => {
        if (allSelected) {
            setTermsSelected([false, false, false]);
        } else {
            setTermsSelected([true, true, true]);
        }
        setAllSelected(!allSelected);
    };

    const handleTermSelect = (index) => {
        const updatedTerms = [...termsSelected];
        updatedTerms[index] = !updatedTerms[index];
        setTermsSelected(updatedTerms);

        if (updatedTerms.every((term) => term)) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    };

    const signUpMutation = useMutation(signUpApi, {
        onSuccess: (data) => {
            openWelcomePopup();

            let email = data.email;
            let encryptedEmail = encrypt(email);
            localStorage.setItem("email", encryptedEmail);
            localStorage.setItem("token", data.token);
            window.location = "/reference";
        },
        onError: (e) => {
            if (e.response.status === 400) {
                const res = e.response.data;
                setError(res);
                if (Object.keys(res).includes("non_field_errors")) {
                    Alert("warn", "에러", res["non_field_errors"]);
                }
            } else {
                Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
            }
        },
    });

    const signUp = () => {
        signUpMutation.mutate({
            email: email,
            password: password,
            confirm_password: confirmPassword,
            is_marketing_agreed: termsSelected[2],
        });
    };

    const getEmailConfirmCode = () => {
        setLoading(true);
        axios
            .post(apiUrl("emailConfirm"), { email: email })
            .then((res) => {
                setLoading(false);
                if (res.status === 204) {
                    Alert("info", "이메일 인증", "입력하신 이메일 주소로 인증번호를 발송했습니다.");
                    setEmailSent(true);
                }
            })
            .catch((e) => {
                setLoading(false);
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    const verifyEmailConfirmCode = () => {
        axios
            .get(apiUrl("emailConfirm"), {
                params: { email: email, confirm_code: confirmCode },
            })
            .then((res) => {
                if (res.status === 204) {
                    Alert("info", "이메일 인증", "인증이 완료되었습니다.");
                    setEmailConfirmed(true);
                }
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                    setEmailConfirmed(false);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    useEffect(() => {
        setConfirmCode("");
        setEmailSent(false);
        setEmailConfirmed(false);
    }, [email]);

    const validationHandler = (name, value) => {
        if (name === "email") {
            let regex = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
            if (!value) {
                setError({ ...error, email: "이메일을 입력해주세요." });
                return;
            } else if (!regex.test(value)) {
                setError({ ...error, email: "이메일 형식이 올바르지 않습니다." });
                return;
            } else {
                setError({ ...error, email: undefined });
            }
        } else if (name === "password") {
            let enPattern = /[a-zA-Z]/;
            let numPattern = /[0-9]/;
            if (!value) {
                setError({ ...error, password: "비밀번호를 입력해주세요." });
                return;
            }
            if (value.length < 6) {
                setError({ ...error, password: "비밀번호는 6자 이상이어야 합니다." });
                return;
            }
            if (!enPattern.test(value) || !numPattern.test(value)) {
                setError({
                    ...error,
                    password: "비밀번호는 영문과 숫자를 포함해야 합니다.",
                });
                return;
            }
            if (value !== confirmPassword && confirmPassword) {
                setError({
                    ...error,
                    confirmPassword: "비밀번호가 일치하지 않습니다.",
                    password: undefined,
                });
                return;
            }
            setError({ ...error, password: undefined, confirmPassword: undefined });
        } else if (name === "confirmPassword") {
            if (value !== "" && value !== password) {
                setError({
                    ...error,
                    confirmPassword: "비밀번호가 일치하지 않습니다.",
                });
                return;
            } else {
                setError({ ...error, confirmPassword: undefined });
            }
        }
        return true;
    };

    useEffect(() => {
        if (emailSent) {
            let endTime = +new Date() + 1000 * 300;
            const id = setInterval(() => {
                let msLeft = endTime - +new Date();
                if (msLeft < 0) {
                    clearInterval(id);
                    setCountDown("만료");
                    setEmailSent(false);
                    setConfirmCode("");
                    return;
                } else {
                    let time = new Date(msLeft);
                    let minute = time.getUTCMinutes();
                    let second = ("0" + time.getUTCSeconds()).slice(-2);
                    setCountDown(minute + ":" + second);
                }
            }, 1000);
            setConfirmInterval(id);
            return () => clearInterval(id);
        }
    }, [emailSent]);

    useEffect(() => {
        let passwordValid = validationHandler("password", password);
        let emailValid = validationHandler("email", email);
        let confirmPasswordValid = validationHandler("confirmPassword", confirmPassword);
        if (
            emailValid &&
            emailConfirmed &&
            passwordValid &&
            confirmPasswordValid &&
            termsSelected[0] &&
            termsSelected[1]
        ) {
            setSignUpButtonActive(true);
        } else {
            setSignUpButtonActive(false);
        }
    }, [email, password, confirmPassword, emailConfirmed, termsSelected]);

    const googleSocialLogin = useGoogleLogin({
        clientId: process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_CLIENT_ID,
        scope: "email profile",
        onSuccess: async (data) => {
            axios.post(apiUrl("googleLogin"), { code: data.code }).then((data) => {
                openWelcomePopup();

                let email = data.data.user_email;
                let encryptedEmail = encrypt(email);
                localStorage.setItem("email", encryptedEmail);
                localStorage.setItem("token", data.data.auth_token);
                window.location = "/reference";
            });
        },
        onError: (e) => {
            Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
        },
        flow: "auth-code",
    });

    const googleSignUpValid = () => {
        if (!termsSelected[0] || !termsSelected[1]) {
            Alert("warn", "에러", "약관에 동의 해주세요");
        } else {
            googleSocialLogin();
        }
    };

    const openWelcomePopup = () => {
        const url = "/popup/welcome";
        const width = 400;
        const height = 420;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;
        const features = `width=${width},height=${height},left=${left},top=${top}`;

        window.open(url, "_blank", features, "noopener, noreferrer");
    };

    return (
        <GuestUserSingleCardLayout width={440}>
            <HtmlHead title={"회원가입"} />
            <TitleText size={"large"} margin={"0 0 60px 0"} fontWeight={"bold"}>
                프렙 회원가입
            </TitleText>
            <ContentsWrap>
                <div>
                    <TitleText withBarType size={"small"} margin={"0 0 40px 0"}>
                        약관동의
                    </TitleText>
                    <AllSelectButton active={allSelected} onClick={handleAllSelect}>
                        <CheckWrap>
                            <Icon
                                name={"check"}
                                size={"16"}
                                color={allSelected ? "var(--color-Black)" : "var(--color-Grey2)"}
                            />
                        </CheckWrap>
                        <TitleText size={"regular"} margin={"0 0 0 16px"}>
                            약관 전체 동의하기
                        </TitleText>
                    </AllSelectButton>
                    <TermsItem>
                        <Button
                            onlyIcon
                            buttonSize={"32px"}
                            bgColor={"var(--color-White)"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={() => handleTermSelect(0)}
                        >
                            <Icon
                                name={"check"}
                                size={"14"}
                                color={termsSelected[0] ? "var(--color-Black)" : "var(--color-Grey2)"}
                            />
                        </Button>
                        <GeneralText {...termTextStyle}>(필수) 이용약관</GeneralText>
                        <Link to={"/terms-of-service"} target="_blank">
                            <MoreButton>열기</MoreButton>
                        </Link>
                    </TermsItem>
                    <TermsItem>
                        <Button
                            onlyIcon
                            buttonSize={"32px"}
                            bgColor={"var(--color-White)"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={() => handleTermSelect(1)}
                        >
                            <Icon
                                name={"check"}
                                size={"14"}
                                color={termsSelected[1] ? "var(--color-Black)" : "var(--color-Grey2)"}
                            />
                        </Button>
                        <GeneralText {...termTextStyle}>(필수) 개인정보 수집 및 이용</GeneralText>
                        <Link to={"/privacy-policy"} target="_blank">
                            <MoreButton>열기</MoreButton>
                        </Link>
                    </TermsItem>
                    <TermsItem margin="0 12px 60px 12px">
                        <Button
                            onlyIcon
                            buttonSize={"32px"}
                            bgColor={"var(--color-White)"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={() => handleTermSelect(2)}
                        >
                            <Icon
                                name={"check"}
                                size={"14"}
                                color={termsSelected[2] ? "var(--color-Black)" : "var(--color-Grey2)"}
                            />
                        </Button>
                        <GeneralText {...termTextStyle}>(선택) 마케팅 정보 수신</GeneralText>
                        {/* <MoreButton>열기</MoreButton> */}
                    </TermsItem>
                </div>
                <div>
                    <TitleText withBarType size={"small"} margin={"0 0 40px 0"}>
                        이메일 회원가입
                    </TitleText>
                    <Row>
                        <FormInput
                            label="이메일"
                            margin="0"
                            width="calc(100% - 108px)"
                            placeholder="이메일 주소를 입력해주세요"
                            type="email"
                            className="form-control"
                            maxLength={50}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            name={"email"}
                            onBlur={(e) => validationHandler(e.target.name, e.target.value)}
                            onFocus={(e) => setError({ ...error, email: undefined })}
                            errorMessage={error?.email}
                        />
                        <Button
                            onlyText
                            width={"100px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            fontColor={"var(--color-White)"}
                            buttonText={"인증번호 발송"}
                            disabled={!error.email === undefined}
                            onClick={getEmailConfirmCode}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label="이메일 인증"
                            margin="0"
                            width="calc(100% - 108px)"
                            timerDp="block"
                            placeholder="인증번호를 입력해주세요"
                            type="text"
                            className="form-control"
                            maxLength={50}
                            onChange={(e) => setConfirmCode(e.target.value)}
                            value={confirmCode}
                            disabled={emailConfirmed ? true : !emailSent}
                        >
                            {countDown ? countDown : null}
                        </FormInput>
                        <Button
                            onlyText
                            width={"100px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            fontColor={"var(--color-White)"}
                            buttonText={emailConfirmed ? "인증 완료" : "인증번호 확인"}
                            disabled={emailConfirmed ? true : !confirmCode}
                            onClick={verifyEmailConfirmCode}
                        />
                    </Row>
                    <FormInput
                        label="비밀번호"
                        subMessageDp="block"
                        subMessage="6자리 이상의 영문과 숫자 조합"
                        margin="0 0 32px 0"
                        width="100%"
                        placeholder="비밀번호를 입력해주세요"
                        iconDp="flex"
                        type="password"
                        className="form-control"
                        name={"password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        onBlur={(e) => validationHandler(e.target.name, e.target.value)}
                        onFocus={(e) => setError({ ...error, password: undefined })}
                        errorMessage={error?.password}
                    />
                    <FormInput
                        label="비밀번호 확인"
                        margin="0 0 60px 0"
                        width="100%"
                        placeholder="비밀번호를 다시 한 번 입력해주세요"
                        iconDp="flex"
                        type="password"
                        className="form-control"
                        name={"confirmPassword"}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        onBlur={(e) => validationHandler(e.target.name, e.target.value)}
                        onFocus={(e) => setError({ ...error, confirmPassword: undefined })}
                        errorMessage={error?.confirmPassword}
                    />
                    <Button
                        onlyText
                        bgColor={"var(--color-Button2)"}
                        hoverBgColor={"var(--color-ButtonHover2)"}
                        fontColor="var(--color-White)"
                        buttonText="회원가입"
                        type="submit"
                        onClick={signUp}
                        disabled={!signUpButtonActive}
                    />
                    <DivideWrap>
                        <DivideLine />
                        <GeneralText size={"small"} margin={"0 8px"} color={"var(--color-Grey1)"}>
                            또는
                        </GeneralText>
                        <DivideLine />
                    </DivideWrap>
                    <Button
                        margin="0 0 24px 0"
                        bgColor="var(--color-White)"
                        hoverBgColor={"var(--color-ButtonHover4)"}
                        fontColor="var(--color-Black)"
                        border="solid 1px var(--color-Grey3)"
                        buttonText="구글 아이디로 가입하기"
                        className="btn btn-primary"
                        type="submit"
                        onClick={googleSignUpValid}
                    >
                        <ImgWrap src={GoogleIconImage} />
                    </Button>
                </div>
                <LoginWrap>
                    <GeneralText size={"small"} margin={"0 12px 0 0"} color={"var(--color-Grey1)"}>
                        이미 가입하셨나요?
                    </GeneralText>
                    <Link to={"/login"}>
                        <Button onlyText {...TextButtonStyle} buttonText={"로그인하기"} />
                    </Link>
                </LoginWrap>
            </ContentsWrap>
        </GuestUserSingleCardLayout>
    );
}

export default SignUpPage;
