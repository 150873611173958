import React from "react";

import styled, { css } from "styled-components";

import HtmlHead from "components/atoms/HtmlHead";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import GuestUserSingleCardLayout from "components/templates/GuestUserSingleCardLayout";

import { useDevice } from "utils/device";

const BodyTextWrap = styled.div`
    min-height: calc(100vh - 320px);
    max-height: unset;
    overflow: auto;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            align-items: flex-start;
            max-height: calc(100vh - 320px);
        `};
`;

const JoWrap = styled.div`
    margin: 0 0 80px 0;
`;

const NumberListWrap = styled.div`
    margin: 24px 0 0 24px;
    width: calc(100% - 24px);
`;

const explainStyle = {
    size: "large",
    margin: "24px 0 0 0",
};

const listStyle = {
    size: "large",
    margin: "0 0 16px 0",
};
function PrivacyPolicyPage() {
    const device = useDevice();

    return (
        <GuestUserSingleCardLayout width={840}>
            <HtmlHead title={"개인정보 처리방침"} />
            <TitleText
                size={"xlarge"}
                margin={"0 0 40px 0"}
                fontWeight={"bold"}
                textAlign={device.isTabletOrPC ? "left" : "center"}
            >
                개인정보 처리방침
            </TitleText>
            <BodyTextWrap device={device}>
                <GeneralText {...explainStyle} margin={"0 0 80px 0"}>
                    주식회사 데이탄소프트(이하 ‘회사’)는 이용자의 개인정보 및 권익을 보호하고, 이와 관련한 고충을
                    신속하고 원활하게 처리하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                </GeneralText>
                {/* 1조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 1조 개인정보처리의 목적
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지
                        않습니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 서비스 제공에 따른 본인 식별, 인증, 고객자격 유지, 관리
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공, 구매 및 요금
                            결제, 물품배송 또는 청구지 발송, 금융거래 본인 인증 및 금융 서비스
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            3. 신규 서비스 및 이벤트 광고성 정보 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고
                            게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
                        </GeneralText>
                    </NumberListWrap>
                </JoWrap>
                {/* 2조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 2조 개인정보의 수집방법 및 수집항목
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 서비스 이용자에 대하여 다음의 개인정보항목을 수집하여 처리하고 있습니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>1. 회원가입 시: 이메일, 암호화된 비밀번호</GeneralText>
                        <GeneralText {...listStyle}>2. 문의요청 시: 이름, 이메일, 소속, 암호화된 연락처</GeneralText>
                        <GeneralText {...listStyle}>
                            3. 서비스 이용 시: 접속 로그, 접속 IP 정보, 서비스 이용 기록, 쿠키
                        </GeneralText>
                        <GeneralText {...listStyle}>4. 서비스 결제 시: 결제기록</GeneralText>
                        <GeneralText {...listStyle}>
                            5. 신규 서비스 및 이벤트 광고성 정보 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고
                            게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
                        </GeneralText>
                    </NumberListWrap>
                    <GeneralText {...explainStyle}>
                        개인정보는 서비스 홈페이지에서 이용자가 직접 제공하거나, 서비스를 이용하는 과정에서 자동 로그
                        기록 장치를 통해 수집됩니다.
                    </GeneralText>
                </JoWrap>
                {/* 3조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 3조 개인정보 수집, 이용, 제공에 대한 동의 및 거부
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 사용자가 최초 회원가입 시 또는 서비스 이용과 관련해 개인정보를 입력할 시 개인정보취급방침
                        및 이용약관등의 내용에 대해 동의 여부를 체크할 수 있는 절차를 마련하여, 이를 체크하면
                        개인정보수집 및 이용에 대해 동의한것으로 취급합니다. 사용자는 위 개인정보의 수집 및 이용을
                        거부할 수 있습니다. 단, 개인정보의 수집 및 이용을 거부할 경우 서비스 일부 또는 전체의 이용이
                        제한될 수 있습니다.
                    </GeneralText>
                    <GeneralText {...explainStyle}>
                        또한 홈페이지에는 구글(Google)에서 제공하는 웹 분석도구인 구글 애널리틱스가 설치되어 있으며, 이
                        도구에서는 사용자 개인을 식별할 수 없도록 비식별화 처리된 정보를 이용합니다. 사용자는 구글
                        애널리틱스 Opt-out Browser Add-on 을 이용하거나, 쿠키 설정 거부를 통해 구글 애널리틱스 이용을
                        거부할 수 있습니다.
                    </GeneralText>
                </JoWrap>
                {/* 4조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 4조 개인정보의 제 3자 제공
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 개인정보를 사용자의 사전 동의 없이 목적 외의 사용을 하거나 제 3자에게 제공하지 않습니다.
                        다만, 아래와 같은 예외 사항이 존재합니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사 기관의
                            요구가 있는 경우
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 회사의 권리와 의무가 합병, 영업 양수 등의 사유로 인해 승계되거나 이전되는 경우. 이 경우
                            관계 법령에 따라 개인정보 이전 사실을 고지합니다.
                        </GeneralText>
                    </NumberListWrap>
                </JoWrap>
                {/* 5조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 5조 개인정보의 보유 및 이용 기간
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        개인정보의 보유 및 이용 기간은 원칙적으로 회원 가입 시부터 회원 탈퇴 시까지 이며, 관련 법령에
                        따라 필요한 정보는 아래와 같이 보유, 이용합니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 표시 및 광고에 대한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 6개월
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 계약 및 청약 철회에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 5년
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            3. 대금결제 및 재화 등의 공급에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 5년
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            4. 소비자의 불만 또는 분쟁처리에 관한 기록 : 전자상거래 등에서의 소비자보호에 관한 법률, 3년
                        </GeneralText>
                        <GeneralText {...listStyle}>5. 전자 금융거래에 대한 기록 : 전자금융거래법, 5년</GeneralText>
                        <GeneralText {...listStyle}>
                            6. 세법 규정에 따른 장부 및 모든 거래와 관련한 서증 : 국세기본법, 5년
                        </GeneralText>
                        <GeneralText {...listStyle}>7. 로그인 기록 및 위치 기록 : 통신비밀보호법, 3개월</GeneralText>
                    </NumberListWrap>
                </JoWrap>
                {/* 6조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 6조 정보주체의 권리, 의무 및 그 행사방법
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        사용자는 개인정보주체로서 개인정보 열람, 정정, 삭제에 관한 권리를 행사할 수 있습니다. 이러한
                        권리 행사는 회사에 서면, 전자우편, 홈페이지를 통해 할 수 있고, 회사는 이러한 요구를 수령할 경우
                        아래의 조치를 지체없이 이행합니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 사용자가 자신의 개인 정보를 조회하거나 수정, 가입 해지를 요청할 경우 홈페이지를 통해 즉시
                            이행합니다.
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 사용자가 가입 해지를 요청한 경우 개인 정보는 법령에서 규정한 기간에 따라 보관 후
                            파기합니다.
                        </GeneralText>
                    </NumberListWrap>
                </JoWrap>
                {/* 7조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 7조 개인정보의 파기
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 사용자의 개인정보를 보호하여 개인정보 유출로 인한 피해가 발생하지 않도록 하기 위하여
                        다음과 같은 방법을 통하여 개인정보를 파기합니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                            삭제합니다.
                        </GeneralText>
                    </NumberListWrap>
                    <GeneralText {...explainStyle}>
                        사용자의 개인정보 보유기간이 경과된 경우, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의
                        종료 등 그 개인정보가 불필요하게 되었을 때에는 내부 방침 및 기타 관련 법령에 의한 정보보호
                        사유에 따라 일정기간 (개인정보의 보유 및 이용 기간 참조) 이내에 그 개인정보를 파기합니다.
                    </GeneralText>
                </JoWrap>
                {/* 8조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 8조 개인정보의 기술적, 관리적 보호 대책
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 사용자들의 개인정보를 취급함에 있어 개인정보의 안전성 확보를 위하여 다음과 같은
                        기술적/관리적 대책을 강구하고 있습니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 비밀번호 암호화 : 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알수 있습니다.
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 해킹 등에 대비한 대책 : 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가
                            유출되거나 훼손되는 것을 막기 위해 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가
                            누출되거나 손상되지 않도록 방지하고 있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를
                            안전하게 전송할 수 있도록 하고 있습니다.
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            3. 취급 직원의 최소화 및 교육: 회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고,
                            담당자에 대한 정기 교육(년 1회)을 통하여 개인정보취급방침의 준수를 항상 강조하고 있습니다.
                        </GeneralText>
                    </NumberListWrap>
                    <GeneralText {...explainStyle}>
                        사용자의 개인정보 보유기간이 경과된 경우, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의
                        종료 등 그 개인정보가 불필요하게 되었을 때에는 내부 방침 및 기타 관련 법령에 의한 정보보호
                        사유에 따라 일정기간(개인정보의 보유 및 이용 기간 참조)이내에 그 개인정보를 파기합니다.
                    </GeneralText>
                </JoWrap>
                {/* 9조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 9조 의견수렴 및 불만처리
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        회사는 사용자와의 원활한 의사소통을 위해 고객센터를 운영하고 있으며 상세사항은 다음과 같습니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>고객센터 email: contact@datansoft.com</GeneralText>
                    </NumberListWrap>
                    <GeneralText {...explainStyle}>
                        기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 대검찰청인터넷범죄수사센터,
                        경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>
                            1. 개인정보침해신고센터 전화: (국번없이) 1301 / 휴대전화: 지역번호+1301
                            (http://www.cyberprivacy.or.kr)
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            2. 대검찰청 인터넷범죄수사센터전화: 02-3480-3600 (http://www.spo.go.kr)
                        </GeneralText>
                        <GeneralText {...listStyle}>
                            3. 경찰청사이버테러대응센터 전화: 02-393-9112 (http://www.netan.go.kr)
                        </GeneralText>
                    </NumberListWrap>
                </JoWrap>
                {/* 10조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 10조 개인정보 관리 책임자 및 담당자의 연락처
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        개인정보 관리책임자는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                        사용자의 불만처리 및 피해구제에 대한 업무를 수행하고 있습니다.
                    </GeneralText>
                    <NumberListWrap>
                        <GeneralText {...listStyle}>개인정보보호 책임자: 명재석 (jsmyung@datansoft.com)</GeneralText>
                    </NumberListWrap>
                </JoWrap>
                {/* 11조 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        제 11조 고지의 의무
                    </TitleText>
                    <GeneralText {...explainStyle}>
                        현 개인정보 취급방침은, 정부 및 회사의 정책 또는 보안기술의 변경에 따라 수정될 수 있으며, 이
                        경우 개정 최소 7일전부터 서비스를 통해 고지합니다.
                    </GeneralText>
                </JoWrap>
                {/* 부칙 */}
                <JoWrap>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        [부칙]
                    </TitleText>
                    <TitleText size={"regular"} fontWeight={"bold"}>
                        프렙 서비스 개인정보처리방침은 7월 1일부터 시행됩니다.
                    </TitleText>
                </JoWrap>
            </BodyTextWrap>
        </GuestUserSingleCardLayout>
    );
}

export default PrivacyPolicyPage;
