import React from "react";
import { confirmAlert } from "react-confirm-alert";

import AbstractAlert from "components/atoms/alert/AbstractAlert";

const Alert = (type, title, message, afterClose) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <AbstractAlert
                    onClose={() => {
                        onClose();
                        if (afterClose) {
                            afterClose();
                        }
                    }}
                    type={type}
                    title={title}
                    message={message}
                />
            );
        },
    });
};

export default Alert;
