import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import Icon from "components/atoms/Icon";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import FormInput from "components/molecules/FormInput";

import { usePaymentPlanSubscriptionHistory } from "hooks/queries/usePayment";
import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { decrypt } from "utils/crypto";
import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    display: ${(props) => (props.isActive ? "block" : "none")};
    padding: 0 0 160px 0;
    width: 100%;
    overflow: auto;
`;

const InnerWrap = styled.div`
    margin: 40px 0 0 0;
    width: 100%;
`;

const Category = styled.div`
    display: flex;
    align-items: center;
    margin: ${(props) => props.margin};
    width: 100%;
    height: 32px;
`;

const CategoryContents = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 32px;
`;

const DivideLine = styled.div`
    margin: 40px 0;
    width: 100%;
    height: 1px;
    background-color: var(--color-Line);
`;

const FoldViewWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const FoldView = styled.div`
    display: ${(props) => (props.isVisible ? "block" : "none")};
    justify-content: flex-end;
    margin: 24px 0 0 0;
    width: 560px;
`;

const SubCategory = styled.div`
    display: flex;
    align-items: center;
    margin: ${(props) => props.margin || "0 0 24px 0"};
    height: 32px;
`;

const SubTitleWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 16px 0 0;
    width: 160px;
`;

const Row = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const ButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

const barTitleStyle = {
    size: "small",
    width: "200px",
    fontWeight: "regular",
};

const pwSubTitleStyle = {
    size: "subSmall",
    fontWeight: "regular",
    color: "var(--color-Grey1)",
};

function TabAccountView(props) {
    const navigate = useNavigate();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordChecked, setPasswordChecked] = useState(false);
    const [user, setUser] = useState({});
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState({});
    const [subscribeHistory, setSubscribeHistory] = useState({});
    const { setLoading } = useLoading();
    const userQuery = useUser();
    const subscribeHistoryQuery = usePaymentPlanSubscriptionHistory();

    useEffect(() => {
        const email = localStorage.getItem("email");
        if (email) {
            const decryptedEmail = decrypt(email);
            setEmail(decryptedEmail);
        }
    }, []);

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
        if (subscribeHistoryQuery.data) {
            setSubscribeHistory(subscribeHistoryQuery.data[subscribeHistoryQuery.data.length - 1]);
        }
    }, [userQuery.data, subscribeHistoryQuery.data]);

    useEffect(() => {
        setPasswordChecked(false);
    }, [password]);

    const prevPasswordCheck = () => {
        setLoading(true, "비밀번호를 확인 중입니다");
        axios
            .post(apiUrl("changePassword"), { password: password }, { params: { commit: "check" } })
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    Alert("info", "확인", "비밀번호를 확인했습니다.");
                    setPasswordChecked(true);
                }
            })
            .catch((e) => {
                setLoading(false);
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    const changePasswordApi = () => {
        setLoading(true, "비밀번호를 변경 중입니다");
        axios
            .post(apiUrl("changePassword"), {
                password: password,
                new_password: newPassword,
                new_password_confirm: newPasswordConfirm,
            })
            .then((res) => {
                setLoading(false);
                if (res.status === 204) {
                    Alert("info", "변경", "비밀번호가 변경되었습니다.");
                    setPasswordChecked(false);
                    setPassword("");
                    setNewPassword("");
                    setNewPasswordConfirm("");
                    setIsPasswordVisible(false);
                }
            })
            .catch((e) => {
                setLoading(false);
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    const changeMarketingAgreedApi = (is_marketing_agreed) => {
        axios
            .post(apiUrl("changeMarketing"), {
                is_marketing_agreed: is_marketing_agreed,
            })
            .then((res) => {
                if (res.status === 204) {
                    userQuery.refetch();
                }
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    const unregisterApi = () => {
        if (user.plan_id === Constants.FREE_PLAN) {
            Confirm(
                "info",
                "탈퇴",
                "탈퇴하시겠습니까? 가입 기록 외에 모든 데이터가 삭제되고, 재가입 시 무료 쿠폰을 받을 수 없습니다.",
                "탈퇴",
                (e) => {
                    callUnregisterApi();
                },
            );
        } else {
            Confirm(
                "info",
                "탈퇴",
                "탈퇴하시겠습니까? 가입 기록 외에 모든 데이터가 삭제되고, 재가입 시 무료 쿠폰을 받을 수 없습니다.\n현재 구독 요금은 이용 기간을 제외하고 환불됩니다.",
                "탈퇴",
                (e) => {
                    callUnregisterApi();
                },
            );
        }
    };

    const callUnregisterApi = () => {
        axios
            .post(apiUrl("unregister"))
            .then((res) => {
                if (res.status === 204) {
                    Alert("info", "탈퇴", "탈퇴되었습니다.");
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    userQuery.remove();
                    navigate("/");
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            })
            .catch((e) => {
                if (e.response.status === 400) {
                    Alert("warn", "에러", e.response.data.error);
                } else {
                    Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
                }
            });
    };

    const validationHandler = (e) => {
        if (e.target.name === "newPassword") {
            setNewPassword(e.target.value);
            let enPattern = /[a-zA-Z]/;
            let numPattern = /[0-9]/;
            if (!e.target.value) {
                setError({ ...error, newPassword: "비밀번호를 입력해주세요." });
                return;
            }
            if (e.target.value.length < 6) {
                setError({
                    ...error,
                    newPassword: "비밀번호는 6자 이상이어야 합니다.",
                });
                return;
            }
            if (!enPattern.test(e.target.value) || !numPattern.test(e.target.value)) {
                setError({
                    ...error,
                    newPassword: "비밀번호는 영문과 숫자를 포함해야 합니다.",
                });
                return;
            }
            if (e.target.value !== newPasswordConfirm && newPasswordConfirm) {
                setError({
                    ...error,
                    newPasswordConfirm: "비밀번호가 일치하지 않습니다.",
                    newPassword: undefined,
                });
                return;
            }
            setError({
                ...error,
                newPassword: undefined,
                newPasswordConfirm: undefined,
            });
        } else if (e.target.name === "newPasswordConfirm") {
            setNewPasswordConfirm(e.target.value);
            if (e.target.value !== newPassword) {
                setError({
                    ...error,
                    newPasswordConfirm: "비밀번호가 일치하지 않습니다.",
                });
                return;
            } else {
                setError({ ...error, newPasswordConfirm: undefined });
            }
        }
        return true;
    };

    return (
        <Container isActive={props.isActive}>
            <InnerWrap>
                {/* 이메일 주소 */}
                <Category margin="0 0 24px 0">
                    <TitleText withBarType {...barTitleStyle}>
                        이메일 주소
                    </TitleText>
                    <CategoryContents>
                        <GeneralText size={"large"} color={"var(--color-Black)"}>
                            {email}
                        </GeneralText>
                    </CategoryContents>
                </Category>
                {/* 비밀번호 변경 */}
                <Category>
                    <TitleText withBarType {...barTitleStyle}>
                        비밀번호 변경
                    </TitleText>
                    <CategoryContents>
                        <Button
                            onlyText={!isPasswordVisible}
                            onClick={(e) => setIsPasswordVisible(!isPasswordVisible)}
                            width={"140px"}
                            height={"32px"}
                            bgColor={isPasswordVisible ? "var(--color-White)" : "var(--color-Button2)"}
                            hoverBgColor={isPasswordVisible ? "none" : "var(--color-ButtonHover2)"}
                            border={isPasswordVisible ? "solid 1px var(--color-Button2)" : "transparent"}
                            fontColor={isPasswordVisible ? "var(--color-Black)" : "var(--color-White)"}
                            buttonText={"비밀번호 변경하기"}
                        >
                            {isPasswordVisible && <Icon name={"x"} size={"8"} />}
                        </Button>
                    </CategoryContents>
                </Category>
                <FoldViewWrap>
                    <FoldView isVisible={isPasswordVisible}>
                        <form>
                            {" "}
                            {/* 콘솔 에러 제거: password 는 form 안에 있어야함 */}
                            <SubCategory>
                                <SubTitleWrap>
                                    <TitleText {...pwSubTitleStyle}>기존 비밀번호</TitleText>
                                </SubTitleWrap>
                                <Row>
                                    <FormInput
                                        labelDp="none"
                                        height={32}
                                        placeholder="본인 확인을 위해 기존 비밀번호를 입력해주세요."
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        disabled={passwordChecked}
                                    />
                                    <Button
                                        onlyIcon
                                        margin={"0 0 0 4px"}
                                        buttonSize={"32px"}
                                        bgColor={"var(--color-Button1)"}
                                        hoverBgColor={"var(--color-ButtonHover1)"}
                                        onClick={prevPasswordCheck}
                                        disabled={passwordChecked}
                                    >
                                        <Icon name={"check"} size={"12"} color={"var(--color-White)"} />
                                    </Button>
                                </Row>
                            </SubCategory>
                            <SubCategory>
                                <SubTitleWrap>
                                    <TitleText {...pwSubTitleStyle}>새 비밀번호</TitleText>
                                </SubTitleWrap>
                                <Row>
                                    <FormInput
                                        labelDp="none"
                                        height={32}
                                        placeholder="새 비밀번호를 입력해주세요."
                                        type="password"
                                        disabled={!passwordChecked}
                                        name={"newPassword"}
                                        errorMessage={error?.newPassword}
                                        value={newPassword}
                                        onChange={validationHandler}
                                    />
                                </Row>
                            </SubCategory>
                            <SubCategory margin="0 0 40px 0">
                                <SubTitleWrap>
                                    <TitleText {...pwSubTitleStyle}>새 비밀번호 확인</TitleText>
                                </SubTitleWrap>
                                <Row>
                                    <FormInput
                                        labelDp="none"
                                        height={32}
                                        placeholder="새 비밀번호를 다시 입력해주세요."
                                        type="password"
                                        disabled={!passwordChecked}
                                        name={"newPasswordConfirm"}
                                        errorMessage={error?.newPasswordConfirm}
                                        value={newPasswordConfirm}
                                        onChange={validationHandler}
                                    />
                                </Row>
                            </SubCategory>
                        </form>
                        <ButtonSection>
                            <Button
                                onlyText
                                width={"80px"}
                                height={"32px"}
                                fontColor={"var(--color-White)"}
                                buttonText={"취소"}
                                onClick={(e) => setIsPasswordVisible(false)}
                            />
                            <Button
                                onlyText
                                margin={"0 0 0 4px"}
                                width={"80px"}
                                height={"32px"}
                                bgColor={"var(--color-Button2)"}
                                hoverBgColor={"var(--color-ButtonHover2)"}
                                fontColor={"var(--color-White)"}
                                buttonText={"확인"}
                                onClick={changePasswordApi}
                                disabled={
                                    !passwordChecked ||
                                    error?.newPassword ||
                                    error?.newPasswordConfirm ||
                                    !("newPassword" in error) ||
                                    !("newPasswordConfirm" in error)
                                }
                            />
                        </ButtonSection>
                    </FoldView>
                </FoldViewWrap>
                <Category margin="24px 0 0 0">
                    <TitleText withBarType {...barTitleStyle}>
                        마케팅 정보 수신 동의
                    </TitleText>
                    <CategoryContents>
                        <GeneralText size={"regular"} margin={"0 8px 0 0"}>
                            수신에 동의합니다.
                        </GeneralText>
                        <Checkbox
                            size={"20px"}
                            margin="0"
                            checked={user.is_marketing_agreed === undefined ? false : user.is_marketing_agreed}
                            onChange={(e) => changeMarketingAgreedApi(e.target.checked)}
                        />
                    </CategoryContents>
                </Category>
            </InnerWrap>
            <DivideLine />
            <InnerWrap>
                {/* 탈퇴하기 */}
                <Category>
                    <TitleText withBarType {...barTitleStyle}>
                        탈퇴하기
                    </TitleText>
                    {/* 회원탈퇴 동작 실행후 버튼은 disabled 처리 되고 WithDrawalText가 나타나야함 */}
                    <CategoryContents>
                        <Button
                            onlyText
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            width={"120px"}
                            height={"32px"}
                            fontColor={"var(--color-White)"}
                            buttonText={"탈퇴하기"}
                            onClick={unregisterApi}
                        />
                    </CategoryContents>
                </Category>
            </InnerWrap>
        </Container>
    );
}

export default TabAccountView;
