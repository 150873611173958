import { useQuery } from "react-query";

import { userCheck } from "utils/request/auth";

export const useUser = (params) => {
    return useQuery("user", (e) => userCheck(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};
