import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import AiCursor from "components/atoms/AiCursor";
import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import TitleText from "components/atoms/text/TitleText";

const ResponseContainer = styled.div`
    position: fixed;
    background-color: var(--color-White);
    border-radius: 8px;
    padding: 16px;
    width: 700px;
    z-index: 101;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    left: 50%;
    transform: translateX(-50%);
`;

const ResponseHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const LeftAlignWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const RightAlignWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AiIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 12px;
    height: 12px;
`;

const AIResponseStopButton = styled.div`
    margin-left: 12px;
    cursor: pointer;
`;

const ResponseBottom = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
`;

const ResponseTextArea = styled.div`
    width: 100%;
    min-height: 8px;
    padding: 8px;
    border: 1px solid var(--color-Line);
    border-radius: 4px;
    resize: none;
    font-size: 13px;
    color: var(--color-SubBlack);
    text-align: justify;
    background-color: var(--color-White);
    height: 160px;
    overflow-y: scroll;
`;

function AIResponseComponent(props) {
    const [response, setResponse] = useState("");
    const responseContainerRef = useRef();
    const [initialY, setInitialY] = useState(0);
    const [initialScrollY, setInitialScrollY] = useState(0);

    useEffect(() => {
        setResponse(props.aiResponse);
    }, [props.aiResponse]);

    // 초기 위치 설정
    useEffect(() => {
        if (responseContainerRef.current) {
            const containerRect = responseContainerRef.current.getBoundingClientRect();
            const newY = props.y;

            const isOverflowingBottom = newY + containerRect.height > window.innerHeight;
            if (!isOverflowingBottom) {
                responseContainerRef.current.style.top = `${newY + 10}px`;
                setInitialY(newY + 10);
            } else {
                responseContainerRef.current.style.top = `${newY - containerRect.height - 10}px`;
                setInitialY(newY - containerRect.height - 10);
            }
            setInitialScrollY(window.scrollY);
        }
    }, [props.y]);

    // 스크롤 및 리사이즈 이벤트 처리
    useEffect(() => {
        const updatePosition = () => {
            if (responseContainerRef.current) {
                const scrollY = window.scrollY;
                const diff = scrollY - initialScrollY;
                const newY = initialY - diff;

                responseContainerRef.current.style.top = `${newY}px`;
            }
        };

        const handleScroll = () => {
            updatePosition();
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", updatePosition);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", updatePosition);
        };
    }, [initialY, initialScrollY]);

    // 외부 클릭을 감지하기 위한 effect
    useEffect(() => {
        function handleClickOutside(event) {
            event.preventDefault();
            const path = event.path || (event.composedPath && event.composedPath());
            const isAlertClick = path.some((element) => {
                if (element?.className) {
                    const className =
                        typeof element.className === "string" ? element.className : element.className.baseVal;
                    return className.includes("alert");
                }
                return false;
            });

            if (responseContainerRef.current && !responseContainerRef.current.contains(event.target) && !isAlertClick) {
                Confirm("info", "알림", "변경사항을 버리시겠습니까?", "버리기", () => {
                    props.setAiResponse({ type: "", text: "" });
                    props.stopAiResponse();
                });
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <ResponseContainer ref={responseContainerRef}>
            <ResponseHeader>
                <LeftAlignWrapper>
                    <AiIconWrap>
                        <Icon name={"aiResponse"} size={"12px"} color={"var(--color-Key)"} />
                    </AiIconWrap>
                    <TitleText size={"subRegular"}>AI 응답</TitleText>
                    {props.aiResponseIng && (
                        <AIResponseStopButton onClick={props.stopAiResponse}>작성 중지</AIResponseStopButton>
                    )}
                </LeftAlignWrapper>
                <RightAlignWrapper>
                    <SelectBox
                        items={[
                            { id: "ko", name: "한국어" },
                            { id: "en", name: "English" },
                        ]}
                        height={"32px"}
                        margin={"0 8px 0 0"}
                        onChange={(value) => {
                            props.changeLanguage(value);
                        }}
                        width={"90px"}
                        className={"ai-response"}
                        value={props.research?.ai_response_language}
                    />
                    <Button
                        onlyText
                        size={"xsmall"}
                        width={"80px"}
                        height={"32px"}
                        bgColor={"var(--color-Button1)"}
                        hoverBgColor={"var(--color-ButtonHover1)"}
                        buttonText={"다시 작성"}
                        onClick={props.rewrite}
                        disabled={props.aiResponseIng}
                    />
                </RightAlignWrapper>
            </ResponseHeader>
            <ResponseTextArea>
                {response}
                {props.aiResponseIng && <AiCursor />}
            </ResponseTextArea>
            <ResponseBottom>
                <ButtonsContainer>
                    <Button
                        onlyText
                        size={"xsmall"}
                        width={"65px"}
                        height={"24px"}
                        bgColor={"var(--color-Button1)"}
                        hoverBgColor={"var(--color-ButtonHover1)"}
                        buttonText={"덮어쓰기"}
                        onClick={() => props.aiResponseHandler("overwrite")}
                        disabled={props.aiResponseIng}
                    />
                    <Button
                        onlyText
                        size={"xsmall"}
                        width={"65px"}
                        height={"24px"}
                        bgColor={"var(--color-Button1)"}
                        hoverBgColor={"var(--color-ButtonHover1)"}
                        buttonText={"추가하기"}
                        onClick={() => props.aiResponseHandler("add")}
                        disabled={props.aiResponseIng}
                    />
                    <Button
                        onlyText
                        size={"xsmall"}
                        width={"47px"}
                        height={"24px"}
                        bgColor={"var(--color-Button2)"}
                        hoverBgColor={"var(--color-ButtonHover2)"}
                        buttonText={"버리기"}
                        onClick={() => props.aiResponseHandler("discard")}
                        disabled={props.aiResponseIng}
                    />
                </ButtonsContainer>
            </ResponseBottom>
        </ResponseContainer>
    );
}

export default AIResponseComponent;
