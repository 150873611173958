import styled from "styled-components";

import LoadingImage from "assets/png/others/LoadingImage.png";

import { useLoading } from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";

const Components = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8px);
    z-index: 1000;
`;

const Box = styled.div`
    box-sizing: border-box;
    position: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    align-items: center;
    min-width: 320px;
    height: auto;
    background-color: var(--color-White);
    border: solid 2px var(--color-Outline);
    border-radius: 16px;
    z-index: 1;
`;

const LoadingImageWrap = styled.div`
    margin: 24px 0 40px 0;
    min-width: 56px;
    min-height: 56px;
    background-image: url(${(props) => props.loadImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const IndicatorWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 40px 0;
    width: 120px;
`;

const Circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: var(--color-DarkKey);
`;

function Loading(props) {
    const { display, text } = useLoading();

    return (
        <>
            {props.children}
            {display && (
                <Components>
                    <Box>
                        <LoadingImageWrap loadImg={LoadingImage} />
                        <GeneralText size={"large"} margin={"0 0 32px 0"} textAling={"center"}>
                            {text}
                        </GeneralText>
                        <IndicatorWrap>
                            <Circle className="circle1" />
                            <Circle className="circle2" />
                            <Circle className="circle3" />
                            <Circle className="circle4" />
                            <Circle className="circle5" />
                        </IndicatorWrap>
                    </Box>
                </Components>
            )}
        </>
    );
}

export default Loading;
