// 권한 관련 공통 에러 핸들러
import Alert from "components/atoms/alert/Alert";

export const permissionErrorHandler = (error, navigate) => {
    if (!error.response) {
        navigate("/error");
        return;
    }
    if (error.response.status === 401) {
        Alert("warn", "에러", "로그인이 필요합니다.");
        navigate("/login");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
    } else if (error.response.status === 403) {
        Alert("warn", "에러", "권한이 없습니다.");
        navigate("/");
    } else if (error.response.status === 404) {
        Alert("warn", "에러", "비정상적인 접근입니다.");
        navigate("/error");
    } else if (error.response.status === 500) {
        Alert("warn", "에러", "서버에 문제가 있습니다.");
        navigate("/error");
    }
};
