import { apiUrl } from "utils/urls";

import axios from "axios";

export const postPaymentBillingKey = async (body) => {
    const { data } = await axios.post(apiUrl("paymentBillingKey"), body);
    return data;
};

export const getPaymentBillingKeyList = async (params) => {
    const { data } = await axios.get(apiUrl("paymentBillingKey"), {
        params: params,
    });
    return data;
};

export const deletePaymentBillingKey = async (id) => {
    const { data } = await axios.delete(apiUrl("paymentBillingKey") + "/" + id);
    return data;
};

export const getPaymentBillingHistory = async (params) => {
    const { data } = await axios.get(apiUrl("paymentBillingHistory"), {
        params: params,
    });
    return data;
};

export const getPaymentPlan = async (params) => {
    const { data } = await axios.get(apiUrl("paymentPlan"), { params: params });
    return data;
};

export const getPaymentPlanSubscriptionHistory = async (params) => {
    const { data } = await axios.get(apiUrl("paymentPlanSubscriptionHistory"), {
        params: params,
    });
    return data;
};

export const postPaymentPlanSubscriptionHistory = async (body) => {
    const { data } = await axios.post(apiUrl("paymentPlanSubscriptionHistory"), body);
    return data;
};

export const getPaymentCoupon = async (params) => {
    const { data } = await axios.get(apiUrl("paymentCoupon"), { params: params });
    return data;
};

export const postPaymentCoupon = async (body) => {
    const { data } = await axios.post(apiUrl("paymentCouponUse"), body);
    return data;
};

export const registerPaymentCoupon = async (body) => {
    const { data } = await axios.post(apiUrl("paymentCouponRegister"), body);
    return data;
};

export const getPaymentPlanGroupUser = async (params) => {
    const { data } = await axios.get(apiUrl("paymentPlanGroupUser"), { params: params });
    return data;
};

export const postPaymentPlanGroupUser = async (body) => {
    const { data } = await axios.post(apiUrl("paymentPlanGroupUser"), body);
    return data;
};

export const deletePaymentPlanGroupUser = async (id) => {
    const { data } = await axios.delete(apiUrl("paymentPlanGroupUser") + "/" + id);
    return data;
};

export const patchPaymentPlanGroupUser = async (body) => {
    const { data } = await axios.patch(apiUrl("paymentPlanGroupUser") + "/" + body.id, body);
    return data;
};

export const getPaymentPlanGroup = async (params) => {
    const { data } = await axios.get(apiUrl("paymentPlanGroup"), { params: params });
    return data;
};
