import cryptoJs from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY || "secretKey";
const iv = "abcdefghijklmnop"; // 16자리 iv

//암호화
export const encrypt = (text) => {
    const rk = secretKey.padEnd(32, "0");

    const cipher = cryptoJs.AES.encrypt(text, cryptoJs.enc.Utf8.parse(rk), {
        iv: cryptoJs.enc.Utf8.parse(iv),
        padding: cryptoJs.pad.Pkcs7,
        mode: cryptoJs.mode.CBC,
    });

    return cipher.toString();
};

// 복호화
export const decrypt = (encryptedText) => {
    const rk = secretKey.padEnd(32, "0");
    const decipher = cryptoJs.AES.decrypt(encryptedText, cryptoJs.enc.Utf8.parse(rk), {
        iv: cryptoJs.enc.Utf8.parse(iv),
        padding: cryptoJs.pad.Pkcs7,
        mode: cryptoJs.mode.CBC,
    });

    return decipher.toString(cryptoJs.enc.Utf8);
};
