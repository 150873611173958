import { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";

const Container = styled.div`
    width: 100%;
    padding: 0 8px;
`;

const Input = styled.input`
    padding: ${(props) => props.padding || "6px 8px"};
    text-align: center;
    width: 100%;
    min-height: 8px;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-Black);
    background-color: ${({ isEditable }) => (isEditable ? "var(--color-Base1)" : "var(--color-White)")};
    border: ${({ isEditable }) => (isEditable ? "solid 1px var(--color-InputLine)" : "solid 1px var(--color-White)")};
    border-radius: 4px;
    outline: #dce1e7;
    resize: none;
    cursor: text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
        background-color: ${({ isEditable }) => (isEditable ? "var(--color-Base1)" : "var(--color-Base1)")};
    }

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

function ResearchDetailTitleInput(props) {
    const [isEditable, setIsEditable] = useState(false);
    const [oldValue, setOldValue] = useState("");
    const inputRef = useRef(null);
    const isEditableRef = useRef(false);

    const handleInputChange = (e) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };

    const handleInputBlur = () => {
        if (props.value === "" && props.allowEmpty === false) {
            Alert("warn", "에러", "내용을 입력해주세요.", () => {
                if (inputRef.current) {
                    setIsEditable(true);
                    inputRef.current.focus();
                }
            });
        } else {
            setIsEditable(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            inputRef.current.blur();
            e.preventDefault();
        } else if (e.key === "Escape") {
            props.onChange({ target: { value: oldValue } });
            setIsEditable(false);
        }
    };

    useEffect(() => {
        if (isEditable && isEditableRef.current === false) {
            setOldValue(props.value);
        }

        if (!isEditable && isEditableRef.current === true) {
            if (props.saveAct && props.value !== oldValue) {
                props.saveAct();
            }
        }

        isEditableRef.current = isEditable;
    }, [isEditable]);

    const handleContainerClick = () => {
        setIsEditable(true);
    };

    return (
        <Container onClick={handleContainerClick}>
            <Input
                ref={inputRef}
                name={props.name}
                isEditable={isEditable}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                readOnly={!isEditable}
                rows={1}
                placeholder={props.placeholder}
                value={props.value ? props.value : ""}
                padding={props.padding}
            />
        </Container>
    );
}

export default ResearchDetailTitleInput;
