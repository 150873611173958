import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import styled from "styled-components";

import WelcomePopupImage from "assets/png/popup/WelcomePopupImage.png";

import SentenceText from "components/atoms/text/SentenceText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    width: 400px;
    height: 400px;
`;

const CompleteImgWrap = styled.div`
    width: 200px;

    > img {
        width: 100%;
    }
`;

function WelcomePopup(props) {
    useEffect(() => {
        // 구글 애널리틱스 운영서버만 적용: 가입 전환 액션 측정
        if (process.env.REACT_APP_ENV === "prd") {
            ReactGA.gtag("event", "conversion", { send_to: "AW-16518517814/ukVPCIiEk6YZELao0sQ9" });
        }
    }, []);

    return (
        <Container>
            <Wrapper>
                <CompleteImgWrap>
                    <img src={WelcomePopupImage} alt={"회원가입 완료 이미지"} />
                </CompleteImgWrap>
                <TitleText size={"large"} textAlign={"center"} margin={"24px 0 0 0"}>
                    프렙 회원가입을 축하합니다.
                </TitleText>
                <SentenceText size={"large"} textAlign={"center"} color={"var(--color-SubBlack)"} margin={"12px 0 0 0"}>
                    프렙의 다양한 AI 기능을 활용해보세요.
                    <br />
                    참고문헌 관리부터 리서치 노트 작성까지
                    <br />
                    다양한 기능으로 당신의 논문 연구를 지원합니다.
                </SentenceText>
            </Wrapper>
        </Container>
    );
}

export default WelcomePopup;
