import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import TitleText from "components/atoms/text/TitleText";

import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceBibInfoAddFieldModal from "components/page/modal/ReferenceBibInfoAddFieldModal";
import ReferenceBibInfoViewModal from "components/page/modal/ReferenceBibInfoViewModal";

const Container = styled.div`
    width: 100%;
`;

const TitleLineWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px 0;
`;

const TabviewWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.form`
    position: relative;
    width: 100%;
`;

const FormInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const FormRow = styled.div`
    display: ${(props) => (props.field === "abstract" ? "none" : "flex")};
    justify-content: initial;
    align-items: flex-start;
    margin: 0 0 8px 0;
    width: 100%;
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 140px;
    height: 24px;
`;

const ItemRight = styled.div`
    display: flex;
    align-items: flex-start;
    width: calc(100% - 148px);
`;

const LabelSign = styled.div`
    min-width: 2px;
    height: 12px;
    background-color: var(--color-Key);
`;

const FormLabelWrap = styled.div`
    position: relative;
    top: 4px;
    display: flex;
    align-items: flex-start;
    margin: 0 0 8px 0;
    padding: 0 0 0 8px;
    overflow-wrap: anywhere;
`;

const FormLabel = styled.label`
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    cursor: text;
`;

const FormContent = styled.div`
    display: flex;
    width: 100%;
`;

const FormInput = styled.input`
    flex: 1;
    margin: 0;
    padding: 0 8px;
    height: 24px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    box-sizing: border-box;

    &:disabled {
        background-color: var(--color-DisabledInput);
    }
`;

const WithoutDelBtn = styled.div`
    display: flex;
    width: calc(100% - 32px);
`;

const WithDelBtn = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const BibTexButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 0 8px 0;
`;

function TabReferenceBibInfo(props) {
    // blur 처리 시 삭제버튼을 바로 클릭하면?
    const { setLoading } = useLoading();

    const requireFields = ["entry_type", "citation_key", "title"];

    const [bibJson, setBibJson] = useState({});

    const {
        register,
        handleSubmit,
        setValue,
        unregister,
        setFocus,
        formState: { errors },
    } = useForm();

    const [bibFieldAddModal, setBibFieldAddModal] = useState(false);
    const [showReferenceBibInfoViewModal, setShowReferenceBibInfoViewModal] = useState(false);

    const sortBibJson = (bibJson) => {
        // 1. entry_type 2. citation_key 3. title, 4. etc 순서로 변경한다.(title은 있을수도있고, 없을 수도 있다.)
        const sortedBibJson = {};
        const copiedBibJson = JSON.parse(JSON.stringify(bibJson));
        const entryType = copiedBibJson.entry_type;
        const citationKey = copiedBibJson.citation_key;
        const title = copiedBibJson.title;

        delete copiedBibJson.entry_type;
        delete copiedBibJson.citation_key;
        delete copiedBibJson.title;

        sortedBibJson.entry_type = entryType;
        sortedBibJson.citation_key = citationKey;
        if (title) {
            sortedBibJson.title = title;
        }
        if (copiedBibJson.abstract) {
            setValue("abstract", copiedBibJson.abstract);
        }
        Object.keys(copiedBibJson)
            .sort()
            .forEach((key) => {
                sortedBibJson[key] = copiedBibJson[key];
            });

        return sortedBibJson;
    };

    useEffect(() => {
        if (props.bibliography.bib_json) {
            setBibJson(sortBibJson(props.bibliography.bib_json));
        }
    }, [props.bibliography.bib_json]);

    const onSubmit = (data, field) => {
        if (JSON.stringify(data) === JSON.stringify(bibJson)) {
            return;
        }
        props.mutation.mutate(
            {
                id: props.bibliography.id,
                bib_json: data,
            },
            {
                onMutate: (variables) => {
                    setLoading(true);
                },
                onSettled: (data, variables, context) => {
                    setLoading(false);
                },
                onSuccess: (data, variables, context) => {
                    document.getElementsByName(field)[0].style.border = "1px solid var(--color-Outline)";
                },
                onError: (error, variables, context) => {
                    Alert("warn", "에러", error.response?.data?.bib_json || "참고문헌 수정에 실패했습니다.", () => {
                        // focus처리 및 빨간 테두리 처리
                        setFocus(field);
                        document.getElementsByName(field)[0].style.border = "1px solid red !important";
                    });
                },
            },
        );
    };

    const fieldAdd = (fieldName) => {
        if (fieldName in bibJson) {
            Alert("warn", "에러", "이미 존재하는 필드입니다.");
            return;
        }
        const newBibJson = { ...bibJson };
        newBibJson[fieldName] = "";
        props.mutation.mutate(
            {
                id: props.bibliography.id,
                bib_json: newBibJson,
            },
            {
                onMutate: (variables) => {
                    setLoading(true);
                },
                onSettled: (data, variables, context) => {
                    setLoading(false);
                },
                onSuccess: (data, variables, context) => {
                    setBibFieldAddModal(false);
                },
                onError: (error, variables, context) => {
                    Alert("warn", "에러", "참고문헌 정보를 수정하지 못했습니다.");
                },
            },
        );
    };

    const fieldDelete = (fieldName) => {
        Confirm("warn", "삭제", "이 항목을 삭제하시겠습니까?", "삭제", (e) => {
            const newBibJson = { ...bibJson };
            delete newBibJson[fieldName];
            props.mutation.mutate(
                {
                    id: props.bibliography.id,
                    bib_json: newBibJson,
                },
                {
                    onMutate: (variables) => {
                        setLoading(true);
                    },
                    onSettled: (data, variables, context) => {
                        setLoading(false);
                    },
                    onSuccess: (data, variables, context) => {
                        unregister(fieldName);
                    },
                    onError: (error, variables, context) => {
                        Alert("warn", "에러", "참고문헌 정보를 수정하지 못했습니다.");
                    },
                },
            );
        });
    };

    const handleOnKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.target.blur();
        }
    };

    return (
        <Container>
            <TitleLineWrap>
                <TitleText size={"small"} margin={"0 8px 0 0"}>
                    서지정보
                </TitleText>
                <Button
                    onClick={(e) => setBibFieldAddModal(true)}
                    margin={"0 8px 0 auto"}
                    width={"88px"}
                    height={"24px"}
                    bgColor={"var(--color-Button2)"}
                    hoverBgColor={"var(--color-ButtonHover2)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"항목 추가"}
                    iconSize={"1"}
                    fontSize={"small"}
                >
                    <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                </Button>
                <Button
                    onlyText
                    onClick={(e) => setShowReferenceBibInfoViewModal(true)}
                    margin={"0 32px 0 0"}
                    width={"52px"}
                    height={"24px"}
                    bgColor={"var(--color-Button2)"}
                    hoverBgColor={"var(--color-ButtonHover2)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"BibTeX"}
                    fontSize={"small"}
                />
            </TitleLineWrap>
            <TabviewWrap onSubmit={handleSubmit(onSubmit)}>
                <FormContainer>
                    <FormInner>
                        {Object.keys(bibJson).map((field, index) => {
                            const options = {
                                value: bibJson[field],
                                shouldUnregister: true,
                            };
                            return (
                                <FormRow key={field} field={field}>
                                    <ItemLeft>
                                        <LabelSign />
                                        <FormLabelWrap>
                                            <FormLabel>
                                                {field}
                                                {requireFields.includes(field) && " *"}
                                            </FormLabel>
                                        </FormLabelWrap>
                                    </ItemLeft>
                                    <ItemRight>
                                        <FormContent>
                                            {field === "entry_type" ? (
                                                <WithoutDelBtn>
                                                    <FormInput {...register(`${field}`, options)} disabled />
                                                </WithoutDelBtn>
                                            ) : requireFields.includes(field) ? (
                                                <WithoutDelBtn>
                                                    <FormInput
                                                        {...register(`${field}`, options)}
                                                        onBlur={(e) => handleSubmit(onSubmit)(field)}
                                                        onKeyDown={handleOnKeyDown}
                                                    />
                                                </WithoutDelBtn>
                                            ) : (
                                                <WithDelBtn>
                                                    <FormInput
                                                        {...register(`${field}`, options)}
                                                        onBlur={(e) => handleSubmit(onSubmit)(field)}
                                                        onKeyDown={handleOnKeyDown}
                                                    />
                                                    {!(field === "entry_type" || field === "citation_key") && (
                                                        <Tooltip message={"항목 삭제"}>
                                                            <Button
                                                                key={index + "origin"}
                                                                onlyIcon
                                                                margin={"0 0 0 8px"}
                                                                buttonSize={"24px"}
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                                onClick={(e) => fieldDelete(field)}
                                                            >
                                                                <Icon
                                                                    name={"delete"}
                                                                    size={"12"}
                                                                    color={"var(--color-SubBlack)"}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </WithDelBtn>
                                            )}
                                        </FormContent>
                                    </ItemRight>
                                </FormRow>
                            );
                        })}
                    </FormInner>
                </FormContainer>
            </TabviewWrap>
            {bibFieldAddModal && (
                <AbstractModal
                    modalTitle="BibTeX 항목 추가"
                    width={"400"}
                    exitModal={(e) => setBibFieldAddModal(!bibFieldAddModal)}
                    id={"bibFieldAddModal"}
                >
                    <ReferenceBibInfoAddFieldModal
                        exitModal={(e) => setBibFieldAddModal(!bibFieldAddModal)}
                        addAction={fieldAdd}
                    />
                </AbstractModal>
            )}
            {showReferenceBibInfoViewModal && (
                <AbstractModal
                    modalTitle="BibTeX"
                    width={"800"}
                    exitModal={(e) => setShowReferenceBibInfoViewModal(!showReferenceBibInfoViewModal)}
                    id={"referenceBibInfoViewModal"}
                >
                    <ReferenceBibInfoViewModal bib_json={bibJson} />
                </AbstractModal>
            )}
        </Container>
    );
}

export default TabReferenceBibInfo;
