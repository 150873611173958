// src/components/atoms/Placeholder.js
import React from "react";
import { Placeholder as BootstrapPlaceholder } from "react-bootstrap";

function Placeholder(props) {
    return (
        <BootstrapPlaceholder as={"div"} animation={"glow"}>
            <BootstrapPlaceholder style={props.style} />
        </BootstrapPlaceholder>
    );
}

export default Placeholder;
