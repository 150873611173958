import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

import styled, { css } from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import useToast from "components/atoms/toast/useToast";

import RightSideBar from "components/molecules/RightSideBar";
import Table from "components/molecules/Table";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useBibliographyCreate } from "hooks/queries/useBibliographies";
import { useSelectBoxOptions } from "hooks/queries/useOptions";
import { useDeletePaperSearchHistory, usePaperSearch, usePaperSearchHistory } from "hooks/queries/usePapers";
import { useUser } from "hooks/queries/useUser";

import { useDevice } from "utils/device";
import { getConvertedPaper } from "utils/request/paper";
import { toBibJson } from "utils/scienceon";
import { wsUrl } from "utils/urls";

const WidthLimit = styled.div`
    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    margin: 0 auto;
    padding: 0 48px;
    width: 100%;
    height: ${(props) => props.height};
`;

const Header = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 48px;
    max-height: 48px;
    align-items: center;
    background-color: var(--color-White);
`;

const PageTitle = styled.h1`
    position: relative;
    top: 2px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-Black);
`;

const Body = styled.div`
    display: flex;
    justify-content: center;
    margin: 48px 0 0 0;
    width: 100%;
    height: calc(100vh - 104px);
    max-height: calc(100vh - 104px);
    overflow: auto;
`;

const SearchInputWrap = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
    flex: 1;
    height: 100%;
`;

const SearchMaxWidth = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const RefPoint = styled.div`
    position: relative;
    flex: 1;
    min-width: 100%;
    height: 34px;
`;

const InputWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: auto;
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Line);
    transition: all 120ms ease-in;
    z-index: 1;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const RowWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
`;

const SearchInput = styled.input`
    padding: 12px 4px;
    width: calc(100% - 10px);
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--color-White);
    color: var(--color-Black);
    border: transparent;
    outline: none;

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

const SearchButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SearchHistoryList = styled.div`
    flex-direction: column;
    margin: 0 8px;
    padding: 8px 0 4px 0;
    width: calc(100% - 16px);
    background-color: var(--color-White);
    border-top: solid 1px var(--color-Grey3);
`;

const SearchHistoryItem = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-Black);
    border: transparent;
    background-color: var(--color-White);

    &:hover {
        > div {
            background-color: var(--color-ButtonHover4);
        }
    }
`;

const ItemInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    background-color: var(--color-White);
    border-radius: 4px;
    border: transparent;

    &:first-child {
        margin: 4px 0 0 0;
    }

    &:last-child {
        margin: 0 0 4px 0;
    }
`;

const OptionBoxContainer = styled.div`
    position: absolute;
    top: 28px;
    right: 0;
    display: ${(props) => (props.visible ? "block" : "none")};
    padding: 8px;
    width: ${(props) => props.width};
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 3;
`;

const CheckOptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 200px;
`;

const CheckItemWrap = styled.div``;

const CheckRadioItemLabel = styled.label`
    display: flex;
    align-items: center;
    height: 24px;
`;

const InnerLeft = styled.div`
    display: flex;
    align-items: center;
`;

const SubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    flex-shrink: 0;
`;

const HeaderLeftSection = styled.div`
    display: flex;
    align-items: center;
`;

const SelectBoxSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
`;

const BlankView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const DOIWrap = styled.div`
    display: flex;
    align-items: center;

    a {
        margin: 4px 4px 0 0;

        &:last-child {
            margin: 4px 0px 0 0;
        }
    }
`;

const OptionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.optionBoxActive ? "var(--color-White)" : "var(--color-Button4)")};
    border: solid 1.5px ${(props) => (props.optionBoxActive ? "var(--color-Button2)" : "transparent")};
    border-radius: 4px;
    outline: none;

    &:hover {
        background-color: ${(props) => (props.optionBoxActive ? "" : "var(--color-ButtonHover4)")};
    }
`;

const MainHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 16px;
    width: 100%;
    border-bottom: 1px solid var(--color-Line);
    flex-shrink: 0;
`;

const SummarySection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;

const SummaryInstruction = styled.p`
    font-size: 14px;
    color: var(--color-Black);
    margin-bottom: 16px;
    text-align: center;
`;

const SummaryControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
`;

const LinkButtonStyle = {
    height: "18px",
    padding: "0px 4px",
    borderRadius: "4px",
    bgColor: "var(--color-Button3)",
    hoverBgColor: "var(--color-ButtonHover3)",
    fontSize: "xsmall",
};

const ItemWrapper = styled.div`
    flex: 1;
    overflow-y: scroll;
`;

const TabButtonWrap = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    background-color: var(--color-White);
    z-index: 1;
`;

const TabButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    background-color: var(--color-White);
    border: transparent;
    outline: none;

    &:hover {
        p {
            color: var(--color-Key);
        }
    }
`;

const TabLine = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) => (props.isActive ? "var(--color-Key)" : "var(--color-DisabledInput)")};
`;

const AddInfoSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;

const AddInfoBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-InfoBox);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 24px;
        `};
`;

const AddInfoItem = styled.div`
    width: 50%;
    max-width: calc(50% - 8px);
`;

const AddInfoItemRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 8px 6px;
    width: 100%;

    &:last-child {
        margin: 0 0 0 6px;
    }
`;

const TextSection = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--color-Line);
    margin: 16px 0;
`;

const CheckIconStyle = {
    name: "check",
    size: "14",
    color: "var(--color-DarkKey)",
};

const ItemTextStyle = {
    margin: "0 0 0 8px",
    size: "large",
    color: "var(--color-SubBlack)",
};

function SearchPage(props) {
    const device = useDevice();

    const [searchParams] = useSearchParams();

    const [searchInput, setSearchInput] = useState(searchParams.get("q") || "");
    const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");

    const [user, setUser] = useState({});
    const [paperList, setPaperList] = useState({
        results: [],
        count: 0,
    });
    const [summaryText, setSummaryText] = useState("");

    const [displaySearchHistory, setDisplaySearchHistory] = useState("none");
    const [searchHistory, setSearchHistory] = useState(JSON.parse(localStorage.getItem("searchHistory")) || []);

    const [optionBoxActive, setOptionBoxActive] = useState(false);
    const [sorting, setSorting] = useState("NULL");
    const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1, count: 0 });
    const [showSummarySidebar, setShowSummarySidebar] = useState(false);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [language, setLanguage] = useState("");
    const [summaryStatus, setSummaryStatus] = useState("");
    const userQuery = useUser();
    const paperSearchQuery = usePaperSearch({
        "search-query": `${searchQuery}`,
        sort: sorting,
        page_size: pagination.pageSize,
        page_index: pagination.pageIndex,
    });
    const paperCreateMutation = useBibliographyCreate();

    const optionsQuery = useSelectBoxOptions();
    const paperSearchHistoryQuery = usePaperSearchHistory();
    const paperSearchHistoryDeleteMutation = useDeletePaperSearchHistory();
    const [tabActive, setTabActive] = useState("summary");
    const wsRef = useRef(null);
    const searchInputRef = useRef(null);
    const optionBoxRef = useRef(null);

    const { setToast } = useToast();

    const { setLoading } = useLoading();
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const tableKey = "search";

    const [columnVisibility, setColumnVisibility] = useState(() => {
        const tableConfig = JSON.parse(localStorage.getItem("table-config")) || {}; // 기존 table-config 불러오기
        const savedVisibility = {};

        if (tableConfig[tableKey]) {
            Object.keys(tableConfig[tableKey]).forEach((columnId) => {
                savedVisibility[columnId] = tableConfig[tableKey][columnId].visibility;
            });
        }

        return savedVisibility;
    });

    useEffect(() => {
        if (Object.keys(columnVisibility).length > 0) {
            const tableConfig = JSON.parse(localStorage.getItem("table-config")) || {};
            const updatedConfig = { ...tableConfig };

            updatedConfig[tableKey] = updatedConfig[tableKey] || {};

            Object.keys(columnVisibility).forEach((columnId) => {
                updatedConfig[tableKey][columnId] = {
                    ...updatedConfig[tableKey][columnId],
                    visibility: columnVisibility[columnId],
                };
            });

            localStorage.setItem("table-config", JSON.stringify(updatedConfig)); // 로컬 스토리지에 저장
        }
    }, [columnVisibility, tableKey]);

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    useEffect(() => {
        if (sorting) {
            setPagination({ pageIndex: 1, pageSize: pagination.pageSize, count: pagination.count });
        }
    }, [sorting]);

    useEffect(() => {
        if (pagination) {
            paperSearchQuery.refetch();
        }
    }, [pagination]);

    useEffect(() => {
        if (optionsQuery.data) {
            setLanguageOptions(optionsQuery.data.env_ai_language);
        }
    }, [optionsQuery.data]);

    // 초기화
    useEffect(() => {
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
            paperSearchHistoryQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
        if (searchQuery) {
            let tempSearchHistory = searchHistory;
            if (searchHistory.includes(searchInput)) {
                // 검색어를 포함하고 있다면 제거
                tempSearchHistory = searchHistory.filter((item) => item !== searchInput);
            }
            tempSearchHistory.unshift(searchInput);
            if (tempSearchHistory.length > 10) {
                // 길이가 10개를 넘어가면 마지막 요소를 제거
                tempSearchHistory.pop();
            }
            setSearchHistory(tempSearchHistory);
        }
        paperSearchQuery.refetch({ page_index: 1, page_size: pagination.pageSize });
    }, [searchQuery]);

    useEffect(() => {
        if (paperSearchHistoryQuery.data) {
            if (user.email) {
                setSearchHistory(paperSearchHistoryQuery.data);
            }
        }
    }, [paperSearchHistoryQuery.data, user]);

    useEffect(() => {
        localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
    }, [searchHistory.length]);

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
            if (language === "") {
                setLanguage(userQuery.data.env_ai_language);
            }
        }
        if (paperSearchQuery.data) {
            setPaperList(paperSearchQuery.data);
            if (pagination.count !== paperSearchQuery.data.count) {
                setPagination({
                    ...pagination,
                    count: paperSearchQuery.data.count,
                });
            }
        }
    }, [userQuery.data, paperSearchQuery.data]);

    const startSummary = () => {
        setLoading(true);
        if (checkedBibsRef.current.length > 10) {
            Alert("warn", "에러", "요약은 10개 이하로만 가능합니다.");
        }
        setSummaryText("");

        wsRef.current = new WebSocket(wsUrl("searchSummary"));

        wsRef.current.onopen = (evt) => {
            wsRef.current.send(
                JSON.stringify({
                    action: "summary",
                    papers: checkedBibsRef.current.map((scienceon_cn) => {
                        const paper = paperList.results.find((p) => p.scienceon_cn === scienceon_cn);
                        return {
                            title: paper?.title,
                            year: paper?.year,
                            abstract: paper?.abstract,
                        };
                    }),
                    language: language,
                }),
            );
            setSummaryStatus("running");
        };

        wsRef.current.onmessage = (evt) => {
            setLoading(false);
            const data = JSON.parse(evt.data);

            if (data.content === "<END>") {
                wsRef.current.close();
            } else {
                setSummaryText((prev) => {
                    return prev + data.content;
                });
            }
        };

        wsRef.current.onerror = (error) => {
            console.log(error);
            // Alert("warn", "에러", "요약문을 생성하지 못했습니다.");
        };
        wsRef.current.onclose = (event) => {
            setLoading(false);
            if (event.code !== 1000) {
                // 정상 종료가 아닌 경우
                console.log(event);
                Alert("warn", "에러", "요약문을 생성하지 못했습니다.");
            }
            setSummaryStatus("done");
            wsRef.current = null;
        };
    };

    const stopSummary = () => {
        if (wsRef.current) {
            wsRef.current.send(
                JSON.stringify({
                    action: "stop",
                    papers: [],
                }),
            );
        }
    };

    const rerenderTable = React.useReducer(() => ({}), {})[1];

    const checkedBibsRef = useRef([]);

    const handleCheckboxAllChange = (e) => {
        // 전체 선택이면 모든 id를, 그렇지 않으면 빈 배열을 반환
        checkedBibsRef.current = e.target.checked ? paperList?.results?.map((obj) => obj.scienceon_cn) : [];
        rerenderTable();
    };

    const handleCheckboxChange = (event) => {
        let scienceon_cn = event.target.id;
        const index = checkedBibsRef.current.indexOf(scienceon_cn);
        if (index > -1) {
            checkedBibsRef.current = checkedBibsRef.current.filter((item) => item !== scienceon_cn);
        } else {
            checkedBibsRef.current = [...checkedBibsRef.current, scienceon_cn];
        }
        rerenderTable();
    };

    const handleQuoteButtonClicked = (obj) => {
        let bib_json = toBibJson(obj);
        const link = {};
        link.google = "https://scholar.google.com/scholar?q=" + encodeURIComponent(obj?.title);
        if (obj.doi) link.doi = obj.doi;
        if (obj.scienceon_content_url) link.scienceon_content_url = obj.scienceon_content_url;
        if (obj.scienceon_fulltext_url) link.scienceon_fulltext_url = obj.scienceon_fulltext_url;

        paperCreateMutation.mutate(
            {
                body: {
                    bib_json: bib_json,
                    display_name: obj.title,
                    link: link,
                },
            },
            {
                onSuccess: (data, variables, context) => {
                    setToast("참고문헌을 등록했습니다.", "info");
                },
            },
        );
    };

    const [styledText, setStyledText] = useState([]);
    const convertToBibText = () => {
        const references = checkedBibsRef.current.map((scienceon_cn) => {
            console.log(paperList);
            const paper = paperList.results.find((p) => p.scienceon_cn === scienceon_cn);
            return toBibJson(paper);
        });

        getConvertedPaper(references).then((res) => setStyledText(res));
    };

    const columns = React.useMemo(
        () => [
            {
                id: "scienceon_cn",
                accessorKey: "scienceon_cn",
                header: null,
                name: "선택",
                size: 45,
                minSize: 45,
                enableResizing: false,
                content: (info) => {
                    const value = info.getValue();
                    return (
                        <Checkbox
                            id={value}
                            checked={checkedBibsRef.current?.includes(value)}
                            onChange={handleCheckboxChange}
                        />
                    );
                },
            },
            {
                id: "title",
                accessorKey: "title",
                name: "논문명",
                style: { justifyContent: "left" },
                text: (info) => {
                    return info.getValue();
                },
                content: (info) => {
                    return (
                        <DOIWrap>
                            {info.row.original?.doi && (
                                <Link to={info.row.original.doi} target={"_blank"}>
                                    <Button onlyText {...LinkButtonStyle} buttonText={"DOI"} />
                                </Link>
                            )}
                            <Link
                                to={
                                    "https://scholar.google.com/scholar?q=" +
                                    encodeURIComponent(info.row.original?.title)
                                }
                                target={"_blank"}
                            >
                                <Button onlyText {...LinkButtonStyle} buttonText={"Google"} />
                            </Link>
                            {info.row.original?.scienceon_content_url && (
                                <Link to={info.row.original.scienceon_content_url} target={"_blank"}>
                                    <Button onlyText {...LinkButtonStyle} buttonText={"ScienceON"} />
                                </Link>
                            )}
                            {info.row.original?.scienceon_fulltext_url && (
                                <Link to={info.row.original.scienceon_fulltext_url} target={"_blank"}>
                                    <Button onlyText {...LinkButtonStyle} buttonText={"PDF"} />
                                </Link>
                            )}
                        </DOIWrap>
                    );
                },
            },
            {
                id: "year",
                accessorKey: "year",
                name: "발행년도",
                size: 60,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "publisher",
                accessorKey: "publisher",
                name: "발행처",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "author",
                accessorKey: "author",
                name: "저자",
                size: 80,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "keyword",
                accessorKey: "keyword",
                name: "키워드",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "abstract",
                accessorKey: "abstract",
                name: "초록",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "add",
                accessorKey: "add",
                name: "추가",
                size: 60,
                minSize: 60,
                maxSize: 60,
                enableResizing: false,
                content: (info) => {
                    return (
                        <Button
                            onlyText
                            size={"xsmall"}
                            width={"47px"}
                            height={"24px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"추가"}
                            onClick={() => handleQuoteButtonClicked(info.row.original)}
                        />
                    );
                },
            },
        ],
        [],
    );

    useEffect(() => {
        // 목록이 변경되면 checkedBibsRef를 초기화 한다.
        checkedBibsRef.current = [];
        rerenderTable();
    }, [paperList.results]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (optionBoxRef.current && !optionBoxRef.current.contains(e.target)) {
                setOptionBoxActive(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionBoxRef]);

    return (
        <LoginUserLayout>
            <HtmlHead title={"논문 DB 검색"} />
            <Header>
                <WidthLimit device={device} justifyContent={"space-between"} alignItems={"center"} height={"48px"}>
                    <PageTitle>논문 DB 검색</PageTitle>
                    <SearchInputWrap>
                        <SearchMaxWidth device={device}>
                            <RefPoint>
                                <InputWrap>
                                    <RowWrap>
                                        <SearchInput
                                            ref={searchInputRef}
                                            placeholder="검색어를 입력해주세요"
                                            value={searchInput}
                                            onChange={(e) => {
                                                // 수정금지: 랜딩페이지에서 전달되는 쿼리 처리를 위해 searchQuery 와 searchInput 모두 필요
                                                setSearchInput(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                                                    if (searchInput) {
                                                        setSearchQuery(searchInput);
                                                    }
                                                }
                                            }}
                                            onFocus={() => {
                                                setDisplaySearchHistory("flex");
                                            }}
                                            onBlur={() => {
                                                setDisplaySearchHistory("none");
                                            }}
                                        />
                                        <SearchButtonWrap>
                                            {searchInput && (
                                                <Tooltip message={"초기화"}>
                                                    <Button
                                                        onlyIcon
                                                        bgColor={"transparent"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        onClick={(e) => {
                                                            setSearchInput("");
                                                            setSearchQuery("");
                                                        }}
                                                    >
                                                        <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip message={"검색"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"transparent"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={(e) => setSearchQuery(searchInput)}
                                                >
                                                    <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                                </Button>
                                            </Tooltip>
                                        </SearchButtonWrap>
                                    </RowWrap>
                                    {searchHistory.length > 0 && (
                                        <SearchHistoryList style={{ display: displaySearchHistory }}>
                                            {searchHistory.map((item, index) => (
                                                <SearchHistoryItem
                                                    key={index}
                                                    onMouseDown={(e) => {
                                                        setSearchInput(item);
                                                        setSearchQuery(item);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <ItemInner>
                                                        <InnerLeft>
                                                            <Icon
                                                                name={"last"}
                                                                size={"14"}
                                                                color={"var(--color-SubBlack)"}
                                                            />
                                                            <GeneralText
                                                                size={"large"}
                                                                color={"var(--color-Black)"}
                                                                margin={"0 0 0 12px"}
                                                            >
                                                                {item}
                                                            </GeneralText>
                                                        </InnerLeft>
                                                        <Tooltip message={"삭제"}>
                                                            <Button
                                                                onlyIcon
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover5)"}
                                                                onMouseDown={(e) => {
                                                                    setSearchHistory(
                                                                        searchHistory.filter((_, i) => i !== index),
                                                                    );
                                                                    paperSearchHistoryDeleteMutation.mutate({
                                                                        query: item,
                                                                    });
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <Icon
                                                                    name={"delete"}
                                                                    size={"12"}
                                                                    color={"var(--color-SubBlack)"}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ItemInner>
                                                </SearchHistoryItem>
                                            ))}
                                        </SearchHistoryList>
                                    )}
                                </InputWrap>
                            </RefPoint>
                        </SearchMaxWidth>
                    </SearchInputWrap>
                </WidthLimit>
            </Header>

            <Body>
                <WidthLimit device={device} flexDirection={"column"} height={"calc(100vh-104px)"}>
                    {paperList && paperList?.results?.length > 0 && (
                        <SubHeader>
                            <HeaderLeftSection>
                                <Checkbox
                                    id={"all"}
                                    indeterminate={
                                        checkedBibsRef.current.length > 0 &&
                                        checkedBibsRef.current.length < paperList?.results?.length
                                    }
                                    checked={checkedBibsRef.current.length === paperList?.results?.length}
                                    onChange={handleCheckboxAllChange}
                                    margin={"0 8px 0 0"}
                                />
                                <Button
                                    onlyText
                                    height={"24px"}
                                    width={"70px"}
                                    bgColor={"var(--color-Button2)"}
                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                    buttonText={"요약하기"}
                                    disabled={checkedBibsRef.current.length === 0}
                                    onClick={() => {
                                        if (checkedBibsRef.current.length > 10) {
                                            Alert("warn", "에러", "요약은 10개까지만 가능합니다.");
                                            return;
                                        }
                                        convertToBibText();
                                        setSummaryText("");
                                        setShowSummarySidebar(true);
                                    }}
                                />
                                {checkedBibsRef.current.length > 0 && (
                                    <GeneralText margin={"0 0 0 10px"} size={"small"} fontWeight={"300"}>
                                        {checkedBibsRef.current.length}개의 항목이 선택됨
                                    </GeneralText>
                                )}
                            </HeaderLeftSection>
                            <SelectBoxSection>
                                <a href={"https://scienceon.kisti.re.kr/"}>
                                    <GeneralText margin={"0 10px 0 0"} size={"small"} fontWeight={"300"}>
                                        Powered by ScienceON
                                    </GeneralText>
                                </a>
                                <OptionButton
                                    optionBoxActive={optionBoxActive}
                                    onClick={() => {
                                        setOptionBoxActive(!optionBoxActive);
                                    }}
                                    ref={optionBoxRef}
                                >
                                    <Icon
                                        name={"option"}
                                        color={optionBoxActive ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                        size={"14px"}
                                    />
                                    <OptionBoxContainer width={"240px"} visible={optionBoxActive}>
                                        <GeneralText
                                            margin={"0 0 16px 0"}
                                            size={"regular"}
                                            fontWeight={"600"}
                                            textAlign={"left"}
                                        >
                                            테이블 항목
                                        </GeneralText>
                                        <CheckOptionWrap onClick={(e) => e.stopPropagation()}>
                                            {columns.map((column) => {
                                                return ["title", "scienceon_cn", "add"].includes(column.id) ? null : (
                                                    <CheckItemWrap key={column.id}>
                                                        <CheckRadioItemLabel>
                                                            <input
                                                                {...{
                                                                    type: "checkbox",
                                                                    style: { display: "block" },
                                                                    checked:
                                                                        columnVisibility[column.id] !== undefined
                                                                            ? columnVisibility[column.id]
                                                                            : true,
                                                                    onChange: (e) => {
                                                                        setColumnVisibility((prev) => ({
                                                                            ...prev,
                                                                            [column.id]: !(
                                                                                columnVisibility[column.id] ?? true
                                                                            ),
                                                                        }));
                                                                        e.stopPropagation();
                                                                    },
                                                                }}
                                                            />{" "}
                                                            <GeneralText margin={"0 0 0 4px"} size={"small"}>
                                                                {column.name}
                                                            </GeneralText>
                                                        </CheckRadioItemLabel>
                                                    </CheckItemWrap>
                                                );
                                            })}
                                        </CheckOptionWrap>
                                    </OptionBoxContainer>
                                </OptionButton>

                                <SelectBox
                                    width="120px"
                                    height={"28px"}
                                    maxWidth="120px"
                                    optionWidth="100%"
                                    optionMinWidth="120px"
                                    topBottom="top"
                                    reverse="180deg"
                                    value={sorting}
                                    items={[
                                        { id: "NULL", name: "정확도" },
                                        { id: "pubyear", name: "발행일순" },
                                    ]}
                                    onChange={(value) => setSorting(value)}
                                />
                            </SelectBoxSection>
                        </SubHeader>
                    )}
                    {paperList && paperList?.results?.length > 0 ? (
                        <Table
                            tableKey={tableKey}
                            data={paperList?.results || []}
                            columns={columns}
                            useSizingColumns={true}
                            useSortingColumns={false}
                            useOrderColumns={true}
                            pagination={pagination}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            setPagination={(page) => {
                                if (page.pageSize !== pagination.pageSize) {
                                    setPagination({
                                        pageIndex: 1,
                                        pageSize: page.pageSize,
                                        count: page.count,
                                    });
                                } else {
                                    setPagination({
                                        ...page,
                                        count: pagination.count,
                                    });
                                }
                            }}
                        />
                    ) : (
                        <BlankView>
                            <TextSection>
                                <GeneralText size={"large"}>검색 결과가 없습니다.</GeneralText>
                            </TextSection>
                            <AddInfoSection>
                                <AddInfoBox device={device}>
                                    <AddInfoItem>
                                        <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                            키워드를 입력하여 관련 논문을 찾아볼 수 있습니다.
                                        </GeneralText>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>
                                                최신 연구 자료를 검색할 수 있습니다.
                                            </GeneralText>
                                        </AddInfoItemRow>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>
                                                검색 결과는 정확도 순, 최신 순으로 정렬할 수 있습니다.
                                            </GeneralText>
                                        </AddInfoItemRow>
                                    </AddInfoItem>
                                    <AddInfoItem>
                                        <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                            검색된 논문들을 요약하거나 내 참고문헌으로 저장할 수 있습니다.
                                        </GeneralText>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>AI가 논문들을 요약해드립니다.</GeneralText>
                                        </AddInfoItemRow>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>
                                                관심 있는 논문을 내 참고문헌으로 추가할 수 있습니다.
                                            </GeneralText>
                                        </AddInfoItemRow>
                                    </AddInfoItem>
                                </AddInfoBox>
                            </AddInfoSection>
                        </BlankView>
                    )}
                </WidthLimit>
            </Body>
            <RightSideBar
                right={showSummarySidebar ? "0" : "-560px"}
                onClose={() => setShowSummarySidebar(false)}
                top={"56px"}
            >
                <MainHeader>
                    <HeaderLeftSection>
                        <Button
                            onlyIcon
                            bgColor={"transparent"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                            onClick={() => setShowSummarySidebar(false)}
                        >
                            <Icon name={"x"} />
                        </Button>
                        <GeneralText size={"large"} color={"var(--color-Black)"} margin={"0 0 0 8px"}>
                            요약하기
                        </GeneralText>
                    </HeaderLeftSection>
                </MainHeader>
                <TabButtonWrap>
                    <TabButton onClick={() => setTabActive("summary")}>
                        <GeneralText
                            size={"regular"}
                            fontWeight={"500"}
                            color={tabActive === "summary" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                        >
                            요약
                        </GeneralText>
                        <TabLine isActive={tabActive === "summary"} />
                    </TabButton>
                    <TabButton onClick={() => setTabActive("papers")}>
                        <GeneralText
                            size={"regular"}
                            fontWeight={"500"}
                            color={tabActive === "papers" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                        >
                            논문
                        </GeneralText>
                        <TabLine isActive={tabActive === "papers"} />
                    </TabButton>
                </TabButtonWrap>
                {tabActive === "summary" ? (
                    summaryText ? (
                        <>
                            <SubHeader>
                                <HeaderLeftSection />
                                <SelectBoxSection>
                                    <SelectBox
                                        width="calc(100% - 28px)"
                                        maxWidth="120px"
                                        optionWidth="100%"
                                        optionMinWidth="120px"
                                        topBottom="top"
                                        reverse="180deg"
                                        margin={"0 4px 0 0"}
                                        value={language}
                                        items={languageOptions}
                                        onChange={(value) => setLanguage(value)}
                                    />
                                    {summaryStatus === "running" ? (
                                        <Button
                                            width={"130px"}
                                            height={"32px"}
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            fontColor={"var(--color-White)"}
                                            buttonText={"작성 중지"}
                                            onClick={() => {
                                                stopSummary();
                                            }}
                                        >
                                            <Icon name={"stop"} color={"var(--color-White)"} />
                                        </Button>
                                    ) : (
                                        <Button
                                            onlyText
                                            height={"32px"}
                                            width={"100px"}
                                            bgColor={"var(--color-Button2)"}
                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                            buttonText={"다시 작성"}
                                            onClick={() => {
                                                startSummary();
                                            }}
                                        />
                                    )}
                                </SelectBoxSection>
                            </SubHeader>
                            <ItemWrapper>
                                <GeneralText margin={"0 12px"} size={"regular"} fontWeight={"400"} textAlign={"left"}>
                                    <div dangerouslySetInnerHTML={{ __html: summaryText }} />
                                </GeneralText>
                            </ItemWrapper>
                        </>
                    ) : (
                        <>
                            <SummarySection>
                                <SummaryInstruction>요약을 작성할 언어를 선택해주세요.</SummaryInstruction>
                                <SummaryControls>
                                    <SelectBox
                                        width="120px"
                                        maxWidth="120px"
                                        optionWidth="100%"
                                        optionMinWidth="120px"
                                        topBottom="top"
                                        reverse="180deg"
                                        value={language}
                                        items={languageOptions}
                                        onChange={(value) => setLanguage(value)}
                                    />
                                    <Button
                                        onlyText
                                        height={"32px"}
                                        width={"100px"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        buttonText={"요약하기"}
                                        onClick={() => {
                                            startSummary();
                                        }}
                                    />
                                </SummaryControls>
                            </SummarySection>
                        </>
                    )
                ) : (
                    <div>
                        <ItemWrapper>
                            {styledText.map((text) => (
                                <>
                                    <GeneralText
                                        margin={"16px 12px"}
                                        size={"regular"}
                                        fontWeight={"400"}
                                        textAlign={"left"}
                                        key={text.citation_key}
                                    >
                                        {text.styled_text}
                                    </GeneralText>
                                    <Divider />
                                </>
                            ))}
                        </ItemWrapper>
                    </div>
                )}
            </RightSideBar>
        </LoginUserLayout>
    );
}

export default SearchPage;
