import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import SentenceText from "components/atoms/text/SentenceText";
import useToast from "components/atoms/toast/useToast";

import { useSelectBoxOptions } from "hooks/queries/useOptions";
import { useUser } from "hooks/queries/useUser";

import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

const ButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

const StyleBox = styled.div`
    margin: 16px 0 0 0;
    padding: 8px;
    background-color: var(--color-Base1);
    border-radius: 4px;
`;

function ReferenceCitationStyleModal(props) {
    const [citationStyle, setCitationStyle] = useState("");
    const [styleList, setStyleList] = useState([]);
    const [styledText, setStyledText] = useState("");

    const userQuery = useUser();
    const optionsQuery = useSelectBoxOptions();

    const { setToast } = useToast();
    const { setLoading } = useLoading();

    useEffect(() => {
        if (userQuery.data) {
            let new_style = userQuery.data.env_citation_style;
            if (citationStyle === "") {
                // 최초 진입 시 환경설정 값으로 스타일 반영
                changeStyle(new_style);
            }
            setCitationStyle(new_style);
        }
        if (optionsQuery.data) {
            setStyleList(optionsQuery.data.env_citation_style);
        }
    }, [userQuery.data, optionsQuery.data]);

    useEffect(() => {
        if (citationStyle !== "") {
            changeStyle(citationStyle);
        }
    }, [citationStyle]);

    const changeStyle = (style) => {
        setLoading(true);
        let reference_ids = [props.reference.id];
        axios
            .post(apiUrl("paperStyle"), {
                style_type: style,
                reference_ids: reference_ids,
            })
            .then((res) => {
                setLoading(false);
                setStyledText(res.data[0]);
            })
            .catch((err) => {
                setLoading(false);
                Alert("error", "에러", "스타일 변경에 실패했습니다.");
            });
        setLoading(false);
    };

    const handleCopyButtonClick = () => {
        navigator.clipboard
            .writeText(styledText)
            .then(() => {
                setToast("서지정보가 복사되었습니다", "info");
            })
            .catch((error) => {
                console.error("복사 실패:", error);
            });
    };

    return (
        <Container>
            <ButtonSection>
                <SelectBox
                    width="120px"
                    height="32px"
                    onChange={(e) => setCitationStyle(e)}
                    value={citationStyle}
                    items={styleList}
                />
                <Tooltip message={"복사"}>
                    <Button
                        onlyIcon
                        margin={"0 0 0 4px"}
                        bgColor={"var(--color-Button2)"}
                        hoverBgColor={"var(--color-ButtonHover2)"}
                        onClick={handleCopyButtonClick}
                    >
                        <Icon name={"copy"} size={"12"} color={"var(--color-White)"} />
                    </Button>
                </Tooltip>
            </ButtonSection>
            <StyleBox>
                <SentenceText size={"regular"}>{styledText}</SentenceText>
            </StyleBox>
        </Container>
    );
}

export default ReferenceCitationStyleModal;
