import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";

import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

const BodyContentListView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ListViewTableWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: ${({ isScrolledLeft, isScrolledRight }) =>
        `${
            isScrolledLeft && isScrolledRight
                ? "inset 8px 0 8px -6px rgba(0, 0, 0, 0.15) , inset -8px 0 8px -6px rgba(0, 0, 0, 0.15)"
                : isScrolledLeft
                ? "inset 8px 0 8px -6px rgba(0, 0, 0, 0.15)"
                : isScrolledRight
                ? "inset -8px 0 8px -6px rgba(0, 0, 0, 0.15)"
                : ""
        }`};
    transition: box-shadow 0.2s ease;
`;

const ListViewTable = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
`;

const TableHead = styled.thead`
    width: 100%;
`;

const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const TableHeadTr = styled.tr`
    th {
        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
`;

const TableHeadTh = styled.th`
    position: sticky;
    top: 0;
    height: 32px;
    z-index: 1;
    border-bottom: solid 1px var(--color-Outline);

    &:last-child {
        border-right: none;
    }

    .sortHandle,
    .resizeHandle {
        visibility: hidden;
    }

    .sort-active {
        visibility: visible;
    }

    &:hover {
        .sortHandle,
        .resizeHandle {
            visibility: visible;
        }
    }

    &:last-child {
        .resizeHandle {
            visibility: hidden;
        }
    }
`;

const TableHeadThTitle = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: ${(props) => props.cursor};
    padding-left: 4px;
`;

const TableBody = styled.tbody`
    > tr {
        height: 48px;
        border-bottom: solid 1px var(--color-Outline);
        cursor: default;

        &:hover {
            transition: all 80ms ease-in;
            background-color: #e9f2f4;
        }
    }
`;

const TableCell = styled.td`
    z-index: 0;
`;

const TableCellWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    padding-left: 4px;
`;

const TableCellText = styled.div`
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.03em;
    font-size: 1.2rem;
    word-break: break-all;
`;

const TableCellContent = styled.div`
    overflow: hidden;
`;

const ListViewPagination = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    padding: 10px 0;
`;

const ListViewPageButtonWrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const ListViewPageSelectBoxWrap = styled.div`
    margin-left: auto;
`;

const TableHeadThSortHandle = styled.div`
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;

    &:hover {
        background-color: #aaccc6;
    }
`;

const ResizeHandle = styled.div`
    position: absolute;
    top: 6px;
    right: 0;
    width: 4px;
    height: 24px;
    background-color: var(--color-White);
    cursor: col-resize;
    user-select: none;
`;

const RefTableHeader = ({ header, useSortingColumns, useOrderColumns }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
        id: header.column.id,
    });

    const isDraggable = !["id", "is_bookmarked"].includes(header.column.id);
    const draggableProps = isDraggable
        ? useOrderColumns
            ? { ...attributes, ...listeners, style: { cursor: "grab" } }
            : {}
        : {};

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        backgroundColor: "var(--color-Base2)",
        transform: CSS.Translate.toString(transform),
        transition: "width transform 0.2s ease-in-out",
        whiteSpace: "nowrap",
        textAlign: "left",
        zIndex: isDragging ? 2 : 1,
        width: header.column.getSize(),
    };

    return (
        <TableHeadTh key={header.id} colSpan={header.colSpan} ref={setNodeRef} style={style}>
            {!header.isPlaceholder && (
                <TableHeadThTitle {...draggableProps}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {!["id", "is_bookmarked", "scienceon_cn"].includes(header.column.id) && useSortingColumns && (
                        <TableHeadThSortHandle
                            className={`sortHandle ${
                                header.column.getIsSorted() &&
                                !["id", "is_bookmarked"].includes(header.column.id) &&
                                "sort-active"
                            }`}
                            onMouseDown={
                                ["id", "is_bookmarked"].includes(header.column.id)
                                    ? undefined
                                    : header.column.getToggleSortingHandler()
                            }
                            title={
                                header.column.getCanSort() && !["id", "is_bookmarked"].includes(header.column.id)
                                    ? header.column.getNextSortingOrder() === "asc"
                                        ? "오름차순으로 정렬"
                                        : header.column.getNextSortingOrder() === "desc"
                                        ? "내림차순으로 정렬"
                                        : "정렬 해제"
                                    : undefined
                            }
                        >
                            {{
                                asc: "↑",
                                desc: "↓",
                            }[header.column.getIsSorted()] ?? "↕"}
                        </TableHeadThSortHandle>
                    )}
                </TableHeadThTitle>
            )}
            {!["id", "is_bookmarked", "scienceon_cn"].includes(header.column.id) && (
                <ResizeHandle
                    className={"resizeHandle"}
                    {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                    }}
                >
                    &nbsp;
                </ResizeHandle>
            )}
        </TableHeadTh>
    );
};

const DragAlongCell = ({ cell }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
        id: cell.column.id,
    });

    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            const scrollHeight = textRef.current.scrollHeight;
            const offsetHeight = textRef.current.offsetHeight;
            setIsOverflowing(scrollHeight > offsetHeight);
        }
    }, [textRef?.current?.clientWidth, cell]);

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        height: "48px",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        zIndex: isDragging ? 1 : 0,
        color: "var(--color-SubBlack)",
    };

    const text = cell.column.columnDef.text ? cell.column.columnDef.text(cell.getContext()) : "";
    const content = cell.column.columnDef.content ? cell.column.columnDef.content(cell.getContext()) : "";

    return (
        <TableCell
            key={cell.id}
            ref={setNodeRef}
            style={{
                ...style,
                width: cell.column.getSize(),
            }}
        >
            <TableCellWrap>
                {isOverflowing ? (
                    <>
                        <Tooltip message={text}>
                            <TableCellText ref={textRef}>{text}</TableCellText>
                        </Tooltip>
                        <TableCellContent>{content}</TableCellContent>
                    </>
                ) : (
                    <>
                        <TableCellText ref={textRef}>{text}</TableCellText>
                        <TableCellContent>{content}</TableCellContent>
                    </>
                )}
            </TableCellWrap>
        </TableCell>
    );
};

const MAX_PAGE_DISPLAY = 10;

const TABLE_COMMON_NAME = "table-config";

function Table(props) {
    /**
     * Table Component
     * props:
     * @param tableKey - 테이블 구분키
     * @param data - row data
     * @param columns - column data(header)

     * Optional
     * @param pagination - {pageIndex, pageSize}
     * @param setPagination - 페이지 상태 업데이트 함수

     * @param useSortingColumns - 컬럼 헤더에서 정렬 할지 여부
     * @param sorting - {field:'display_name' ,desc:false}
     * @param setSorting - 정렬 처리 상태 업데이트 함수

     * @param useSizingColumns - 컬럼 너비 조정 여부

     * @param columnVisibility - 컬럼 표시 여부 빈값인 경우 모두 표출
     * @param setColumnVisibility - 컬럼 표시 여부 업데이트 함수
     * @param useDraggableColumns - 컬럼 순서 변경 여부

     * @param useOrderColumns - 컬럼 순서 변경 여부
     */

    const {
        tableKey,
        data,
        pagination,
        setPagination,
        sorting,
        setSorting,
        useSizingColumns = true,
        useSortingColumns,
        columnVisibility,
        setColumnVisibility,
        onRowClick,
        useOrderColumns,
    } = props;
    const tableRef = React.useRef();

    const getColumnHeaderName = (info) => {
        return (
            <GeneralText size={"small"} fontWeight={"500"} color={"#2F5B51"}>
                {info.column.columnDef.name}
            </GeneralText>
        );
    };

    let columns = props.columns;

    columns = columns.map((column) => {
        if (column.header === undefined) {
            column.header = getColumnHeaderName;
        }
        return column;
    });

    const saveTableConfigToLocalStorage = (tableKey, config) => {
        const tableConfig = JSON.parse(localStorage.getItem(TABLE_COMMON_NAME)) || {};
        tableConfig[tableKey] = config;
        localStorage.setItem(TABLE_COMMON_NAME, JSON.stringify(tableConfig));
    };

    const getTableConfigFromLocalStorage = (tableKey) => {
        const tableConfig = JSON.parse(localStorage.getItem(TABLE_COMMON_NAME)) || {};
        return tableConfig[tableKey] || {};
    };

    const [columnsConfig, setColumnsConfig] = useState(getTableConfigFromLocalStorage(tableKey));

    useEffect(() => {
        const savedConfig = getTableConfigFromLocalStorage(tableKey);
        if (savedConfig) {
            setColumnsConfig(savedConfig);
        }
    }, [tableKey]);

    useEffect(() => {
        if (columnsConfig) {
            saveTableConfigToLocalStorage(tableKey, columnsConfig);
        }
    }, [columnsConfig, tableKey]);

    const updateColumnSize = (columnId, size) => {
        setColumnsConfig((prevConfig) => ({
            ...prevConfig, // 전체 설정을 복사
            [columnId]: {
                ...prevConfig[columnId], // 기존 컬럼 설정 유지
                size, // 새 크기 업데이트
            },
        }));
    };

    const getColumnOrder = () => {
        const savedConfig = getTableConfigFromLocalStorage(tableKey);
        if (savedConfig) {
            // order 값을 기준으로 정렬하여 열 ID 순서를 반환
            return Object.keys(savedConfig).sort((a, b) => (savedConfig[a].order || 0) - (savedConfig[b].order || 0));
        } else {
            return columns.map((col) => col.id); // columns 배열을 이용해 기본 순서 설정
        }
    };
    const [columnOrder, setColumnOrder] = useState(getColumnOrder());

    useEffect(() => {
        if (columnOrder) {
            updateColumnOrder(columnOrder);
        }
    }, [columnOrder]);

    const updateColumnOrder = (orderList) => {
        setColumnsConfig((prevConfig) => {
            const newConfig = { ...prevConfig };
            // orderList에 따라 각 열의 순서 설정
            orderList.forEach((columnId, index) => {
                if (newConfig[columnId]) {
                    newConfig[columnId] = {
                        ...newConfig[columnId],
                        order: index,
                    };
                } else {
                    newConfig[columnId] = { order: index };
                }
            });

            return newConfig;
        });
    };

    const table = useReactTable({
        data: data,
        columns: columns,
        ...(pagination && {
            manualPagination: true,
            pageCount: Math.ceil(pagination.count / pagination.pageSize),
            initialState: {
                pagination: {
                    pageIndex: pagination.pageIndex - 1,
                    pageSize: pagination.pageSize,
                },
            },
            getPaginationRowModel: getPaginationRowModel(),
            onPaginationChange: (updater) => {
                const updatedPagination =
                    typeof updater === "function" ? updater(table.getState().pagination) : updater;

                setPagination({
                    pageIndex: updatedPagination.pageIndex + 1,
                    pageSize: updatedPagination.pageSize,
                });
            },
        }),
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true,
        ...(setSorting && {
            onSortingChange: (updater) => {
                const updatedSorting = typeof updater === "function" ? updater(table.getState().sorting) : updater;
                setSorting(updatedSorting);
            },
        }),
        getSortedRowModel: sorting ? getSortedRowModel() : undefined,
        columnResizeMode: "onChange",
        columnResizeDirection: "ltr",
        defaultColumn: {
            minSize: 40,
        },
        state: {
            ...(sorting && {
                sorting: sorting,
            }),
            ...(columnVisibility && {
                columnVisibility: columnVisibility,
            }),
            ...(useOrderColumns && {
                columnOrder: columnOrder,
            }),
        },
    });

    useEffect(() => {
        if (pagination) {
            table.setState((old) => {
                return {
                    ...old,
                    pagination: {
                        pageIndex: pagination.pageIndex - 1,
                        pageSize: pagination.pageSize,
                    },
                };
            });
        }
    }, [pagination]);

    useEffect(() => {
        if (sorting) {
            table.setState((old) => {
                return {
                    ...old,
                    sorting: sorting,
                };
            });
        } else {
            table.setState((old) => {
                return {
                    ...old,
                    sorting: [],
                };
            });
        }
    }, [sorting]);

    useEffect(() => {
        if (Object.keys(columnsConfig).length !== 0) {
            columns.forEach((column) => {
                if (columnsConfig[column.id] && columnsConfig[column.id].size !== undefined) {
                    column.size = columnsConfig[column.id].size;
                } else if (columnsConfig[column.id]?.size === undefined) {
                    const columnSizing = getColumnSizing(columns);
                    const orderList = [];
                    columns.forEach((column) => {
                        column.size = columnSizing[column.id];
                        updateColumnSize(column.id, columnSizing[column.id]);
                        orderList.push(column.id);
                    });
                    setColumnOrder(orderList);
                } else {
                    column.size = 50;
                }
            });
        } else {
            // 로컬스토리지에 저장된 컬럼 설정이 없는 경우
            const columnSizing = getColumnSizing(columns);
            const orderList = [];
            columns.forEach((column) => {
                column.size = columnSizing[column.id];
                updateColumnSize(column.id, columnSizing[column.id]);
                orderList.push(column.id);
            });
            setColumnOrder(orderList);
        }
    }, [columns, tableKey]);

    useEffect(() => {
        if (useSizingColumns) {
            const currentSizing = table.getState().columnSizing;

            const tableConfig = getTableConfigFromLocalStorage(tableKey) || {};
            const updatedSizing = {
                ...tableConfig,
                ...Object.keys(currentSizing).reduce((acc, columnId) => {
                    acc[columnId] = {
                        ...tableConfig[columnId],
                        size: currentSizing[columnId],
                    };
                    return acc;
                }, {}),
            };

            saveTableConfigToLocalStorage(tableKey, updatedSizing);
        }
    }, [table.getState().columnSizing, tableKey, useSizingColumns]);

    const getColumnSizing = (columns) => {
        const totalWidth = tableRef.current.offsetWidth;

        let sizedColumns = [];
        let totalSize = 0;
        columns.forEach((column) => {
            if (column.size && column.size > 0) {
                sizedColumns.push(column.id);
                totalSize += column.size;
            }
        });

        const columnWidth = (totalWidth - totalSize) / (columns.length - sizedColumns.length);
        const columnSizing = {};
        columns.forEach((column) => {
            if (!sizedColumns.includes(column.id)) {
                columnSizing[column.id] = columnWidth;
            } else {
                columnSizing[column.id] = column.size;
            }
        });

        return columnSizing;
    };

    const currentPage = table.getState().pagination.pageIndex;
    const totalPages = table.getPageCount();

    const getVisiblePages = () => {
        let startPage = Math.max(0, currentPage - Math.floor(MAX_PAGE_DISPLAY / 2));
        let endPage = startPage + MAX_PAGE_DISPLAY - 1;

        if (endPage >= totalPages) {
            endPage = totalPages - 1;
            startPage = Math.max(0, endPage - MAX_PAGE_DISPLAY + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id);
                const newIndex = columnOrder.indexOf(over.id);
                return arrayMove(columnOrder, oldIndex, newIndex);
            });
        }
    }

    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

    const tableWrapRef = useRef();
    const [isScrolledLeft, setIsScrolledLeft] = useState(false);
    const [isScrolledRight, setIsScrolledRight] = useState(false);

    useEffect(() => {
        const tableWrap = tableWrapRef.current;

        const handleScroll = () => {
            if (tableWrap) {
                const { scrollLeft, scrollWidth, clientWidth } = tableWrapRef.current;
                setIsScrolledLeft(scrollLeft > 0);
                setIsScrolledRight(scrollLeft + clientWidth < scrollWidth);
            }
        };
        tableWrap?.addEventListener("scroll", handleScroll);
        handleScroll(); // 초기 상태 설정

        return () => {
            tableWrap?.removeEventListener("scroll", handleScroll);
        };
    }, [table?.getTotalSize()]);

    return (
        <BodyContent>
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToHorizontalAxis]}
                onDragEnd={handleDragEnd}
                sensors={sensors}
            >
                <BodyContentListView ref={tableRef}>
                    <ListViewTableWrap
                        ref={tableWrapRef}
                        isScrolledLeft={isScrolledLeft}
                        isScrolledRight={isScrolledRight}
                    >
                        <ListViewTable
                            style={{
                                width: table.getTotalSize(),
                            }}
                        >
                            <TableHead>
                                {props.useSizingColumns
                                    ? table.getHeaderGroups().map((headerGroup) => (
                                          <TableHeadTr key={headerGroup.id}>
                                              <SortableContext
                                                  items={columnOrder}
                                                  strategy={horizontalListSortingStrategy}
                                              >
                                                  {headerGroup.headers.map((header) => (
                                                      <RefTableHeader
                                                          key={header.id}
                                                          header={header}
                                                          useSortingColumns={useSortingColumns}
                                                          useOrderColumns={useOrderColumns}
                                                      />
                                                  ))}
                                              </SortableContext>
                                          </TableHeadTr>
                                      ))
                                    : table.getHeaderGroups().map((headerGroup) => (
                                          <TableHeadTr key={headerGroup.id}>
                                              {headerGroup.headers.map((header) => (
                                                  <TableHeadTh
                                                      key={header.id}
                                                      colSpan={header.colSpan}
                                                      style={{
                                                          padding: "8px 6px",
                                                          backgroundColor: "var(--color-Base2)",
                                                          transition: "width transform 0.2s ease-in-out",
                                                          whiteSpace: "nowrap",
                                                          textAlign: "center",
                                                          width: header.column.getSize(),
                                                      }}
                                                  >
                                                      <TableHeadThTitle>
                                                          {flexRender(
                                                              header.column.columnDef.header,
                                                              header.getContext(),
                                                          )}
                                                      </TableHeadThTitle>
                                                  </TableHeadTh>
                                              ))}
                                          </TableHeadTr>
                                      ))}
                            </TableHead>
                            <TableBody>
                                {table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        onClick={() => onRowClick && onRowClick(row)}
                                        style={{ cursor: onRowClick ? "pointer" : "default" }}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <SortableContext
                                                key={cell.id}
                                                items={columnOrder}
                                                strategy={horizontalListSortingStrategy}
                                            >
                                                <DragAlongCell key={cell.id} cell={cell} />
                                            </SortableContext>
                                        ))}
                                    </tr>
                                ))}
                            </TableBody>
                        </ListViewTable>
                    </ListViewTableWrap>
                    {pagination && (
                        <ListViewPagination>
                            <ListViewPageButtonWrap>
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.firstPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <Icon
                                        name={"arrowLeftDouble"}
                                        color={
                                            !table.getCanPreviousPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    margin={"0 4px"}
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <Icon
                                        name={"arrowLeft"}
                                        color={
                                            !table.getCanPreviousPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                {getVisiblePages().map((pageIndex) => (
                                    <Button
                                        onlyText
                                        margin={"0 4px"}
                                        height={"24px"}
                                        width={"auto"}
                                        minWidth={"24px"}
                                        key={pageIndex}
                                        onClick={() => table.setPageIndex(pageIndex)}
                                        bgColor={currentPage === pageIndex ? "var(--color-Key)" : "transparent"}
                                        hoverBgColor={
                                            currentPage === pageIndex
                                                ? "var(--color-DarkKey)"
                                                : "var(--color-ButtonHover4)"
                                        }
                                        buttonText={pageIndex + 1}
                                        fontColor={
                                            currentPage === pageIndex ? "var(--color-White)" : "var(--color-SubBlack)"
                                        }
                                    />
                                ))}
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    margin={"0 4px"}
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <Icon
                                        name={"arrowRight"}
                                        color={
                                            !table.getCanNextPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.lastPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <Icon
                                        name={"arrowRightDouble"}
                                        color={
                                            !table.getCanNextPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                {/* <span>
                                                <div>Page</div>
                                                <strong>
                                                    {table.getState().pagination.pageIndex + 1} of{" "}
                                                    {table.getPageCount().toLocaleString()}
                                                </strong>
                                            </span>
                                            <span>
                                                | Go to page:
                                                <input
                                                    type="number"
                                                    defaultValue={table.getState().pagination.pageIndex + 1}
                                                    onChange={(e) => {
                                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                        table.setPageIndex(page);
                                                    }}
                                                />
                                            </span> */}
                            </ListViewPageButtonWrap>
                            <ListViewPageSelectBoxWrap>
                                <SelectBox
                                    width={"120px"}
                                    optionWidth={"100%"}
                                    topBottom={"bottom"}
                                    reverse={"180deg"}
                                    value={String(table.getState().pagination.pageSize)}
                                    items={[
                                        { id: "10", name: "10개씩 보기" },
                                        { id: "20", name: "20개씩 보기" },
                                        { id: "30", name: "30개씩 보기" },
                                        { id: "40", name: "40개씩 보기" },
                                        { id: "50", name: "50개씩 보기" },
                                    ]}
                                    onChange={(id) => {
                                        table.setPageSize(Number(id));
                                    }}
                                />
                            </ListViewPageSelectBoxWrap>
                        </ListViewPagination>
                    )}
                </BodyContentListView>
            </DndContext>
        </BodyContent>
    );
}

export default Table;
