import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { apiUrl } from "utils/urls";

import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

const BibTeXContainer = styled.div`
    margin: 24px 0 0 0;
    padding: 6px;
    width: 100%;
    height: 240px;
    max-height: 320px;
    background-color: var(--color-CodeBack);
    border: solid 2px var(--color-Black);
    border-radius: 4px;
`;

const Textarea = styled.textarea`
    width: 100%;
    height: auto;
    min-height: 100%;
    line-height: 1.8;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-White);
    background-color: transparent;
    border: transparent;
    outline: none;
    resize: none;

    &::placeholder {
        color: var(--color-DisabledText);
    }

    &::-webkit-scrollbar {
        width: 6px;
        display: initial;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-Black);
    }
`;

function ReferenceAddBibtexModal(props) {
    const { setLoading } = useLoading();
    const navigate = useNavigate();
    const [bibtex, setBibtex] = useState("");

    const addReferenceWithBibtex = () => {
        setLoading(true);
        axios
            .post(apiUrl("paperAddWithBibtex"), {
                bib: bibtex,
            })
            .then((res) => {
                setLoading(false);
                navigate(`/reference/${res.data.uuid}`);
            })
            .catch((error) => {
                setLoading(false);
                Alert("warn", "에러", error.response?.data?.bib_json || "참고문헌 추가 중 오류가 발생했습니다.");
            });
    };

    return (
        <Container>
            <GeneralText size={"regular"}>
                BibTeX 양식에 맞춰 정보를 입력해주세요.
                <br />
                입력완료 후 추가버튼을 선택하면 참고문헌이 추가됩니다.
            </GeneralText>
            {/* BibTeX로 참고문헌 추가 기능 */}
            <BibTeXContainer>
                <Textarea
                    autoFocus
                    value={bibtex}
                    onChange={(e) => setBibtex(e.target.value)}
                    placeholder='@misc{citekey,&#13;&#10; title = "The title of the miscellaneous entry",&#13;&#10; author = "A. Author",&#13;&#10; howpublished = "How it was published",&#13;&#10; month = "Month",&#13;&#10; year = "Year",&#13;&#10; note = "An optional note",&#13;&#10;}'
                />
            </BibTeXContainer>
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"추가"}
                leftOnClick={props.exitModal}
                rightOnClick={(e) => addReferenceWithBibtex()}
                disabled={!bibtex}
            />
        </Container>
    );
}

export default ReferenceAddBibtexModal;
