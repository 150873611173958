import React from "react";
import { Quill } from "react-quill";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";

const SaveButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
        svg {
            fill: #06c;
        }
    }
`;

const UndoButton = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
        <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
    </svg>
);

const RedoButton = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
        <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
    </svg>
);

function undoChange() {
    this.quill.history.undo();
}

function redoChange() {
    this.quill.history.redo();
}

function saveChange() {
    console.log("saved");
}

const Font = Quill.import("formats/font");
Font.whitelist = ["sans-serif", "serif", "monospace"];
Quill.register(Font, true);

export const modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {
            undo: undoChange,
            redo: redoChange,
            save: saveChange,
        },
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
    imageDrop: true,
    ImageResize: {
        parchment: Quill.import("parchment"),
    },
};

export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "reference",
    "align",
];
const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large", "huge"];
Quill.register(Size, true);

function ResearchEditorToolbar(props) {
    return (
        <div id="toolbar">
            <span className={"ql-formats"}>
                <Tooltip message={"저장"}>
                    <SaveButton className={"ql-save"} onClick={(e) => props.save("user")}>
                        <Icon name={"save"} size={13} />
                    </SaveButton>
                </Tooltip>
            </span>
            <span className="ql-formats">
                <Tooltip message={"되돌리기"}>
                    <button className="ql-undo">
                        <UndoButton />
                    </button>
                </Tooltip>
                <Tooltip message={"다시실행"}>
                    <button className="ql-redo">
                        <RedoButton />
                    </button>
                </Tooltip>
            </span>
            <span className="ql-formats">
                <select className="ql-header" defaultValue="">
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option value="">Normal</option>
                </select>
                <select className="ql-font" defaultValue="sans-serif">
                    <option value={"sans-serif"}>Sans Serif</option>
                    <option value={"serif"}>Serif</option>
                    <option value={"monospace"}>Monospace</option>
                </select>
                <select className="ql-size" defaultValue="medium">
                    <option value="small">small</option>
                    <option value="medium">medium</option>
                    <option value="large">large</option>
                    <option value="huge">huge</option>
                </select>
            </span>
            <span className="ql-formats">
                <Tooltip message="굵게">
                    <button className="ql-bold" />
                </Tooltip>
                <Tooltip message="기울임">
                    <button className="ql-italic" />
                </Tooltip>
                <Tooltip message="밑줄">
                    <button className="ql-underline" />
                </Tooltip>
                <Tooltip message="취소선">
                    <button className="ql-strike" />
                </Tooltip>
                <Tooltip message="인용구">
                    <button className="ql-blockquote" />
                </Tooltip>
            </span>
            <span className="ql-formats">
                <Tooltip message={"번호 목록"}>
                    <button className="ql-list" value="ordered" />
                </Tooltip>
                <Tooltip message={"글머리 기호 목록"}>
                    <button className="ql-list" value="bullet" />
                </Tooltip>
                <Tooltip message={"내어쓰기"}>
                    <button className="ql-indent" value="-1" />
                </Tooltip>
                <Tooltip message={"들여쓰기"}>
                    <button className="ql-indent" value="+1" />
                </Tooltip>
            </span>

            <span className={"ql-formats"}>
                <Tooltip message={"정렬"}>
                    <select className="ql-align" />
                </Tooltip>
            </span>
            <span className="ql-formats">
                <Tooltip message={"링크 연결"}>
                    <button className="ql-link" />
                </Tooltip>
                <Tooltip message={"이미지 삽입"}>
                    <button className="ql-image" />
                </Tooltip>
                <Tooltip message={"비디오 링크"}>
                    <button className="ql-video" />
                </Tooltip>
            </span>
            <span className={"ql-formats"}>
                <Tooltip message={"서식 제거"}>
                    <button className="ql-clean" />
                </Tooltip>
            </span>
            <span className="ql-formats">
                <Tooltip message={"글자색 변경"}>
                    <select className="ql-color" />
                </Tooltip>
                <Tooltip message={"배경색 변경"}>
                    <select className="ql-background" />
                </Tooltip>
            </span>
        </div>
    );
}

export default ResearchEditorToolbar;
