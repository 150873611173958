import { useMutation, useQuery, useQueryClient } from "react-query";

import Alert from "components/atoms/alert/Alert";

import {
    deletePaymentBillingKey,
    deletePaymentPlanGroupUser,
    getPaymentBillingHistory,
    getPaymentBillingKeyList,
    getPaymentCoupon,
    getPaymentPlan,
    getPaymentPlanGroup,
    getPaymentPlanGroupUser,
    getPaymentPlanSubscriptionHistory,
    patchPaymentPlanGroupUser,
    postPaymentBillingKey,
    postPaymentCoupon,
    postPaymentPlanGroupUser,
    postPaymentPlanSubscriptionHistory,
    registerPaymentCoupon,
} from "utils/request/payment";

export const usePaymentBillingKeys = (params) => {
    return useQuery("paymentBillingKey", (e) => getPaymentBillingKeyList(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaymentBillingKeyCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postPaymentBillingKey, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "paymentBillingKey" });
        },
    });
};

export const usePaymentBillingKeyDelete = () => {
    const queryClient = useQueryClient();

    return useMutation(deletePaymentBillingKey, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "paymentBillingKey" });
        },
    });
};

export const usePaymentBillingHistory = (params) => {
    return useQuery("paymentBillingHistory", (e) => getPaymentBillingHistory(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaymentPlan = () => {
    return useQuery("paymentPlan", (e) => getPaymentPlan(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaymentPlanSubscriptionHistory = () => {
    return useQuery("paymentPlanSubscriptionHistory", (e) => getPaymentPlanSubscriptionHistory(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaymentPlanSubscriptionHistoryCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postPaymentPlanSubscriptionHistory, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({
                queryKey: "paymentPlanSubscriptionHistory",
            });
            queryClient.invalidateQueries({ queryKey: "user" });
            queryClient.invalidateQueries({ queryKey: "paymentPlanGroup" });
            queryClient.invalidateQueries({ queryKey: "paymentPlanGroupUser" });
            queryClient.invalidateQueries({ queryKey: "paymentBillingHistory" });
        },
        onError: (e) => {
            if (e.response.data.non_field_errors[0]) {
                Alert("warn", "에러", e.response.data.non_field_errors[0]);
            } else {
                Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
            }
        },
    });
};

export const usePaymentCoupon = (params) => {
    return useQuery("paymentCoupon", (e) => getPaymentCoupon(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaymentCouponUse = () => {
    const queryClient = useQueryClient();

    return useMutation(postPaymentCoupon, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "user" });
            queryClient.invalidateQueries({
                queryKey: "paymentPlanSubscriptionHistory",
            });
            queryClient.invalidateQueries({ queryKey: "paymentCoupon" });
            Alert("info", "쿠폰", "쿠폰이 적용되었습니다.");
        },
        onError: (e) => {
            if (e.response.data.error) {
                Alert("warn", "에러", e.response.data.error);
            } else {
                Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
            }
        },
    });
};

export const usePaymentCouponRegister = () => {
    const queryClient = useQueryClient();

    return useMutation(registerPaymentCoupon, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "paymentCoupon" });
            Alert("info", "쿠폰", "쿠폰이 등록되었습니다.");
        },
        onError: (e) => {
            if (e.response.data.error) {
                Alert("warn", "에러", e.response.data.error);
            } else {
                Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
            }
        },
    });
};

export const usePaymentPlanGroupUser = (params) => {
    return useQuery("paymentPlanGroupUser", (e) => getPaymentPlanGroupUser(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const usePaymentPlanGroupUserCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postPaymentPlanGroupUser, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "paymentPlanGroupUser" });
        },
    });
};

export const usePaymentPlanGroupUserDelete = () => {
    const queryClient = useQueryClient();

    return useMutation(deletePaymentPlanGroupUser, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "paymentPlanGroupUser" });
        },
    });
};

export const usePaymentPlanGroupUserUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation(patchPaymentPlanGroupUser, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "paymentPlanGroupUser" });
        },
    });
};

export const usePaymentPlanGroup = () => {
    return useQuery("paymentPlanGroup", (e) => getPaymentPlanGroup(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};
